'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

var userFragment = "\n  fragment GroupUserFragment on User {\n    _id\n    first\n    last\n    avatar\n  }\n";

var studentGroupFragment = "\n  fragment StudentGroupFragment on Group {\n    _id\n    tutor {\n      user {\n        ...GroupUserFragment\n      }\n    }\n  }\n  " + userFragment + "\n";

var tutorGroupFragment = "\n  fragment TutorGroupFragment on Group {\n    _id\n    studentMembers {\n      student {\n        user {\n          ...GroupUserFragment\n        }\n      }\n    }\n  }\n  " + userFragment + "\n";

var Type = {};

function decodeUser(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          first: Json_decode.optional((function (param) {
                  return Json_decode.field("first", Json_decode.string, param);
                }), json),
          last: Json_decode.optional((function (param) {
                  return Json_decode.field("last", Json_decode.string, param);
                }), json),
          avatar: Json_decode.optional((function (param) {
                  return Json_decode.field("avatar", Json_decode.string, param);
                }), json)
        };
}

function decodeWithUser(json) {
  return {
          user: Json_decode.field("user", decodeUser, json)
        };
}

function decodeStudent(json) {
  return {
          student: Json_decode.field("student", decodeWithUser, json)
        };
}

function decodeGroup(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          studentMembers: Json_decode.optional((function (param) {
                  return Json_decode.field("studentMembers", (function (param) {
                                return Json_decode.array(decodeStudent, param);
                              }), param);
                }), json),
          tutor: Json_decode.optional((function (param) {
                  return Json_decode.field("tutor", decodeWithUser, param);
                }), json)
        };
}

var Decode = {
  decodeUser: decodeUser,
  decodeWithUser: decodeWithUser,
  decodeStudent: decodeStudent,
  decodeGroup: decodeGroup
};

function getNameFromUser(user) {
  var match = user.first;
  var match$1 = user.last;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return match + " " + match$1;
    } else {
      return match;
    }
  } else if (match$1 !== undefined) {
    return match$1;
  } else {
    return ;
  }
}

function normalize(group) {
  var match = group.studentMembers;
  var match$1 = group.tutor;
  if (match$1 !== undefined) {
    return {
            groupId: group._id,
            userId: match$1.user._id,
            avatar: match$1.user.avatar,
            first: match$1.user.first,
            last: match$1.user.last,
            name: getNameFromUser(match$1.user)
          };
  }
  if (match !== undefined && match.length === 1) {
    var studentMember = match[0];
    return {
            groupId: group._id,
            userId: studentMember.student.user._id,
            avatar: studentMember.student.user.avatar,
            first: studentMember.student.user.first,
            last: studentMember.student.user.last,
            name: getNameFromUser(studentMember.student.user)
          };
  }
  return {
          groupId: group._id,
          userId: "--11",
          avatar: undefined,
          first: undefined,
          last: undefined,
          name: undefined
        };
}

exports.userFragment = userFragment;
exports.studentGroupFragment = studentGroupFragment;
exports.tutorGroupFragment = tutorGroupFragment;
exports.Type = Type;
exports.Decode = Decode;
exports.getNameFromUser = getNameFromUser;
exports.normalize = normalize;
/* No side effect */
