'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_Heart(Props) {
  var colorOpt = Props.color;
  var widthOpt = Props.width;
  var heightOpt = Props.height;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var width = widthOpt !== undefined ? widthOpt : "14";
  var height = heightOpt !== undefined ? heightOpt : "14";
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: height,
              width: width,
              fill: "none",
              viewBox: "0 0 " + (width + (" " + height)),
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M7 13.4575C6.80062 13.4575 6.60306 13.3862 6.44575 13.2435C6.25917 13.0734 1.88186 9.06557 0.619708 6.54309C-0.197949 4.90778 -0.207096 3.15539 0.595928 1.85665C1.32395 0.676808 2.62818 0 4.17021 0C5.45432 0 6.40368 0.815829 7 1.55849C7.59632 0.815829 8.54568 0 9.82979 0C11.3718 0 12.6742 0.676808 13.4041 1.85665C14.2071 3.15722 14.198 4.90961 13.3803 6.54309C12.1181 9.06557 7.74083 13.0734 7.55425 13.2435C7.39694 13.3862 7.19938 13.4575 7 13.4575ZM4.17021 1.64629C3.19707 1.64629 2.42514 2.0286 1.99711 2.72187C1.4959 3.53221 1.53249 4.68644 2.09222 5.80775C2.97207 7.56745 5.79455 10.3588 7 11.5076C8.20545 10.3588 11.0279 7.56745 11.9078 5.80775C12.4693 4.68644 12.5041 3.53404 12.0029 2.72187C11.5749 2.0286 10.8029 1.64629 9.82979 1.64629C8.61154 1.64629 7.75729 3.40599 7.74998 3.42246C7.61644 3.71696 7.32377 3.90537 7 3.90537C6.67623 3.90537 6.38355 3.71696 6.25002 3.42246C6.24271 3.40782 5.39395 1.64629 4.17021 1.64629Z",
                  fill: c
                }));
}

var make = Icon_Heart;

exports.make = make;
/* react Not a pure module */
