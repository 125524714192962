'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Date$NimbusWeb = require("../../../utils/Date.bs.js");

function dCourse(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          subject: Json_decode.field("subject", Json_decode.string, json),
          number: Json_decode.field("number", Json_decode.string, json)
        };
}

function dDate(json) {
  return {
          timestamp: Json_decode.field("timestamp", Json_decode.string, json)
        };
}

function dT(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          eventType: Json_decode.field("type", Json_decode.string, json),
          objectId: Json_decode.field("objectId", Json_decode.string, json),
          dropInStudentsId: Json_decode.optional((function (param) {
                  return Json_decode.field("dropInStudentsId", Json_decode.string, param);
                }), json),
          status: Json_decode.optional((function (param) {
                  return Json_decode.field("status", Json_decode.string, param);
                }), json),
          picture: Json_decode.optional((function (param) {
                  return Json_decode.field("picture", Json_decode.string, param);
                }), json),
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          courses: Json_decode.field("courses", (function (param) {
                  return Json_decode.array(dCourse, param);
                }), json),
          startDateTime: Json_decode.field("startDateTime", dDate, json),
          minutes: Json_decode.field("minutes", Json_decode.$$int, json),
          pattern: Json_decode.field("pattern", Json_decode.bool, json),
          timeblockId: Json_decode.field("_timeblock", Json_decode.string, json)
        };
}

var Decode = {
  dCourse: dCourse,
  dDate: dDate,
  dT: dT
};

function normalize($$event) {
  return {
          _id: $$event._id,
          eventType: $$event.eventType,
          objectId: $$event.objectId,
          dropInStudentsId: $$event.dropInStudentsId,
          status: $$event.status,
          picture: $$event.picture,
          name: $$event.name,
          courses: $$event.courses,
          startDateTime: Date$NimbusWeb.dateFromStringMS($$event.startDateTime.timestamp),
          minutes: $$event.minutes,
          pattern: $$event.pattern,
          timeblockId: $$event.timeblockId
        };
}

function toJson($$event) {
  var dict = {};
  dict["_id"] = $$event._id;
  dict["minutes"] = $$event.minutes;
  dict["timeblockId"] = $$event.timeblockId;
  var courses = Belt_Array.map($$event.courses, (function (course) {
          var dCourse = {};
          dCourse["_id"] = course._id;
          dCourse["subject"] = course.subject;
          dCourse["number"] = course.number;
          return dCourse;
        }));
  dict["courses"] = courses;
  return dict;
}

function parseCalendarEvent($$event) {
  try {
    return JSON.parse($$event.resource);
  }
  catch (exn){
    return Curry._1(Pervasives.failwith("Failed to parse event json"), $$event.resource);
  }
}

var fragment = "\n  fragment EventFragment on EventItem {\n    _id\n    type\n    objectId\n    dropInStudentsId\n    status\n    picture\n    name\n    interestedStudentsCount\n    attendeesCount\n    courses { _id subject number }\n    startDateTime { timestamp }\n    minutes\n    online\n    pattern\n    _timeblock\n  }\n";

exports.fragment = fragment;
exports.Decode = Decode;
exports.normalize = normalize;
exports.toJson = toJson;
exports.parseCalendarEvent = parseCalendarEvent;
/* Date-NimbusWeb Not a pure module */
