'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var Storage$NimbusWeb = require("../../utils/Storage.bs.js");
var ReactBigCalendar = require("react-big-calendar");
var Navigation$NimbusWeb = require("../../utils/Navigation.bs.js");
var AuthContext$NimbusWeb = require("../../contexts/AuthContext.bs.js");
var BigCalendar$NimbusWeb = require("../../components/BigCalendar.bs.js");
var EventViewUI$NimbusWeb = require("./EventViewUI.bs.js");
var UserContext$NimbusWeb = require("../../contexts/UserContext.bs.js");
var EventResponse$NimbusWeb = require("../lesson/requests/EventResponse.bs.js");
var SelectedCoursesContext$NimbusWeb = require("../../contexts/SelectedCoursesContext.bs.js");
var DropInRequest_FetchById$NimbusWeb = require("../lesson/requests/DropInRequest_FetchById.bs.js");
var EventRequest_FetchPublic$NimbusWeb = require("../lesson/requests/EventRequest_FetchPublic.bs.js");
var DropInRequest_ShowInterest$NimbusWeb = require("../lesson/requests/DropInRequest_ShowInterest.bs.js");
var EventRequest_FetchZoomMeeting$NimbusWeb = require("../lesson/requests/EventRequest_FetchZoomMeeting.bs.js");
var DropInStudentsRequest_FetchById$NimbusWeb = require("../lesson/requests/DropInStudentsRequest_FetchById.bs.js");
var EventRequest_FetchByDropInStudentsId$NimbusWeb = require("../lesson/requests/EventRequest_FetchByDropInStudentsId.bs.js");

var localizer = BigCalendar$NimbusWeb.Localizer.get(undefined);

function toCalendarEvent($$event) {
  var start = $$event.startDateTime;
  var match;
  if (start !== undefined) {
    var start$1 = Caml_option.valFromOption(start);
    match = [
      start$1,
      new Date(start$1.getTime() + 1000.0 * 60.0 * $$event.minutes)
    ];
  } else {
    match = [
      new Date(),
      new Date()
    ];
  }
  return {
          _id: Belt_Option.getWithDefault($$event.dropInStudentsId, ""),
          _id2: $$event.objectId,
          title: Belt_Option.getWithDefault($$event.name, "Drop in: " + Belt_Array.map($$event.courses, (function (course) {
                        return course.subject + " " + course.number;
                      })).join(", ")),
          start: match[0],
          end: match[1],
          resource: JSON.stringify(EventResponse$NimbusWeb.toJson($$event))
        };
}

function EventView(Props) {
  var dropInStudentsId = Props.dropInStudentsId;
  var authenticatedOpt = Props.authenticated;
  var authenticated = authenticatedOpt !== undefined ? authenticatedOpt : false;
  var match = React.useContext(AuthContext$NimbusWeb.UserType.context);
  var userType = match[0];
  var match$1 = React.useContext(UserContext$NimbusWeb.context);
  var userProfile = match$1[0];
  var request = Request$NimbusWeb.use(undefined);
  var match$2 = React.useContext(SelectedCoursesContext$NimbusWeb.context);
  var setSelectedCourses = match$2[1];
  var selectedCourses = match$2[0];
  var match$3 = React.useState(function () {
        return [];
      });
  var setEvents = match$3[1];
  var match$4 = React.useState(function () {
        
      });
  var setSelectedEvent = match$4[1];
  var selectedEvent = match$4[0];
  var match$5 = React.useState(function () {
        
      });
  var setDropInStudent = match$5[1];
  var dropInStudent = match$5[0];
  var match$6 = React.useState(function () {
        
      });
  var setDropIn = match$6[1];
  var dropIn = match$6[0];
  var match$7 = React.useState(function () {
        return false;
      });
  var setShowAddInterestAlert = match$7[1];
  React.useEffect((function () {
          if (dropInStudentsId !== undefined) {
            var dropInStudentsPromise = Curry._1(request, DropInStudentsRequest_FetchById$NimbusWeb.make(dropInStudentsId));
            var eventPromise = Curry._1(request, EventRequest_FetchByDropInStudentsId$NimbusWeb.make(dropInStudentsId));
            Promise.all([
                      dropInStudentsPromise,
                      eventPromise
                    ]).then(function (param) {
                    var dropInStudents = DropInStudentsRequest_FetchById$NimbusWeb.$$Response.normalize(Curry._1(DropInStudentsRequest_FetchById$NimbusWeb.$$Response.Decode.exec, param[0]));
                    var $$event = EventRequest_FetchByDropInStudentsId$NimbusWeb.$$Response.normalize(Curry._1(EventRequest_FetchByDropInStudentsId$NimbusWeb.$$Response.Decode.exec, param[1]));
                    var dropInPromise = Curry._1(request, DropInRequest_FetchById$NimbusWeb.make(Belt_Option.getWithDefault(Belt_Option.map(dropInStudents, (function (dis) {
                                        return dis.dropInId;
                                      })), "")));
                    return Promise.all([
                                dropInPromise,
                                Promise.resolve(dropInStudents),
                                Promise.resolve($$event)
                              ]);
                  }).then(function (param) {
                  var $$event = param[2];
                  var dropInStudents = param[1];
                  var dropIn = DropInRequest_FetchById$NimbusWeb.$$Response.normalize(Curry._1(DropInRequest_FetchById$NimbusWeb.$$Response.Decode.exec, param[0]));
                  Curry._1(setDropIn, (function (param) {
                          return dropIn;
                        }));
                  Curry._1(setDropInStudent, (function (param) {
                          return dropInStudents;
                        }));
                  Curry._1(setSelectedEvent, (function (param) {
                          return Belt_Option.map($$event, toCalendarEvent);
                        }));
                  return Promise.resolve(undefined);
                });
          } else if (selectedCourses.length === 0) {
            Navigation$NimbusWeb.push("find");
          } else {
            var courseIds = Belt_Array.map(selectedCourses, (function (course) {
                      return Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(course, "_id"), Js_json.decodeString), "Invalid");
                    })).filter(function (id) {
                  return id !== "Invalid";
                });
            Curry._1(request, EventRequest_FetchPublic$NimbusWeb.make(courseIds)).then(function (json) {
                  var data = EventRequest_FetchPublic$NimbusWeb.$$Response.normalize(Curry._1(EventRequest_FetchPublic$NimbusWeb.$$Response.Decode.exec, json));
                  Curry._1(setEvents, (function (param) {
                          return Belt_Array.map(data, toCalendarEvent);
                        }));
                  Curry._1(setSelectedCourses, (function (param) {
                          return [];
                        }));
                  return Promise.resolve(undefined);
                });
          }
          
        }), []);
  var onSelectEvent = function ($$event, param) {
    Curry._1(setDropInStudent, (function (param) {
            
          }));
    Curry._1(request, DropInStudentsRequest_FetchById$NimbusWeb.make($$event._id)).then(function (json) {
            var data = DropInStudentsRequest_FetchById$NimbusWeb.$$Response.normalize(Curry._1(DropInStudentsRequest_FetchById$NimbusWeb.$$Response.Decode.exec, json));
            return Promise.all([
                        Curry._1(request, DropInRequest_FetchById$NimbusWeb.make($$event._id2)),
                        Promise.resolve(data)
                      ]);
          }).then(function (param) {
          var dropInStudent = param[1];
          var data = DropInRequest_FetchById$NimbusWeb.$$Response.normalize(Curry._1(DropInRequest_FetchById$NimbusWeb.$$Response.Decode.exec, param[0]));
          Curry._1(setDropIn, (function (param) {
                  return data;
                }));
          Curry._1(setDropInStudent, (function (param) {
                  return dropInStudent;
                }));
          Curry._1(setSelectedEvent, (function (param) {
                  return $$event;
                }));
          return Promise.resolve(undefined);
        });
    
  };
  var addToNimbusCalendar = React.useCallback((function ($$event) {
          return function (dropIn) {
            var dropInId = dropIn._id;
            var eventJson = EventResponse$NimbusWeb.parseCalendarEvent($$event);
            var value = Js_json.classify(eventJson);
            var timeblockId;
            if (typeof value === "number" || value.TAG !== /* JSONObject */2) {
              timeblockId = "";
            } else {
              var timeblockId$1 = Js_dict.get(value._0, "timeblockId");
              if (timeblockId$1 !== undefined) {
                var timeblockId$2 = Js_json.decodeString(Caml_option.valFromOption(timeblockId$1));
                timeblockId = timeblockId$2 !== undefined ? timeblockId$2 : "";
              } else {
                timeblockId = "";
              }
            }
            Curry._1(request, DropInRequest_ShowInterest$NimbusWeb.make(dropInId, timeblockId)).then(function (param) {
                  Navigation$NimbusWeb.push("lesson");
                  return Promise.resolve(undefined);
                });
            
          };
        }), [request]);
  var onClickSignIn = function (param) {
    if (selectedEvent !== undefined && dropInStudent !== undefined) {
      var eventJson = EventResponse$NimbusWeb.parseCalendarEvent(selectedEvent);
      var value = Js_json.classify(eventJson);
      if (typeof value === "number" || value.TAG !== /* JSONObject */2) {
        console.log("Expected Json object");
      } else {
        var courses = Js_dict.get(value._0, "courses");
        if (courses !== undefined) {
          var courses$1 = Js_json.decodeArray(Caml_option.valFromOption(courses));
          if (courses$1 !== undefined) {
            var courses$2 = courses$1.map(function (course) {
                    var course$1 = Js_json.decodeObject(course);
                    if (course$1 !== undefined) {
                      return Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(Caml_option.valFromOption(course$1), "_id"), Js_json.decodeString), "");
                    } else {
                      return "";
                    }
                  }).filter(function (id) {
                  return id !== "";
                });
            Storage$NimbusWeb.set(/* CalendarEvent */4, Curry._1(BigCalendar$NimbusWeb.Calendar.Json.stringifyEvent, selectedEvent));
            Storage$NimbusWeb.set(/* Course */2, courses$2.join(","));
            Storage$NimbusWeb.set(/* DropInStudents */3, dropInStudent._id);
          }
          
        }
        
      }
    }
    return Navigation$NimbusWeb.push("login");
  };
  var authenticated$1 = userType === "tutor" ? false : authenticated;
  return React.createElement(EventViewUI$NimbusWeb.Container.make, {
              children: null
            }, React.createElement(ReactBigCalendar.Calendar, {
                  localizer: localizer,
                  views: [
                    "month",
                    "week",
                    "day",
                    "agenda"
                  ],
                  events: match$3[0],
                  onSelectEvent: onSelectEvent
                }), match$7[0] ? (
                selectedEvent !== undefined && dropIn !== undefined ? React.createElement(EventViewUI$NimbusWeb.AddInterestAlert.make, {
                        onClose: (function (param) {
                            return Curry._1(setShowAddInterestAlert, (function (param) {
                                          return false;
                                        }));
                          }),
                        authenticated: authenticated$1,
                        onPrimary: authenticated$1 ? (function (param) {
                              return Curry._2(addToNimbusCalendar, selectedEvent, dropIn);
                            }) : onClickSignIn,
                        online: dropIn.online
                      }) : null
              ) : (
                selectedEvent !== undefined && dropIn !== undefined && dropInStudent !== undefined ? React.createElement(EventViewUI$NimbusWeb.EventPopup.make, {
                        event: selectedEvent,
                        dropIn: dropIn,
                        dropInStudent: dropInStudent,
                        onClose: (function (param) {
                            return Curry._1(setSelectedEvent, (function (param) {
                                          
                                        }));
                          }),
                        onClickJoin: (function (param) {
                            var sessionObjectId = dropInStudent._id;
                            Curry._1(request, EventRequest_FetchZoomMeeting$NimbusWeb.make(sessionObjectId)).then(function (json) {
                                  var zoomMeetingObject = EventRequest_FetchZoomMeeting$NimbusWeb.$$Response.normalize(Curry._1(EventRequest_FetchZoomMeeting$NimbusWeb.$$Response.Decode.exec, json));
                                  if (zoomMeetingObject !== undefined) {
                                    var isHost = Belt_Option.mapWithDefault(userProfile, false, (function (user) {
                                            return user._id === dropIn.host._id;
                                          }));
                                    var url = isHost ? zoomMeetingObject.startUrl : zoomMeetingObject.joinUrl;
                                    if (url !== undefined) {
                                      window.open(url, "_blank");
                                    } else {
                                      Curry._1(setShowAddInterestAlert, (function (param) {
                                              return true;
                                            }));
                                    }
                                  } else {
                                    Curry._1(setShowAddInterestAlert, (function (param) {
                                            return true;
                                          }));
                                  }
                                  return Promise.resolve(undefined);
                                });
                            
                          }),
                        userId: Belt_Option.map(userProfile, (function (user) {
                                return user._id;
                              }))
                      }) : null
              ));
}

var make = EventView;

exports.localizer = localizer;
exports.toCalendarEvent = toCalendarEvent;
exports.make = make;
/* localizer Not a pure module */
