'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Context$NimbusWeb = require("./Context.bs.js");
var Request$NimbusWeb = require("../utils/Request.bs.js");
var DropInRequest_Fetch$NimbusWeb = require("../scenes/lesson/requests/DropInRequest_Fetch.bs.js");

var defaultValue_dropIns = [];

function defaultValue_setDropIns(param) {
  
}

function defaultValue_fetchByIds(param) {
  return Promise.resolve([]);
}

var defaultValue = {
  dropIns: defaultValue_dropIns,
  setDropIns: defaultValue_setDropIns,
  fetchByIds: defaultValue_fetchByIds
};

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function DropInsContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return [];
      });
  var request = Request$NimbusWeb.use(undefined);
  var fetchByIds = function (ids) {
    return Curry._1(request, DropInRequest_Fetch$NimbusWeb.make(ids)).then(function (json) {
                return Promise.resolve(DropInRequest_Fetch$NimbusWeb.$$Response.normalize(Curry._1(DropInRequest_Fetch$NimbusWeb.$$Response.Decode.exec, json)));
              });
  };
  var api_dropIns = match[0];
  var api_setDropIns = match[1];
  var api = {
    dropIns: api_dropIns,
    setDropIns: api_setDropIns,
    fetchByIds: fetchByIds
  };
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, api, children, undefined));
}

var Wrap = {
  make: DropInsContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
