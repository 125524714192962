'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Typography$NimbusWeb = require("./Typography.bs.js");

require("./Table.css");

function convert(value) {
  var str = Js_json.decodeString(value);
  if (str !== undefined) {
    return str;
  }
  var num = Js_json.decodeNumber(value);
  if (num !== undefined) {
    return String(num);
  }
  var b = Js_json.decodeBoolean(value);
  if (b !== undefined) {
    if (b) {
      return "true";
    } else {
      return "false";
    }
  } else {
    return "No displayable type";
  }
}

function Table(Props) {
  var headerOpt = Props.header;
  var data = Props.data;
  var hoverOpt = Props.hover;
  var onClickRowOpt = Props.onClickRow;
  var selectedOpt = Props.selected;
  var ref_ = Props.ref_;
  var header = headerOpt !== undefined ? headerOpt : [];
  var hover = hoverOpt !== undefined ? hoverOpt : false;
  var onClickRow = onClickRowOpt !== undefined ? onClickRowOpt : (function (param) {
        
      });
  var selected = selectedOpt !== undefined ? selectedOpt : [];
  return React.createElement("table", {
              ref: ref_,
              style: {
                backgroundColor: "#f7f8fc",
                width: "100%",
                borderRadius: "5px"
              }
            }, header.length === 0 ? null : React.createElement("thead", undefined, React.createElement("tr", undefined, Belt_Array.map(header, (function (h) {
                              return React.createElement("th", {
                                          key: h.key,
                                          style: {
                                            textAlign: "left"
                                          }
                                        }, React.createElement(Typography$NimbusWeb.make, {
                                              value: h.label
                                            }));
                            })))), data.length === 0 ? null : React.createElement("tbody", undefined, Belt_Array.mapWithIndex(data, (function (i, d) {
                          var id = Js_dict.get(d, "_id");
                          var selectedId = selected.find(function (s) {
                                return Js_dict.get(s, "_id") === id;
                              });
                          var className = selectedId !== undefined ? "table-selected" : "";
                          var className$1 = hover ? className + " table-hover-row" : className;
                          return React.createElement("tr", {
                                      key: String(i),
                                      className: className$1,
                                      onClick: (function (param) {
                                          return Curry._1(onClickRow, id);
                                        })
                                    }, Belt_Array.map(header, (function (h) {
                                            var value = Js_dict.get(d, h.key);
                                            return React.createElement("td", {
                                                        key: h.key
                                                      }, React.createElement(Typography$NimbusWeb.make, {
                                                            color: /* Black */11,
                                                            value: value !== undefined ? convert(Caml_option.valFromOption(value)) : "N/A"
                                                          }));
                                          })));
                        }))));
}

var make = Table;

exports.convert = convert;
exports.make = make;
/*  Not a pure module */
