'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Banner$NimbusWeb = require("../components/Banner.bs.js");
var Button$NimbusWeb = require("../components/Button.bs.js");
var Context$NimbusWeb = require("./Context.bs.js");
var Icon_Cancel$NimbusWeb = require("../icons/Icon_Cancel.bs.js");

function defaultValue(param) {
  
}

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function ErrorContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        
      });
  var setErrorMessage = match[1];
  var errorMessage = match[0];
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, setErrorMessage, null, undefined), children, errorMessage !== undefined && errorMessage !== "" ? React.createElement(Banner$NimbusWeb.make, {
                    variant: /* Error */2,
                    position: {
                      TAG: /* Bottom */1,
                      _0: "8px"
                    },
                    children: null
                  }, React.createElement(Banner$NimbusWeb.Message.make, {
                        text: errorMessage
                      }), React.createElement(Banner$NimbusWeb.RightAdornment.make, {
                        children: React.createElement(Button$NimbusWeb.Icon.make, {
                              onClick: (function (param) {
                                  return Curry._1(setErrorMessage, (function (param) {
                                                
                                              }));
                                }),
                              children: React.createElement(Icon_Cancel$NimbusWeb.make, {
                                    color: /* White */10
                                  }),
                              ariaLabel: "close error banner",
                              accessKey: "x"
                            })
                      })) : null);
}

var Wrap = {
  make: ErrorContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
