'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

var Types = {};

var Fragment_university = "\n    fragment UniversityFragment on University {\n      _id\n      name\n      photo\n      photoHome\n    }\n  ";

var Fragment_course = "\n    fragment CourseFragment on Course {\n      _id\n      subject\n      number\n      title\n      availableTutorsCountDropInPublic\n      externalUrl\n    }\n  ";

var Fragment = {
  university: Fragment_university,
  course: Fragment_course
};

function university(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          photo: Json_decode.optional((function (param) {
                  return Json_decode.field("photo", Json_decode.string, param);
                }), json),
          photoHome: Json_decode.optional((function (param) {
                  return Json_decode.field("photoHome", Json_decode.string, param);
                }), json)
        };
}

function course(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          subject: Json_decode.field("subject", Json_decode.string, json),
          number: Json_decode.field("number", Json_decode.string, json),
          title: Json_decode.field("title", Json_decode.string, json),
          availableTutorsCount: Json_decode.optional((function (param) {
                  return Json_decode.field("availableTutorsCountDropInPublic", Json_decode.$$int, param);
                }), json),
          externalUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("externalUrl", Json_decode.string, param);
                }), json)
        };
}

var Decode = {
  university: university,
  course: course
};

exports.Types = Types;
exports.Fragment = Fragment;
exports.Decode = Decode;
/* No side effect */
