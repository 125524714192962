'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var LessonResponse$NimbusWeb = require("./LessonResponse.bs.js");

function make(ids) {
  var query = "\n    query (\$lessonIds: [ID]!) {\n      lessonsByIds(_lessons: \$lessonIds) {\n        ...LessonFragment\n      }\n    }\n    " + LessonResponse$NimbusWeb.fragment + "\n  ";
  var variables = [{
      key: "lessonIds",
      val: ids
    }];
  return Request$NimbusWeb.createBody(query, variables);
}

function data(json) {
  return {
          lessons: Json_decode.optional((function (param) {
                  return Json_decode.field("lessonsByIds", (function (param) {
                                return Json_decode.array(LessonResponse$NimbusWeb.Decode.lesson, param);
                              }), param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", data, param);
                }), json)
        };
}

var Decode = {
  data: data,
  exec: exec
};

function normalize(payload) {
  return Belt_Option.flatMap(payload.data, (function (data) {
                return Belt_Option.map(data.lessons, (function (lessons) {
                              return Belt_Array.map(lessons, LessonResponse$NimbusWeb.normalize);
                            }));
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
