'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_Cancel(Props) {
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: "18",
              width: "18",
              fill: "none",
              viewBox: "0 0 18 18",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M9 0C4.02823 0 0 4.02823 0 9C0 13.9718 4.02823 18 9 18C13.9718 18 18 13.9718 18 9C18 4.02823 13.9718 0 9 0ZM9 16.2581C4.98992 16.2581 1.74194 13.0101 1.74194 9C1.74194 4.98992 4.98992 1.74194 9 1.74194C13.0101 1.74194 16.2581 4.98992 16.2581 9C16.2581 13.0101 13.0101 16.2581 9 16.2581ZM12.6944 6.74274L10.4371 9L12.6944 11.2573C12.8649 11.4278 12.8649 11.7036 12.6944 11.8742L11.8742 12.6944C11.7036 12.8649 11.4278 12.8649 11.2573 12.6944L9 10.4371L6.74274 12.6944C6.57218 12.8649 6.29637 12.8649 6.12581 12.6944L5.30564 11.8742C5.13508 11.7036 5.13508 11.4278 5.30564 11.2573L7.5629 9L5.30564 6.74274C5.13508 6.57218 5.13508 6.29637 5.30564 6.12581L6.12581 5.30564C6.29637 5.13508 6.57218 5.13508 6.74274 5.30564L9 7.5629L11.2573 5.30564C11.4278 5.13508 11.7036 5.13508 11.8742 5.30564L12.6944 6.12581C12.8649 6.29637 12.8649 6.57218 12.6944 6.74274Z",
                  fill: c
                }));
}

var make = Icon_Cancel;

exports.make = make;
/* react Not a pure module */
