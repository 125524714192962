'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var Sidebar$NimbusWeb = require("../../components/Sidebar.bs.js");
var BrandIcon$NimbusWeb = require("../../components/BrandIcon.bs.js");
var Icon_Menu$NimbusWeb = require("../../icons/Icon_Menu.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var BrandContext$NimbusWeb = require("../../contexts/BrandContext.bs.js");

require("./AppBarLogin.css");

function AppBarLogin(Props) {
  var onClickLogIn = Props.onClickLogIn;
  var onClickLogOut = Props.onClickLogOut;
  var showMenuOpt = Props.showMenu;
  var showMenu = showMenuOpt !== undefined ? showMenuOpt : false;
  var setShowSidebar = React.useContext(Sidebar$NimbusWeb.Wrap.context);
  var match = React.useContext(BrandContext$NimbusWeb.context);
  return React.createElement("div", {
              className: "app-bar-login-container"
            }, React.createElement("div", {
                  className: "app-bar-login-logo-container"
                }, React.createElement(BrandIcon$NimbusWeb.make, {
                      icon: match[1],
                      isDefault: match[0]
                    }), React.createElement(Typography$NimbusWeb.make, {
                      variant: /* H3 */2,
                      color: /* White */10,
                      value: match[2]
                    })), onClickLogIn !== undefined ? React.createElement(Button$NimbusWeb.make, {
                    variant: /* Contained */1,
                    className: "app-bar-login-sign-in",
                    onClick: onClickLogIn,
                    text: "Sign In",
                    accessKey: "c"
                  }) : null, onClickLogOut !== undefined ? React.createElement(Button$NimbusWeb.make, {
                    variant: /* Contained */1,
                    className: "app-bar-login-sign-in",
                    onClick: onClickLogOut,
                    text: "Sign Out",
                    accessKey: "g"
                  }) : null, showMenu ? React.createElement(Button$NimbusWeb.Icon.make, {
                    className: "app-bar-login-menu",
                    onClick: (function (param) {
                        return Curry._1(setShowSidebar, (function (state) {
                                      return !state;
                                    }));
                      }),
                    children: React.createElement(Icon_Menu$NimbusWeb.make, {}),
                    ariaLabel: "menu",
                    accessKey: "e"
                  }) : null);
}

var make = AppBarLogin;

exports.make = make;
/*  Not a pure module */
