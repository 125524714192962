'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Helmet$NimbusWeb = require("../../components/Helmet.bs.js");
var Loader$NimbusWeb = require("../../components/Loader.bs.js");
var Helpers$NimbusWeb = require("../../utils/Helpers.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var Storage$NimbusWeb = require("../../utils/Storage.bs.js");
var EventCard$NimbusWeb = require("./EventCard.bs.js");
var SlideShow$NimbusWeb = require("../../components/SlideShow.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var AuthContext$NimbusWeb = require("../../contexts/AuthContext.bs.js");
var BigCalendar$NimbusWeb = require("../../components/BigCalendar.bs.js");
var EventViewUI$NimbusWeb = require("../view/EventViewUI.bs.js");
var UserContext$NimbusWeb = require("../../contexts/UserContext.bs.js");
var EventResponse$NimbusWeb = require("./requests/EventResponse.bs.js");
var EventsContext$NimbusWeb = require("../../contexts/EventsContext.bs.js");
var LessonContext$NimbusWeb = require("../../contexts/LessonContext.bs.js");
var LocaleContext$NimbusWeb = require("../../contexts/LocaleContext.bs.js");
var DropInsContext$NimbusWeb = require("../../contexts/DropInsContext.bs.js");
var ZoomDownloadAlert$NimbusWeb = require("./ZoomDownloadAlert.bs.js");
var EventRequest_Fetch$NimbusWeb = require("./requests/EventRequest_Fetch.bs.js");
var ArriveAtLocationAlert$NimbusWeb = require("./ArriveAtLocationAlert.bs.js");
var DropInStudentsContext$NimbusWeb = require("../../contexts/DropInStudentsContext.bs.js");
var ZoomNotAvailableAlert$NimbusWeb = require("./ZoomNotAvailableAlert.bs.js");
var DropInRequest_FetchById$NimbusWeb = require("./requests/DropInRequest_FetchById.bs.js");
var DropInRequest_ShowInterest$NimbusWeb = require("./requests/DropInRequest_ShowInterest.bs.js");
var EventRequest_FetchZoomMeeting$NimbusWeb = require("./requests/EventRequest_FetchZoomMeeting.bs.js");
var DropInStudentsRequest_FetchById$NimbusWeb = require("./requests/DropInStudentsRequest_FetchById.bs.js");

require("./Lesson.css");

function Lesson(Props) {
  var id = Props.id;
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var request = Request$NimbusWeb.use(undefined);
  var match$1 = React.useContext(LocaleContext$NimbusWeb.context);
  var text = match$1[0];
  var match$2 = React.useContext(AuthContext$NimbusWeb.UserType.context);
  var userType = match$2[0];
  var match$3 = React.useContext(UserContext$NimbusWeb.context);
  var userProfile = match$3[0];
  var match$4 = React.useContext(EventsContext$NimbusWeb.context);
  var setSelectedEvent = match$4[3];
  var selectedEvent = match$4[2];
  var setEvents = match$4[1];
  var events = match$4[0];
  var lessonApi = React.useContext(LessonContext$NimbusWeb.context);
  var dropInApi = React.useContext(DropInsContext$NimbusWeb.context);
  var dropInStudentsApi = React.useContext(DropInStudentsContext$NimbusWeb.context);
  var match$5 = React.useState(function () {
        return [
                undefined,
                true
              ];
      });
  var setShowZoomDownloadAlert = match$5[1];
  var showZoomDownloadAlert = match$5[0];
  var match$6 = React.useState(function () {
        return false;
      });
  var setShowZoomUnavailableAlert = match$6[1];
  var showZoomUnavailableAlert = match$6[0];
  var match$7 = React.useState(function () {
        return false;
      });
  var setShowArriveAtLocationAlert = match$7[1];
  var showArriveAtLocationAlert = match$7[0];
  var match$8 = React.useState(function () {
        
      });
  var setStorageEvent = match$8[1];
  var storageEvent = match$8[0];
  var match$9 = React.useState(function () {
        
      });
  var setStorageDropIn = match$9[1];
  var storageDropIn = match$9[0];
  var match$10 = React.useState(function () {
        
      });
  var setStorageDropInStudents = match$10[1];
  var storageDropInStudents = match$10[0];
  React.useEffect((function () {
          if (userType === "student") {
            var eventString = Storage$NimbusWeb.get(/* CalendarEvent */4);
            var courseIdsString = Storage$NimbusWeb.get(/* Course */2);
            var dropInStudentsString = Storage$NimbusWeb.get(/* DropInStudents */3);
            if (eventString !== undefined && courseIdsString !== undefined && dropInStudentsString !== undefined && eventString !== "" && courseIdsString !== "" && dropInStudentsString !== "") {
              Curry._1(request, DropInStudentsRequest_FetchById$NimbusWeb.make(dropInStudentsString)).then(function (json) {
                      var data = DropInStudentsRequest_FetchById$NimbusWeb.$$Response.normalize(Curry._1(DropInStudentsRequest_FetchById$NimbusWeb.$$Response.Decode.exec, json));
                      return Promise.all([
                                  Curry._1(request, DropInRequest_FetchById$NimbusWeb.make(Belt_Option.getWithDefault(Belt_Option.map(data, (function (dis) {
                                                      return dis.dropInId;
                                                    })), ""))),
                                  Promise.resolve(data)
                                ]);
                    }).then(function (param) {
                    var dropInStudents = param[1];
                    var data = DropInRequest_FetchById$NimbusWeb.$$Response.normalize(Curry._1(DropInRequest_FetchById$NimbusWeb.$$Response.Decode.exec, param[0]));
                    var $$event = Curry._1(BigCalendar$NimbusWeb.Calendar.Json.parseEvent, eventString);
                    Curry._1(setStorageEvent, (function (param) {
                            return $$event;
                          }));
                    Curry._1(setStorageDropIn, (function (param) {
                            return data;
                          }));
                    Curry._1(setStorageDropInStudents, (function (param) {
                            return dropInStudents;
                          }));
                    return Promise.resolve(undefined);
                  });
            }
            Storage$NimbusWeb.set(/* CalendarEvent */4, "");
            Storage$NimbusWeb.set(/* Course */2, "");
            Storage$NimbusWeb.set(/* DropInStudents */3, "");
          }
          
        }), []);
  var match$11 = React.useState(function () {
        return 0;
      });
  var setPageStart = match$11[1];
  React.useEffect((function () {
          var match = events.length;
          if (id !== undefined && match > 0) {
            Curry._1(setSelectedEvent, (function (param) {
                    return Belt_Option.map(Caml_option.undefined_to_opt(events.find(function ($$event) {
                                        return $$event.objectId === id;
                                      })), (function ($$event) {
                                  return $$event._id;
                                }));
                  }));
          }
          
        }), [
        id,
        events.length
      ]);
  React.useEffect((function () {
          var match = events.length;
          if (selectedEvent !== undefined && match > 0) {
            var index = events.findIndex(function ($$event) {
                  return $$event._id === selectedEvent;
                });
            Curry._1(setPageStart, (function (param) {
                    return Math.max(0, index);
                  }));
          }
          
        }), [
        selectedEvent,
        events.length
      ]);
  var fetchEvents = function (param) {
    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    var in100Days = today.getTime() + 1000.0 * 60.0 * 60.0 * 24.0 * 100.0;
    return Curry._1(request, EventRequest_Fetch$NimbusWeb.make(String(today.getTime()), String(in100Days))).then(function (json) {
                    return Promise.resolve(EventRequest_Fetch$NimbusWeb.$$Response.normalize(Curry._1(EventRequest_Fetch$NimbusWeb.$$Response.Decode.exec, json)));
                  }).then(function (data) {
                  var filteredData = data.filter(function ($$event) {
                        return Belt_Option.mapWithDefault($$event.status, false, (function (status) {
                                      if (status === "OPEN" || status === "CONFIRMED" || status === "STARTED") {
                                        return true;
                                      } else {
                                        return status === "COMPLETED";
                                      }
                                    }));
                      });
                  if (filteredData.length === 0) {
                    Curry._1(setLoading, (function (param) {
                            return false;
                          }));
                  }
                  Curry._1(setEvents, (function (param) {
                          return filteredData;
                        }));
                  return Promise.resolve(undefined);
                }).catch(function (param) {
                return Promise.resolve(Curry._1(setLoading, (function (param) {
                                  return false;
                                })));
              });
  };
  React.useEffect((function () {
          Curry._1(setLoading, (function (param) {
                  return true;
                }));
          fetchEvents(undefined);
          
        }), []);
  var fetchLessons = function (eventArray) {
    var lessonEvents = eventArray.filter(function ($$event) {
          return $$event.eventType === "PRIVATE";
        });
    if (lessonEvents.length > 0) {
      return Curry._1(lessonApi.fetchByIds, Belt_Array.map(lessonEvents, (function ($$event) {
                          return $$event.objectId;
                        }))).then(function (lessonData) {
                  Curry._1(lessonApi.setLessons, (function (param) {
                          return lessonData;
                        }));
                  return Promise.resolve(true);
                });
    } else {
      return Promise.resolve(true);
    }
  };
  var fetchDropIns = function (eventArray) {
    var dropInEvents = eventArray.filter(function ($$event) {
          return $$event.eventType === "DROP_IN";
        });
    if (dropInEvents.length > 0) {
      return Promise.all([
                    Curry._1(dropInApi.fetchByIds, Belt_Array.map(dropInEvents, (function ($$event) {
                                return $$event.objectId;
                              }))),
                    Curry._1(dropInStudentsApi.fetchList, Belt_Array.map(dropInEvents, (function ($$event) {
                                return [
                                        $$event.objectId,
                                        Belt_Option.getWithDefault($$event.startDateTime, new Date()),
                                        $$event.minutes
                                      ];
                              })))
                  ]).then(function (param) {
                  var dropInStudentsData = param[1];
                  var dropInData = param[0];
                  Curry._1(dropInApi.setDropIns, (function (param) {
                          return dropInData;
                        }));
                  Curry._1(dropInStudentsApi.setDropInStudents, (function (param) {
                          return dropInStudentsData;
                        }));
                  return Promise.resolve(true);
                });
    } else {
      return Promise.resolve(true);
    }
  };
  React.useEffect((function () {
          Promise.all([
                    fetchLessons(events),
                    fetchDropIns(events)
                  ]).then(function (param) {
                  if (events.length > 0 && param[0] && param[1]) {
                    Curry._1(setLoading, (function (param) {
                            return false;
                          }));
                  }
                  return Promise.resolve(undefined);
                }).catch(function (param) {
                return Promise.resolve(Curry._1(setLoading, (function (param) {
                                  return false;
                                })));
              });
          
        }), [events.length]);
  var onClickStart = function (eventId, lesson, dropInStudent, dropIn) {
    var isOnline = lesson !== undefined ? lesson.online : (
        dropIn !== undefined ? dropIn.online : false
      );
    if (!isOnline) {
      return Curry._1(setShowArriveAtLocationAlert, (function (param) {
                    return true;
                  }));
    }
    var sessionObjectId = lesson !== undefined ? lesson._id : (
        dropInStudent !== undefined ? dropInStudent._id : ""
      );
    Curry._1(setSelectedEvent, (function (param) {
            return eventId;
          }));
    Curry._1(request, EventRequest_FetchZoomMeeting$NimbusWeb.make(sessionObjectId)).then(function (json) {
          var zoomMeetingObject = EventRequest_FetchZoomMeeting$NimbusWeb.$$Response.normalize(Curry._1(EventRequest_FetchZoomMeeting$NimbusWeb.$$Response.Decode.exec, json));
          if (zoomMeetingObject !== undefined) {
            var zoomUrl;
            if (userType !== undefined) {
              switch (userType) {
                case "student" :
                    zoomUrl = zoomMeetingObject.joinUrl;
                    break;
                case "tutor" :
                    zoomUrl = dropIn !== undefined ? (
                        Belt_Option.mapWithDefault(userProfile, false, (function (user) {
                                return user._id === dropIn.host._id;
                              })) || lesson !== undefined ? zoomMeetingObject.startUrl : zoomMeetingObject.joinUrl
                      ) : (
                        lesson !== undefined ? zoomMeetingObject.startUrl : zoomMeetingObject.joinUrl
                      );
                    break;
                default:
                  zoomUrl = undefined;
              }
            } else {
              zoomUrl = undefined;
            }
            if (zoomUrl !== undefined) {
              window.open(zoomUrl, "_blank");
            } else {
              Curry._1(setShowZoomUnavailableAlert, (function (param) {
                      return true;
                    }));
            }
          } else {
            Curry._1(setShowZoomUnavailableAlert, (function (param) {
                    return true;
                  }));
          }
          return Promise.resolve(undefined);
        });
    
  };
  var renderEvents = function (param) {
    if (events.length === 0) {
      return [React.createElement(EventCard$NimbusWeb.make, {
                    children: React.createElement(EventCard$NimbusWeb.Section.make, {
                          children: React.createElement(Typography$NimbusWeb.make, {
                                color: /* Black */11,
                                value: Curry._3(text, "lesson.no_lesson", undefined, undefined)
                              })
                        }),
                    height: ""
                  })];
    } else {
      return Belt_Array.map(events, (function ($$event) {
                    var match = $$event.eventType;
                    switch (match) {
                      case "DROP_IN" :
                          var match$1 = Helpers$NimbusWeb.getDropInFromEvent($$event, dropInApi.dropIns, dropInStudentsApi.dropInStudents);
                          var dropIn = match$1[0];
                          var dropInStudent = match$1[1];
                          var defaultName = "Unknown User";
                          var tmp;
                          if (userType !== undefined) {
                            switch (userType) {
                              case "student" :
                                  tmp = Belt_Option.flatMap(dropIn, (function (d) {
                                          return d.host.avatar;
                                        }));
                                  break;
                              case "tutor" :
                                  tmp = undefined;
                                  break;
                              default:
                                tmp = undefined;
                            }
                          } else {
                            tmp = undefined;
                          }
                          var tmp$1;
                          if (userType !== undefined) {
                            switch (userType) {
                              case "student" :
                                  tmp$1 = Belt_Option.mapWithDefault(dropIn, defaultName, (function (d) {
                                          return d.host.first + (" " + d.host.last);
                                        }));
                                  break;
                              case "tutor" :
                                  tmp$1 = "Group";
                                  break;
                              default:
                                tmp$1 = defaultName;
                            }
                          } else {
                            tmp$1 = defaultName;
                          }
                          var tmp$2;
                          if (dropIn !== undefined) {
                            if (dropIn.online) {
                              tmp$2 = /* Online */0;
                            } else {
                              var $$location$1 = dropIn.location;
                              tmp$2 = $$location$1 !== undefined ? /* InPerson */({
                                    _0: $$location$1.name,
                                    _1: $$location$1.address
                                  }) : /* InPerson */({
                                    _0: Curry._3(text, "event_card.location.unavailable", undefined, undefined),
                                    _1: ""
                                  });
                            }
                          } else {
                            tmp$2 = /* InPerson */{
                              _0: Curry._3(text, "event_card.location.unavailable", undefined, undefined),
                              _1: ""
                            };
                          }
                          var tmp$3;
                          tmp$3 = userType === "tutor" ? React.createElement(EventCard$NimbusWeb.Section.make, {
                                  children: React.createElement(Typography$NimbusWeb.make, {
                                        color: /* Primary */0,
                                        value: Curry._3(text, "lesson.zoom.warning", undefined, undefined)
                                      }),
                                  divider: true
                                }) : null;
                          var isCompleted = Belt_Option.mapWithDefault($$event.status, false, (function (status) {
                                  return status === "COMPLETED";
                                }));
                          var interestedStudentsCount = Belt_Option.mapWithDefault(dropInStudent, 0, (function (d) {
                                  return d.interestedStudents.length;
                                }));
                          var tmp$4;
                          if (userType !== undefined) {
                            switch (userType) {
                              case "student" :
                                  tmp$4 = isCompleted ? React.createElement(EventCard$NimbusWeb.Section.make, {
                                          children: React.createElement(EventCard$NimbusWeb.Content.Count.InterestedStudents.make, {
                                                count: interestedStudentsCount
                                              })
                                        }) : React.createElement(EventCard$NimbusWeb.Section.make, {
                                          children: React.createElement(EventCard$NimbusWeb.Content.Count.InterestedStudents.make, {
                                                count: interestedStudentsCount
                                              })
                                        });
                                  break;
                              case "tutor" :
                                  tmp$4 = isCompleted ? React.createElement(React.Fragment, undefined, Belt_Option.mapWithDefault(dropInStudent, null, (function (d) {
                                                if (d.attendees.length === 0) {
                                                  return null;
                                                } else {
                                                  return React.createElement(EventCard$NimbusWeb.Content.StudentList.make, {
                                                              students: Belt_Option.mapWithDefault(dropInStudent, [], (function (d) {
                                                                      return d.attendees;
                                                                    }))
                                                            });
                                                }
                                              }))) : React.createElement(React.Fragment, undefined, React.createElement(EventCard$NimbusWeb.Section.make, {
                                              children: React.createElement(EventCard$NimbusWeb.Content.Count.InterestedStudents.make, {
                                                    count: interestedStudentsCount
                                                  })
                                            }), Belt_Option.mapWithDefault(dropInStudent, null, (function (d) {
                                                if (interestedStudentsCount === 0) {
                                                  return null;
                                                } else {
                                                  return React.createElement(EventCard$NimbusWeb.Content.StudentList.make, {
                                                              students: d.interestedStudents
                                                            });
                                                }
                                              })));
                                  break;
                              default:
                                tmp$4 = null;
                            }
                          } else {
                            tmp$4 = null;
                          }
                          return React.createElement(EventCard$NimbusWeb.make, {
                                      children: null
                                    }, React.createElement(EventCard$NimbusWeb.Header.make, {
                                          children: React.createElement(EventCard$NimbusWeb.Content.Header.make, {
                                                src: tmp,
                                                title: tmp$1,
                                                subtitle: Curry._3(text, "lesson.drop_in.subtitle", undefined, undefined),
                                                url: location.host + "/view" + Belt_Option.mapWithDefault(dropInStudent, "", (function (dis) {
                                                        return "?disid=" + dis._id;
                                                      }))
                                              })
                                        }), React.createElement(EventCard$NimbusWeb.Body.make, {
                                          children: null
                                        }, React.createElement(EventCard$NimbusWeb.Section.make, {
                                              children: React.createElement(EventCard$NimbusWeb.Content.TitleAndDescription.make, {
                                                    title: Belt_Option.mapWithDefault(dropIn, "Title", (function (d) {
                                                            return d.title;
                                                          })),
                                                    description: Belt_Option.flatMap(dropIn, (function (d) {
                                                            return d.description;
                                                          }))
                                                  }),
                                              divider: true
                                            }), React.createElement(EventCard$NimbusWeb.Section.make, {
                                              children: React.createElement(EventCard$NimbusWeb.Content.Schedule.make, {
                                                    date: Belt_Option.getWithDefault($$event.startDateTime, new Date()),
                                                    minutes: $$event.minutes
                                                  }),
                                              divider: true
                                            }), React.createElement(EventCard$NimbusWeb.Section.make, {
                                              children: React.createElement(EventCard$NimbusWeb.Content.$$Location.make, {
                                                    location: tmp$2
                                                  }),
                                              divider: true
                                            }), React.createElement(EventCard$NimbusWeb.Section.make, {
                                              children: React.createElement(EventCard$NimbusWeb.Content.Courses.make, {
                                                    courses: Belt_Array.map($$event.courses, (function (course) {
                                                            return course.subject + (" " + course.number);
                                                          }))
                                                  }),
                                              divider: true
                                            }), tmp$3, tmp$4), React.createElement(EventCard$NimbusWeb.Footer.make, {
                                          children: React.createElement(EventCard$NimbusWeb.Content.Footer.make, {
                                                startTime: $$event.startDateTime,
                                                onClick: (function (param) {
                                                    if (userType === undefined) {
                                                      return ;
                                                    }
                                                    switch (userType) {
                                                      case "student" :
                                                          return onClickStart($$event._id, undefined, dropInStudent, dropIn);
                                                      case "tutor" :
                                                          return Curry._1(setShowZoomDownloadAlert, (function (param) {
                                                                        return [
                                                                                (function (param) {
                                                                                    return onClickStart($$event._id, undefined, dropInStudent, dropIn);
                                                                                  }),
                                                                                false
                                                                              ];
                                                                      }));
                                                      default:
                                                        return ;
                                                    }
                                                  })
                                              })
                                        }));
                      case "PRIVATE" :
                          var lesson = Helpers$NimbusWeb.getLessonFromEvent($$event, lessonApi.lessons);
                          var shouldRenderHelp = Belt_Option.mapWithDefault(lesson, false, (function (l) {
                                  var match = l.helpType;
                                  var match$1 = l.helpDescription;
                                  if (match !== undefined) {
                                    if (match !== "") {
                                      return true;
                                    } else if (match$1 !== undefined) {
                                      return match$1 !== "";
                                    } else {
                                      return false;
                                    }
                                  } else if (match$1 !== undefined) {
                                    return match$1 !== "";
                                  } else {
                                    return false;
                                  }
                                }));
                          var tmp$5;
                          if (userType !== undefined) {
                            switch (userType) {
                              case "student" :
                                  tmp$5 = Belt_Option.flatMap(lesson, (function (l) {
                                          return l.avatarTutor;
                                        }));
                                  break;
                              case "tutor" :
                                  tmp$5 = Belt_Option.flatMap(lesson, (function (l) {
                                          return l.avatarStudent;
                                        }));
                                  break;
                              default:
                                tmp$5 = undefined;
                            }
                          } else {
                            tmp$5 = undefined;
                          }
                          var tmp$6;
                          if (Belt_Option.mapWithDefault(lesson, false, (function (l) {
                                    return l.online;
                                  }))) {
                            tmp$6 = /* Online */0;
                          } else {
                            var match$2 = Belt_Option.flatMap(lesson, (function (l) {
                                    return l.locationName;
                                  }));
                            var match$3 = Belt_Option.flatMap(lesson, (function (l) {
                                    return l.locationAddress;
                                  }));
                            tmp$6 = match$2 !== undefined && match$3 !== undefined ? /* InPerson */({
                                  _0: match$2,
                                  _1: match$3
                                }) : /* InPerson */({
                                  _0: Curry._3(text, "event_card.location.unavailable", undefined, undefined),
                                  _1: ""
                                });
                          }
                          var tmp$7;
                          tmp$7 = userType === "tutor" ? React.createElement(EventCard$NimbusWeb.Section.make, {
                                  children: React.createElement(Typography$NimbusWeb.make, {
                                        color: /* Primary */0,
                                        className: "",
                                        value: Curry._3(text, "lesson.zoom.warning", undefined, undefined)
                                      })
                                }) : null;
                          return React.createElement(EventCard$NimbusWeb.make, {
                                      children: null
                                    }, React.createElement(EventCard$NimbusWeb.Header.make, {
                                          children: React.createElement(EventCard$NimbusWeb.Content.Header.make, {
                                                src: tmp$5,
                                                title: Helpers$NimbusWeb.getNameFromLesson(userType, lesson),
                                                subtitle: Curry._3(text, "lesson.private.subtitle", undefined, undefined),
                                                url: undefined
                                              })
                                        }), React.createElement(EventCard$NimbusWeb.Body.make, {
                                          children: null
                                        }, React.createElement(EventCard$NimbusWeb.Section.make, {
                                              children: React.createElement(EventCard$NimbusWeb.Content.Schedule.make, {
                                                    date: Belt_Option.getWithDefault($$event.startDateTime, new Date()),
                                                    minutes: $$event.minutes
                                                  }),
                                              divider: true
                                            }), React.createElement(EventCard$NimbusWeb.Section.make, {
                                              children: React.createElement(EventCard$NimbusWeb.Content.$$Location.make, {
                                                    location: tmp$6
                                                  }),
                                              divider: true
                                            }), React.createElement(EventCard$NimbusWeb.Section.make, {
                                              children: React.createElement(EventCard$NimbusWeb.Content.Courses.make, {
                                                    courses: Belt_Array.map($$event.courses, (function (course) {
                                                            return course.subject + (" " + course.number);
                                                          }))
                                                  }),
                                              divider: shouldRenderHelp
                                            }), shouldRenderHelp ? React.createElement(EventCard$NimbusWeb.Section.make, {
                                                children: React.createElement(EventCard$NimbusWeb.Content.HelpDescription.make, {
                                                      helpType: Belt_Option.flatMap(lesson, (function (l) {
                                                              return l.helpType;
                                                            })),
                                                      description: Belt_Option.flatMap(lesson, (function (l) {
                                                              return l.helpDescription;
                                                            }))
                                                    })
                                              }) : null, tmp$7), React.createElement(EventCard$NimbusWeb.Footer.make, {
                                          children: React.createElement(EventCard$NimbusWeb.Content.Footer.make, {
                                                startTime: $$event.startDateTime,
                                                onClick: (function (param) {
                                                    if (userType === undefined) {
                                                      return ;
                                                    }
                                                    switch (userType) {
                                                      case "student" :
                                                          return onClickStart($$event._id, lesson, undefined, undefined);
                                                      case "tutor" :
                                                          return Curry._1(setShowZoomDownloadAlert, (function (param) {
                                                                        return [
                                                                                (function (param) {
                                                                                    return onClickStart($$event._id, lesson, undefined, undefined);
                                                                                  }),
                                                                                true
                                                                              ];
                                                                      }));
                                                      default:
                                                        return ;
                                                    }
                                                  })
                                              })
                                        }));
                      default:
                        return null;
                    }
                  }));
    }
  };
  var renderLoading = function (param) {
    return React.createElement("div", {
                className: "absolute-fill center-container"
              }, React.createElement(Loader$NimbusWeb.Circular.make, {
                    color: /* White */10,
                    backgroundColor: /* BackgroundDark */6
                  }));
  };
  var tmp;
  if (showArriveAtLocationAlert) {
    tmp = React.createElement(ArriveAtLocationAlert$NimbusWeb.make, {
          visible: showArriveAtLocationAlert,
          onClose: (function (param) {
              return Curry._1(setShowArriveAtLocationAlert, (function (param) {
                            return false;
                          }));
            })
        });
  } else if (showZoomUnavailableAlert) {
    tmp = React.createElement(ZoomNotAvailableAlert$NimbusWeb.make, {
          visible: showZoomUnavailableAlert,
          onClose: (function (param) {
              return Curry._1(setShowZoomUnavailableAlert, (function (param) {
                            return false;
                          }));
            })
        });
  } else {
    var callback = showZoomDownloadAlert[0];
    tmp = callback !== undefined ? React.createElement(ZoomDownloadAlert$NimbusWeb.make, {
            visible: true,
            isPrivate: showZoomDownloadAlert[1],
            onClickStart: callback,
            onClose: (function (param) {
                return Curry._1(setShowZoomDownloadAlert, (function (param) {
                              return [
                                      undefined,
                                      true
                                    ];
                            }));
              })
          }) : (
        storageEvent !== undefined && storageDropIn !== undefined && storageDropInStudents !== undefined ? React.createElement(EventViewUI$NimbusWeb.EventPopup.make, {
                event: storageEvent,
                dropIn: storageDropIn,
                dropInStudent: storageDropInStudents,
                onClose: (function (param) {
                    Curry._1(setStorageEvent, (function (param) {
                            
                          }));
                    Curry._1(setStorageDropIn, (function (param) {
                            
                          }));
                    return Curry._1(setStorageDropInStudents, (function (param) {
                                  
                                }));
                  }),
                onClickJoin: (function (param) {
                    var dropInId = storageDropIn._id;
                    var eventJson = EventResponse$NimbusWeb.parseCalendarEvent(storageEvent);
                    var value = Js_json.classify(eventJson);
                    var timeblockId;
                    if (typeof value === "number" || value.TAG !== /* JSONObject */2) {
                      timeblockId = "";
                    } else {
                      var timeblockId$1 = Js_dict.get(value._0, "timeblockId");
                      if (timeblockId$1 !== undefined) {
                        var timeblockId$2 = Js_json.decodeString(Caml_option.valFromOption(timeblockId$1));
                        timeblockId = timeblockId$2 !== undefined ? timeblockId$2 : "";
                      } else {
                        timeblockId = "";
                      }
                    }
                    Curry._1(request, DropInRequest_ShowInterest$NimbusWeb.make(dropInId, timeblockId)).then(function (param) {
                            return fetchEvents(undefined);
                          }).then(function (param) {
                          Curry._1(setStorageEvent, (function (param) {
                                  
                                }));
                          Curry._1(setStorageDropIn, (function (param) {
                                  
                                }));
                          Curry._1(setStorageDropInStudents, (function (param) {
                                  
                                }));
                          return Promise.resolve(undefined);
                        });
                    
                  }),
                buttonText: "Add to Nimbus Calendar"
              }) : null
      );
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Helmet$NimbusWeb.make, {
                  title: "Nimbus Learning - Sessions"
                }), React.createElement("div", {
                  className: "lesson-container"
                }, match[0] ? renderLoading(undefined) : React.createElement(SlideShow$NimbusWeb.make, {
                        children: renderEvents(undefined),
                        pageStart: match$11[0]
                      })), tmp);
}

var make = Lesson;

exports.make = make;
/*  Not a pure module */
