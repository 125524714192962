'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var EventFilterResponse$NimbusWeb = require("../../find/requests/EventFilterResponse.bs.js");

function make(param) {
  var fragment1 = EventFilterResponse$NimbusWeb.Fragment.university;
  var fragment2 = EventFilterResponse$NimbusWeb.Fragment.course;
  var query = "\n    {\n      student {\n        university {\n          ...UniversityFragment\n        }\n        courses {\n          ...CourseFragment\n        }\n      }\n    }\n    " + fragment1 + "\n    " + fragment2 + "\n  ";
  return Request$NimbusWeb.createBody(query, []);
}

function decodeStudent(json) {
  return {
          university: Json_decode.field("university", EventFilterResponse$NimbusWeb.Decode.university, json),
          courses: Json_decode.field("courses", (function (param) {
                  return Json_decode.array(EventFilterResponse$NimbusWeb.Decode.course, param);
                }), json)
        };
}

function decodeData(json) {
  return {
          student: Json_decode.optional((function (param) {
                  return Json_decode.field("student", decodeStudent, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeStudent: decodeStudent,
  decodeData: decodeData,
  exec: exec
};

function normalize(payload) {
  return Belt_Option.flatMap(payload.data, (function (data) {
                return data.student;
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
