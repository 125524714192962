'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function toString(x) {
  if (x) {
    return "fr";
  } else {
    return "en";
  }
}

function textsToDict(locale, texts) {
  return Belt_Array.reduce(texts, {}, (function (dict, item) {
                dict[item.id] = locale ? item.fr : item.en;
                return dict;
              }));
}

function decodePayload(json) {
  return {
          countryCode: Json_decode.optional((function (param) {
                  return Json_decode.field("country_code", Json_decode.string, param);
                }), json)
        };
}

function useUserCountryCode(param) {
  var match = React.useState(function () {
        return "ca";
      });
  var setCountryCode = match[1];
  React.useEffect((function () {
          fetch("https://ipapi.co/json").then(function (response) {
                  return response.json();
                }).then(function (json) {
                var decodedJson = decodePayload(json);
                Curry._1(setCountryCode, (function (param) {
                        return Belt_Option.getWithDefault(decodedJson.countryCode, "ca").toLowerCase();
                      }));
                return Promise.resolve(undefined);
              });
          
        }), []);
  return match[0];
}

exports.toString = toString;
exports.textsToDict = textsToDict;
exports.decodePayload = decodePayload;
exports.useUserCountryCode = useUserCountryCode;
/* react Not a pure module */
