'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Date$NimbusWeb = require("../../utils/Date.bs.js");
var Context$NimbusWeb = require("../../contexts/Context.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var UserContext$NimbusWeb = require("../../contexts/UserContext.bs.js");
var MessageResponse$NimbusWeb = require("./requests/MessageResponse.bs.js");
var MessageRequest_Send$NimbusWeb = require("./requests/MessageRequest_Send.bs.js");
var MessageRequest_Fetch$NimbusWeb = require("./requests/MessageRequest_Fetch.bs.js");
var NotificationUIContext$NimbusWeb = require("../../contexts/NotificationUIContext.bs.js");
var MessageRequest_Subscription$NimbusWeb = require("./requests/MessageRequest_Subscription.bs.js");

function defaultValue_fetchList(param) {
  return Promise.resolve(undefined);
}

var defaultValue_messageSet1 = [];

var defaultValue_messageSet2 = [];

function defaultValue_send(param, param$1, param$2) {
  return Promise.resolve(undefined);
}

function defaultValue_messageFromSubscriptionClear(param) {
  
}

var defaultValue = {
  fetchList: defaultValue_fetchList,
  messageSet1: defaultValue_messageSet1,
  messageSet2: defaultValue_messageSet2,
  send: defaultValue_send,
  messageFromSubscription: undefined,
  messageFromSubscriptionClear: defaultValue_messageFromSubscriptionClear,
  subscription: null
};

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function ChatContext$Wrap(Props) {
  var children = Props.children;
  var groupId = Props.groupId;
  var users = Props.users;
  var visible = Props.visible;
  var request = Request$NimbusWeb.use(undefined);
  var requestFile = Request$NimbusWeb.useFile(undefined);
  var match = React.useContext(UserContext$NimbusWeb.context);
  var profile = match[0];
  var userId = Belt_Option.mapWithDefault(profile, "-1", (function (user) {
          return user._id;
        }));
  var match$1 = React.useState(function () {
        return [];
      });
  var setMessages = match$1[1];
  var match$2 = React.useState(function () {
        return [];
      });
  var setNewMessages = match$2[1];
  var newMessages = match$2[0];
  var notificationUIApi = React.useContext(NotificationUIContext$NimbusWeb.context);
  var fetchList = function (cursor) {
    Curry._1(setMessages, (function (previousMessages) {
            var days = [];
            var previousDays = {
              contents: previousMessages
            };
            Belt_Array.forEachWithIndex(newMessages, (function (iNewMessage, newMessage) {
                    if (iNewMessage === (newMessages.length - 1 | 0)) {
                      var previousDay = Belt_Array.get(previousMessages, 0);
                      if (previousDay !== undefined) {
                        var match = newMessage.date;
                        var match$1 = previousDay.date;
                        if (match !== undefined && match$1 !== undefined && Date$NimbusWeb.isSameDay(Caml_option.valFromOption(match), Caml_option.valFromOption(match$1))) {
                          days.push({
                                date: newMessage.date,
                                blocks: Belt_Array.concat(newMessage.blocks, previousDay.blocks)
                              });
                          previousDays.contents = Belt_Array.sliceToEnd(previousMessages, 1);
                        } else {
                          days.push(newMessage);
                        }
                        return ;
                      }
                      days.push(newMessage);
                      return ;
                    }
                    days.push(newMessage);
                    
                  }));
            Belt_Array.forEach(previousDays.contents, (function (message) {
                    days.push(message);
                    
                  }));
            return days;
          }));
    return Curry._1(request, MessageRequest_Fetch$NimbusWeb.make(groupId, cursor)).then(function (json) {
                var blocks = MessageRequest_Fetch$NimbusWeb.$$Response.normalize(Curry._1(MessageRequest_Fetch$NimbusWeb.$$Response.Decode.exec, json));
                Curry._1(setNewMessages, (function (param) {
                        return blocks;
                      }));
                return Promise.resolve(undefined);
              });
  };
  var match$3 = React.useState(function () {
        
      });
  var setMessageFromSubscription = match$3[1];
  var match$4 = Belt_Option.mapWithDefault(profile, [
        "",
        {}
      ], (function (profile) {
          return MessageRequest_Subscription$NimbusWeb.make(groupId, profile._id);
        }));
  var callback = function (message) {
    var match = Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(users.find(function (user) {
                  return user._id === message.from._id;
                })), [
          "",
          "",
          undefined
        ], (function (user) {
            return [
                    user.first,
                    user.last,
                    user.avatar
                  ];
          }));
    var init = message.from;
    var withUser__id = message._id;
    var withUser_body = message.body;
    var withUser_attachment = message.attachment;
    var withUser_from = {
      _id: init._id,
      first: match[0],
      last: match[1],
      avatar: match[2]
    };
    var withUser_createdAt = message.createdAt;
    var withUser_type_ = message.type_;
    var withUser = {
      _id: withUser__id,
      body: withUser_body,
      attachment: withUser_attachment,
      from: withUser_from,
      createdAt: withUser_createdAt,
      type_: withUser_type_
    };
    Curry._1(setMessages, (function (prev) {
            return MessageResponse$NimbusWeb.pushMessageToArray(prev, withUser);
          }));
    Curry._1(setMessageFromSubscription, (function (param) {
            return message;
          }));
    var messageType = Belt_Option.getWithDefault(message.type_, "MESSAGE");
    if (!visible && message.from._id !== userId) {
      Curry._1(notificationUIApi.setShowBadge, (function (param) {
              return true;
            }));
    } else if (messageType !== "MESSAGE") {
      
    } else {
      Curry._1(notificationUIApi.setShowBadge, (function (param) {
              return false;
            }));
    }
    if (message.from._id === userId) {
      return ;
    }
    switch (messageType) {
      case "WHITEBOARD_START" :
          return Curry._1(notificationUIApi.openBanner, /* Start */0);
      case "WHITEBOARD_STOP" :
          return Curry._1(notificationUIApi.openBanner, /* End */1);
      default:
        return ;
    }
  };
  var subscription = React.createElement(MessageRequest_Subscription$NimbusWeb.Component.make, {
        query: match$4[0],
        variables: match$4[1],
        callback: callback
      });
  var send = React.useCallback((function (text) {
          return function (file, onSendFile) {
            var sendText = Belt_Option.isNone(file) ? text : "";
            var promise = Belt_Option.mapWithDefault(file, Promise.resolve(undefined), (function (file) {
                    var fileResponse = Curry._2(requestFile, file, groupId);
                    Curry._1(onSendFile, undefined);
                    return fileResponse;
                  }));
            return promise.then(function (response) {
                            return Belt_Option.mapWithDefault(response, Promise.resolve(undefined), (function (response) {
                                          var data = MessageResponse$NimbusWeb.Decode.decodeSendFileResponse(response);
                                          return Promise.resolve(data.uri);
                                        }));
                          }).then(function (uriOption) {
                          return Curry._1(request, MessageRequest_Send$NimbusWeb.make(groupId, sendText, file, uriOption, undefined));
                        }).then(function (param) {
                        return Promise.resolve(undefined);
                      });
          };
        }), [
        groupId,
        request,
        requestFile
      ]);
  var api_messageSet1 = match$1[0];
  var api_messageFromSubscription = match$3[0];
  var api_messageFromSubscriptionClear = function (param) {
    return Curry._1(setMessageFromSubscription, (function (param) {
                  
                }));
  };
  var api = {
    fetchList: fetchList,
    messageSet1: api_messageSet1,
    messageSet2: newMessages,
    send: send,
    messageFromSubscription: api_messageFromSubscription,
    messageFromSubscriptionClear: api_messageFromSubscriptionClear,
    subscription: subscription
  };
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, api, children, undefined));
}

var Wrap = {
  make: ChatContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
