'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Context$NimbusWeb = require("./Context.bs.js");
var BrandContext$NimbusWeb = require("./BrandContext.bs.js");
var ThemeContext$NimbusWeb = require("./ThemeContext.bs.js");

function defaultValue_1(param) {
  
}

var defaultValue = [
  undefined,
  defaultValue_1
];

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function OrganizationContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        
      });
  var organization = match[0];
  var match$1 = React.useContext(ThemeContext$NimbusWeb.context);
  var setPrimary = match$1[2];
  var match$2 = React.useContext(BrandContext$NimbusWeb.context);
  var setBrandName = match$2[5];
  var setBrandIcon = match$2[4];
  var setBrandIsDefault = match$2[3];
  React.useEffect((function () {
          if (organization !== undefined) {
            var colors = organization.colors;
            if (colors !== undefined) {
              Curry._1(setPrimary, (function (param) {
                      return colors.student.medium;
                    }));
            }
            Curry._1(setBrandIcon, (function (param) {
                    return organization.logo;
                  }));
            Curry._1(setBrandName, (function (param) {
                    return organization.name;
                  }));
            Curry._1(setBrandIsDefault, (function (param) {
                    return false;
                  }));
          }
          
        }), [organization]);
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, [
                  organization,
                  match[1]
                ], children, undefined));
}

var Wrap = {
  make: OrganizationContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
