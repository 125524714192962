'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function dUser(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          first: Json_decode.field("first", Json_decode.string, json),
          last: Json_decode.field("last", Json_decode.string, json),
          avatar: Json_decode.optional((function (param) {
                  return Json_decode.field("avatar", Json_decode.string, param);
                }), json),
          uid: Json_decode.field("uid", Json_decode.$$int, json),
          uidSS: Json_decode.field("uidSS", Json_decode.$$int, json)
        };
}

function dLocation(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          address: Json_decode.field("address", Json_decode.string, json)
        };
}

function dT(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          title: Json_decode.field("title", Json_decode.string, json),
          description: Json_decode.optional((function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          host: Json_decode.field("host", dUser, json),
          attendeesMax: Json_decode.optional((function (param) {
                  return Json_decode.field("attendeesMax", Json_decode.$$int, param);
                }), json),
          accessLevel: Json_decode.optional((function (param) {
                  return Json_decode.field("access_level", Json_decode.string, param);
                }), json),
          online: Json_decode.field("online", Json_decode.bool, json),
          location: Json_decode.optional((function (param) {
                  return Json_decode.field("location", dLocation, param);
                }), json)
        };
}

var Decode = {
  dUser: dUser,
  dLocation: dLocation,
  dT: dT
};

function normalize(dropIn) {
  return dropIn;
}

exports.Decode = Decode;
exports.normalize = normalize;
/* No side effect */
