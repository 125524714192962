'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

require("./TextField.css");

function TextField(Props) {
  var disabledOpt = Props.disabled;
  var classNameOpt = Props.className;
  var type_ = Props.type_;
  var placeholder = Props.placeholder;
  var value = Props.value;
  var onChange = Props.onChange;
  var requiredOpt = Props.required;
  var onKeyPressOpt = Props.onKeyPress;
  var children = Props.children;
  var label = Props.label;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var onKeyPress = onKeyPressOpt !== undefined ? onKeyPressOpt : (function (param) {
        
      });
  return React.createElement("div", {
              className: "textfield-container " + className
            }, label !== undefined ? React.createElement("label", {
                    className: "textfield-label",
                    htmlFor: placeholder
                  }, React.createElement("span", {
                        className: "material-symbols-outlined"
                      }, label)) : null, React.createElement("input", {
                  className: "textfield-input",
                  id: placeholder,
                  disabled: disabled,
                  placeholder: placeholder,
                  required: required,
                  type: type_,
                  value: value,
                  onKeyPress: onKeyPress,
                  onChange: onChange
                }), children !== undefined ? Caml_option.valFromOption(children) : null);
}

var make = TextField;

exports.make = make;
/*  Not a pure module */
