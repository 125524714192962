'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var A11y$NimbusWeb = require("../../utils/A11y.bs.js");
var Helmet$NimbusWeb = require("../../components/Helmet.bs.js");
var Helpers$NimbusWeb = require("../../utils/Helpers.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var EventFilterUI$NimbusWeb = require("../find/EventFilterUI.bs.js");
var LocaleContext$NimbusWeb = require("../../contexts/LocaleContext.bs.js");
var SSOUniversitiesContext$NimbusWeb = require("../../contexts/SSOUniversitiesContext.bs.js");
var LoginRequest_SSOUniversities$NimbusWeb = require("./requests/LoginRequest_SSOUniversities.bs.js");

require("./Login.css");

function SSOSelect(Props) {
  var match = React.useContext(SSOUniversitiesContext$NimbusWeb.context);
  var setUniversities = match[1];
  var universities = match[0];
  var request = Request$NimbusWeb.use(undefined);
  var match$1 = React.useContext(LocaleContext$NimbusWeb.context);
  var dummyRef = React.useRef(null);
  React.useEffect((function () {
          Curry._1(request, LoginRequest_SSOUniversities$NimbusWeb.make(undefined)).then(function (json) {
                  return Promise.resolve(LoginRequest_SSOUniversities$NimbusWeb.$$Response.normalize(Curry._1(LoginRequest_SSOUniversities$NimbusWeb.$$Response.Decode.exec, json)));
                }).then(function (result) {
                Curry._1(setUniversities, (function (param) {
                        return result;
                      }));
                return Promise.resolve(undefined);
              });
          
        }), []);
  return React.createElement(EventFilterUI$NimbusWeb.Container.make, {
              children: null,
              ref_: dummyRef
            }, React.createElement(Helmet$NimbusWeb.make, {
                  title: "Nimbus Learning - SSO"
                }), React.createElement("div", {
                  className: "event-filter-list-container"
                }, universities !== undefined ? universities.map(function (university, i) {
                        var endpoint = university.ssoEndpoint;
                        if (endpoint !== undefined && endpoint !== "") {
                          return React.createElement(EventFilterUI$NimbusWeb.University.Item.make, {
                                      name: university.name,
                                      photoHome: university.photoHome,
                                      photo: university.photo,
                                      onClick: (function (param) {
                                          return Helpers$NimbusWeb.openSSOLink(endpoint);
                                        }),
                                      accessKey: A11y$NimbusWeb.getAccessKey(i + 8 | 0),
                                      key: university._id
                                    });
                        } else {
                          return null;
                        }
                      }) : React.createElement(Typography$NimbusWeb.make, {
                        color: /* Gray1 */8,
                        value: Curry._3(match$1[0], "login.button.sso.empty", undefined, undefined)
                      })));
}

var make = SSOSelect;

exports.make = make;
/*  Not a pure module */
