'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var DateFns = require("date-fns");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Locale = require("date-fns/locale");
var ReactBigCalendar = require("react-big-calendar");

var Types = {};

var DateFns$1 = {
  Types: Types
};

var Types$1 = {};

function get(param) {
  return ReactBigCalendar.dateFnsLocalizer({
              format: DateFns.format,
              parse: DateFns.parse,
              startOfWeek: DateFns.startOfWeek,
              getDay: DateFns.getDay,
              locales: {
                "en-CA": Locale.enCA
              }
            });
}

var Localizer = {
  Types: Types$1,
  get: get
};

require("react-big-calendar/lib/css/react-big-calendar.css");

var Types$2 = {};

function stringifyEvent($$event) {
  var dict = {};
  dict["_id"] = $$event._id;
  dict["_id2"] = $$event._id2;
  dict["title"] = $$event.title;
  dict["start"] = $$event.start.valueOf();
  dict["end"] = $$event.end.valueOf();
  dict["resource"] = $$event.resource;
  return JSON.stringify(dict);
}

function parseEvent(jsonString) {
  var json;
  try {
    json = JSON.parse(jsonString);
  }
  catch (exn){
    json = Curry._1(Pervasives.failwith("Failed to parse json"), jsonString);
  }
  var v = Js_json.classify(json);
  if (typeof v === "number") {
    return ;
  }
  if (v.TAG !== /* JSONObject */2) {
    return ;
  }
  var v$1 = v._0;
  var match = Js_dict.get(v$1, "_id");
  var match$1 = Js_dict.get(v$1, "_id2");
  var match$2 = Js_dict.get(v$1, "title");
  var match$3 = Js_dict.get(v$1, "start");
  var match$4 = Js_dict.get(v$1, "end");
  var match$5 = Js_dict.get(v$1, "resource");
  if (match === undefined) {
    return ;
  }
  if (match$1 === undefined) {
    return ;
  }
  if (match$2 === undefined) {
    return ;
  }
  if (match$3 === undefined) {
    return ;
  }
  if (match$4 === undefined) {
    return ;
  }
  if (match$5 === undefined) {
    return ;
  }
  var match$6 = Js_json.decodeString(Caml_option.valFromOption(match));
  var match$7 = Js_json.decodeString(Caml_option.valFromOption(match$1));
  var match$8 = Js_json.decodeString(Caml_option.valFromOption(match$2));
  var match$9 = Js_json.decodeNumber(Caml_option.valFromOption(match$3));
  var match$10 = Js_json.decodeNumber(Caml_option.valFromOption(match$4));
  var match$11 = Js_json.decodeString(Caml_option.valFromOption(match$5));
  if (match$6 !== undefined && match$7 !== undefined && match$8 !== undefined && match$9 !== undefined && match$10 !== undefined && match$11 !== undefined) {
    return {
            _id: match$6,
            _id2: match$7,
            title: match$8,
            start: new Date(match$9),
            end: new Date(match$10),
            resource: match$11
          };
  }
  
}

var Json = {
  stringifyEvent: stringifyEvent,
  parseEvent: parseEvent
};

var Calendar = {
  Types: Types$2,
  Json: Json
};

exports.DateFns = DateFns$1;
exports.Localizer = Localizer;
exports.Calendar = Calendar;
/*  Not a pure module */
