'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_Slider(Props) {
  var colorOpt = Props.color;
  var widthOpt = Props.width;
  var heightOpt = Props.height;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var width = widthOpt !== undefined ? widthOpt : "14";
  var height = heightOpt !== undefined ? heightOpt : "6";
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: height,
              width: width,
              fill: "none",
              viewBox: "0 0 " + (width + (" " + height)),
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M0 3C0 3.55228 0.447715 4 1 4H6.184C6.597 5.163 7.696 6 9 6C10.304 6 11.403 5.163 11.816 4H13C13.5523 4 14 3.55228 14 3C14 2.44772 13.5523 2 13 2H11.816C11.403 0.837 10.304 0 9 0C7.696 0 6.597 0.837 6.184 2H0.999999C0.447714 2 0 2.44772 0 3ZM7.5 3C7.5 2.173 8.173 1.5 9 1.5C9.827 1.5 10.5 2.173 10.5 3C10.5 3.827 9.827 4.5 9 4.5C8.173 4.5 7.5 3.827 7.5 3Z",
                  fill: c
                }));
}

var make = Icon_Slider;

exports.make = make;
/* react Not a pure module */
