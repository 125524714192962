'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var OrganizationResponse$NimbusWeb = require("./OrganizationResponse.bs.js");

function make(universityId) {
  var query = "\n    query(\$id: ID!) {\n      organizations: organizationsByUniversity(_university: \$id) {\n        ...OrganizationThemeFragment\n      }\n    }\n    " + OrganizationResponse$NimbusWeb.fragment + "\n  ";
  var variables = [{
      key: "id",
      val: universityId
    }];
  return Request$NimbusWeb.createBody(query, variables);
}

function decodeData(json) {
  return {
          organizations: Json_decode.optional((function (param) {
                  return Json_decode.field("organizations", (function (param) {
                                return Json_decode.array(OrganizationResponse$NimbusWeb.Decode.dT, param);
                              }), param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeData: decodeData,
  exec: exec
};

function normalize(payload) {
  return Belt_Option.flatMap(payload.data, (function (data) {
                return data.organizations;
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
