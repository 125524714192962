'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var GroupsResponse$NimbusWeb = require("./GroupsResponse.bs.js");

function makeStudent(param) {
  var query = "\n    query {\n      groups: studentGroups {\n        ...StudentGroupFragment\n      }\n    }\n    " + GroupsResponse$NimbusWeb.studentGroupFragment + "\n  ";
  return Request$NimbusWeb.createBody(query, []);
}

function makeTutor(param) {
  var query = "\n    query {\n      groups: tutorGroups {\n        ...TutorGroupFragment\n      }\n    }\n    " + GroupsResponse$NimbusWeb.tutorGroupFragment + "\n  ";
  return Request$NimbusWeb.createBody(query, []);
}

function make(userType) {
  if (userType === undefined) {
    return ;
  }
  switch (userType) {
    case "student" :
        return Caml_option.some(makeStudent(undefined));
    case "tutor" :
        return Caml_option.some(makeTutor(undefined));
    default:
      return ;
  }
}

function decodeData(json) {
  return {
          groups: Json_decode.field("groups", (function (param) {
                  return Json_decode.array(GroupsResponse$NimbusWeb.Decode.decodeGroup, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeData: decodeData,
  exec: exec
};

function normalize(payload) {
  return Belt_Option.mapWithDefault(payload.data, [], (function (d) {
                return d.groups.map(GroupsResponse$NimbusWeb.normalize);
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.makeStudent = makeStudent;
exports.makeTutor = makeTutor;
exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
