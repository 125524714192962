'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Context$NimbusWeb = require("./Context.bs.js");
var Request$NimbusWeb = require("../utils/Request.bs.js");
var AuthContext$NimbusWeb = require("./AuthContext.bs.js");
var LoginRequest_FetchStudent$NimbusWeb = require("../scenes/login/requests/LoginRequest_FetchStudent.bs.js");

function defaultValue_1(param) {
  
}

var defaultValue = [
  undefined,
  defaultValue_1
];

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function StudentContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        
      });
  var setProfile = match[1];
  var match$1 = React.useContext(AuthContext$NimbusWeb.Token.context);
  var token = match$1[0];
  var match$2 = React.useContext(AuthContext$NimbusWeb.UserType.context);
  var userType = match$2[0];
  var request = Request$NimbusWeb.use(undefined);
  React.useEffect((function () {
          var exit = 0;
          if (userType !== undefined && userType === "student" && token !== undefined && token !== "" && token !== "undefined") {
            Curry._1(request, LoginRequest_FetchStudent$NimbusWeb.make(undefined)).then(function (json) {
                  var data = LoginRequest_FetchStudent$NimbusWeb.$$Response.normalize(Curry._1(LoginRequest_FetchStudent$NimbusWeb.$$Response.Decode.exec, json));
                  Curry._1(setProfile, (function (param) {
                          return data;
                        }));
                  return Promise.resolve(undefined);
                });
          } else {
            exit = 1;
          }
          if (exit === 1) {
            Curry._1(setProfile, (function (param) {
                    
                  }));
          }
          
        }), [
        token,
        userType
      ]);
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, [
                  match[0],
                  setProfile
                ], children, undefined));
}

var Wrap = {
  make: StudentContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
