'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Context$NimbusWeb = require("./Context.bs.js");

function defaultValue_setShowBadge(param) {
  
}

function defaultValue_closeBanner(param) {
  
}

function defaultValue_openBanner(param) {
  
}

var defaultValue = {
  showBadge: false,
  showBanner: undefined,
  setShowBadge: defaultValue_setShowBadge,
  closeBanner: defaultValue_closeBanner,
  openBanner: defaultValue_openBanner
};

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function NotificationUIContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return false;
      });
  var match$1 = React.useState(function () {
        
      });
  var setShowBanner = match$1[1];
  var closeBanner = React.useCallback((function (param) {
          return Curry._1(setShowBanner, (function (param) {
                        
                      }));
        }), []);
  var openBanner = React.useCallback((function (bannerType) {
          return Curry._1(setShowBanner, (function (param) {
                        return bannerType;
                      }));
        }), []);
  var api_showBadge = match[0];
  var api_showBanner = match$1[0];
  var api_setShowBadge = match[1];
  var api = {
    showBadge: api_showBadge,
    showBanner: api_showBanner,
    setShowBadge: api_setShowBadge,
    closeBanner: closeBanner,
    openBanner: openBanner
  };
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, api, children, undefined));
}

var Wrap = {
  make: NotificationUIContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
