'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Utils$NimbusWeb = require("../../utils/Utils.bs.js");
var ChatUI$NimbusWeb = require("./ChatUI.bs.js");
var ChatContext$NimbusWeb = require("./ChatContext.bs.js");
var UserContext$NimbusWeb = require("../../contexts/UserContext.bs.js");
var LocaleContext$NimbusWeb = require("../../contexts/LocaleContext.bs.js");
var FileTooLargeAlert$NimbusWeb = require("./FileTooLargeAlert.bs.js");

function Chat(Props) {
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  var match$1 = React.useContext(UserContext$NimbusWeb.context);
  var profile = match$1[0];
  var match$2 = React.useContext(LocaleContext$NimbusWeb.context);
  var locale = match$2[1];
  var bottomRef = React.useRef(undefined);
  var cursorRef = React.useRef(undefined);
  var nonCursorRef = React.useRef(undefined);
  var match$3 = React.useState(function () {
        return true;
      });
  var setLoading = match$3[1];
  var loading = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setSendLoading = match$4[1];
  var chatApi = React.useContext(ChatContext$NimbusWeb.context);
  var scrollTo = function (smoothOpt, reactRef) {
    var smooth = smoothOpt !== undefined ? smoothOpt : true;
    var rRef = reactRef.current;
    if (rRef === undefined) {
      return ;
    }
    var rRef$1 = Caml_option.valFromOption(rRef);
    if (smooth) {
      rRef$1.scrollIntoView({"behavior": "smooth"});
    } else {
      rRef$1.scrollIntoView();
    }
    
  };
  React.useEffect((function () {
          Curry._1(setLoading, (function (param) {
                  return true;
                }));
          Curry._1(chatApi.fetchList, "").then(function (param) {
                  scrollTo(false, bottomRef);
                  Curry._1(setLoading, (function (param) {
                          return false;
                        }));
                  return Promise.resolve(undefined);
                }).catch(function (param) {
                Curry._1(setLoading, (function (param) {
                        return false;
                      }));
                return Promise.resolve(undefined);
              });
          
        }), []);
  var match$5 = React.useState(function () {
        return "";
      });
  var setMValue = match$5[1];
  var mValue = match$5[0];
  var onChangeMessage = function (e) {
    e.persist();
    return Curry._1(setMValue, (function (param) {
                  return e.target.value;
                }));
  };
  var match$6 = React.useState(function () {
        return false;
      });
  var setFileTooLargeAlert = match$6[1];
  var match$7 = React.useState(function () {
        
      });
  var setSelectedFile = match$7[1];
  var selectedFile = match$7[0];
  var fileRef = React.useRef(undefined);
  var onClickAttachment = function (param) {
    var fRef = fileRef.current;
    if (fRef !== undefined) {
      return Caml_option.valFromOption(fRef).click();
    }
    
  };
  var onSelectFile = function (e) {
    var target = e.target;
    if (!target.validity.valid) {
      return ;
    }
    var l = target.files;
    var file = Belt_List.head(l);
    if (file !== undefined) {
      if ((file.size / 1000000 | 0) > 20) {
        return Curry._1(setFileTooLargeAlert, (function (param) {
                      return true;
                    }));
      } else {
        return Curry._1(setSelectedFile, (function (param) {
                      return file;
                    }));
      }
    }
    
  };
  React.useEffect((function () {
          var message = chatApi.messageFromSubscription;
          if (message !== undefined) {
            var match = chatApi.messageSet1;
            var arrayToCompare = match.length !== 0 ? chatApi.messageSet1 : chatApi.messageSet2;
            var arrayToCompareLast = Utils$NimbusWeb.getLast(arrayToCompare);
            if (arrayToCompareLast !== undefined) {
              var lastBlock = Utils$NimbusWeb.getLast(arrayToCompareLast.blocks);
              if (lastBlock !== undefined) {
                var lastMessage = Utils$NimbusWeb.getLast(lastBlock.messages);
                if (lastMessage !== undefined && lastMessage._id === message._id) {
                  setTimeout((function (param) {
                          return scrollTo(true, bottomRef);
                        }), 0);
                  Curry._1(chatApi.messageFromSubscriptionClear, undefined);
                }
                
              }
              
            }
            
          }
          
        }), [
        chatApi.messageFromSubscription,
        chatApi.messageSet2,
        chatApi.messageSet1
      ]);
  var handleScroll = function (e) {
    var target = e.target;
    if (!(!loading && target.scrollTop < 50)) {
      return ;
    }
    var day = Belt_Array.get(chatApi.messageSet2, 0);
    var cursor;
    if (day !== undefined) {
      var block = Belt_Array.get(day.blocks, 0);
      if (block !== undefined) {
        var message = Belt_Array.get(block.messages, 0);
        cursor = message !== undefined ? message._id : undefined;
      } else {
        cursor = undefined;
      }
    } else {
      cursor = undefined;
    }
    if (cursor !== undefined) {
      Curry._1(setLoading, (function (param) {
              return true;
            }));
      Curry._1(chatApi.fetchList, cursor).then(function (param) {
              Curry._1(setLoading, (function (param) {
                      return false;
                    }));
              return Promise.resolve(undefined);
            }).catch(function (param) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            return Promise.resolve(undefined);
          });
      return ;
    }
    
  };
  React.useEffect((function () {
          scrollTo(false, cursorRef);
          
        }), [cursorRef.current]);
  var onSubmitMessage = function (param) {
    Curry._1(setSendLoading, (function (param) {
            return true;
          }));
    Curry._3(chatApi.send, mValue, selectedFile, (function (param) {
                return Curry._1(setSelectedFile, (function (param) {
                              
                            }));
              })).then(function (param) {
            Curry._1(setSendLoading, (function (param) {
                    return false;
                  }));
            if (Belt_Option.isNone(selectedFile)) {
              Curry._1(setMValue, (function (param) {
                      return "";
                    }));
            }
            return Promise.resolve(undefined);
          }).catch(function (param) {
          Curry._1(setSendLoading, (function (param) {
                  return false;
                }));
          return Promise.resolve(undefined);
        });
    
  };
  var onKeyPress = function ($$event) {
    if (mValue.length > 0 && $$event.key === "Enter") {
      return onSubmitMessage(undefined);
    }
    
  };
  var renderAttachment = React.useCallback((function (attachmentOption) {
          return function (id) {
            return Belt_Option.mapWithDefault(attachmentOption, null, (function (attachment) {
                          return React.createElement(ChatUI$NimbusWeb.Message.Bubble.Attachment.ContentContainer.make, {
                                      onClick: (function (param) {
                                          var filenameOption = attachment.filename;
                                          var uriOption = attachment.payload;
                                          if (uriOption === undefined) {
                                            return ;
                                          }
                                          var filename = Belt_Option.getWithDefault(filenameOption, "Untitled");
                                          var a = document.getElementById(id);
                                          a.setAttribute("href", uriOption);
                                          a.setAttribute("download", filename);
                                          a.click();
                                          a.setAttribute("href", "");
                                          
                                        }),
                                      id: id,
                                      children: React.createElement(ChatUI$NimbusWeb.Message.Bubble.Attachment.Content.make, {
                                            attachment: attachment
                                          })
                                    });
                        }));
          };
        }), []);
  var renderMessages = function (msgs, isNew) {
    return Belt_Array.mapWithIndex(msgs, (function (dayi, day) {
                  return React.createElement(ChatUI$NimbusWeb.Message.Day.make, {
                              children: Belt_Array.mapWithIndex(day.blocks, (function (blocki, block) {
                                      var match = ChatUI$NimbusWeb.Message.getClassNames(block, profile);
                                      var displaySide = match[0];
                                      var messageCount = block.messages.length - 1 | 0;
                                      return React.createElement(ChatUI$NimbusWeb.Message.Block.make, {
                                                  children: Belt_Array.mapWithIndex(block.messages, (function (i, message) {
                                                          var match = message.type_;
                                                          var exit = 0;
                                                          if (match !== undefined) {
                                                            switch (match) {
                                                              case "WHITEBOARD_START" :
                                                                  return React.createElement(ChatUI$NimbusWeb.Message.ActionText.make, {
                                                                              text: Belt_Option.getWithDefault(block.user.name, "Unknown User") + " has started the whiteboard",
                                                                              key: message._id
                                                                            });
                                                              case "WHITEBOARD_STOP" :
                                                                  return React.createElement(ChatUI$NimbusWeb.Message.ActionText.make, {
                                                                              text: Belt_Option.getWithDefault(block.user.name, "Unknown User") + " has ended the whiteboard",
                                                                              key: message._id
                                                                            });
                                                              default:
                                                                exit = 1;
                                                            }
                                                          } else {
                                                            exit = 1;
                                                          }
                                                          if (exit === 1) {
                                                            var container = function (content, a) {
                                                              return React.createElement(ChatUI$NimbusWeb.Message.Bubble.make, {
                                                                          ref_: !isNew && dayi === 0 && blocki === 0 && i === 0 ? Utils$NimbusWeb.setRef(cursorRef) : Utils$NimbusWeb.setRef(nonCursorRef),
                                                                          isAttachment: a,
                                                                          isFirst: i === 0,
                                                                          isLast: i === messageCount,
                                                                          displaySide: displaySide,
                                                                          children: content,
                                                                          key: message._id
                                                                        });
                                                            };
                                                            var body = message.body;
                                                            var exit$1 = 0;
                                                            if (body !== undefined) {
                                                              if (body !== "") {
                                                                return container(React.createElement(ChatUI$NimbusWeb.Message.Bubble.$$Text.make, {
                                                                                value: body
                                                                              }), false);
                                                              }
                                                              exit$1 = 2;
                                                            } else {
                                                              exit$1 = 2;
                                                            }
                                                            if (exit$1 === 2) {
                                                              if (Belt_Option.isNone(message.attachment)) {
                                                                return null;
                                                              } else {
                                                                return container(Curry._2(renderAttachment, message.attachment, message._id), true);
                                                              }
                                                            }
                                                            
                                                          }
                                                          
                                                        })),
                                                  block: block,
                                                  displaySide: displaySide,
                                                  displayAvatar: match[1],
                                                  type_: block.type_,
                                                  key: String(blocki)
                                                });
                                    })),
                              date: day.date,
                              locale: locale,
                              key: String(dayi)
                            });
                }));
  };
  return React.createElement(ChatUI$NimbusWeb.Container.make, {
              children: null
            }, React.createElement(ChatUI$NimbusWeb.Message.Container.make, {
                  onScroll: handleScroll,
                  children: null
                }, renderMessages(chatApi.messageSet2, true), renderMessages(chatApi.messageSet1, false), React.createElement("div", {
                      ref: Utils$NimbusWeb.setRef(bottomRef)
                    })), React.createElement(ChatUI$NimbusWeb.Footer.make, {
                  children: null
                }, selectedFile !== undefined ? React.createElement(ChatUI$NimbusWeb.Footer.Input.FileDisplay.make, {
                        file: selectedFile,
                        onClick: (function (param) {
                            return Curry._1(setSelectedFile, (function (param) {
                                          
                                        }));
                          })
                      }) : React.createElement(ChatUI$NimbusWeb.Footer.Input.make, {
                        ref_: Utils$NimbusWeb.setRef(fileRef),
                        placeholder: Curry._3(match[0], "message.placeholder", undefined, undefined),
                        onChangeText: onChangeMessage,
                        value: mValue,
                        onClickAttachment: onClickAttachment,
                        onSelectFile: onSelectFile,
                        onKeyPress: onKeyPress
                      }), React.createElement(ChatUI$NimbusWeb.Footer.SendButton.make, {
                      disabled: Belt_Option.mapWithDefault(selectedFile, mValue === "", (function (param) {
                              return false;
                            })),
                      onClick: onSubmitMessage,
                      loading: match$4[0]
                    })), React.createElement(FileTooLargeAlert$NimbusWeb.make, {
                  visible: match$6[0],
                  onClose: (function (param) {
                      return Curry._1(setFileTooLargeAlert, (function (param) {
                                    return false;
                                  }));
                    })
                }), chatApi.subscription);
}

var make = Chat;

exports.make = make;
/* react Not a pure module */
