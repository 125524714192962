'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var DropInResponse$NimbusWeb = require("./DropInResponse.bs.js");

function make(ids) {
  var variables = [{
      key: "dropInIds",
      val: ids
    }];
  return Request$NimbusWeb.createBody("\n    query (\$dropInIds: [ID]!) {\n      fetchDropInsByIds(_drop_ins: \$dropInIds) {\n        _id\n        title\n        description\n        host { _id first last avatar uid uidSS }\n        attendeesMax\n        online\n        location { name address }\n      }\n    }\n  ", variables);
}

function dData(json) {
  return {
          dropIns: Json_decode.optional((function (param) {
                  return Json_decode.field("fetchDropInsByIds", (function (param) {
                                return Json_decode.array(DropInResponse$NimbusWeb.Decode.dT, param);
                              }), param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", dData, param);
                }), json)
        };
}

var Decode = {
  dData: dData,
  exec: exec
};

function normalize(payload) {
  var data = payload.data;
  if (data === undefined) {
    return [];
  }
  var dropIns = data.dropIns;
  if (dropIns !== undefined) {
    return Belt_Array.map(dropIns, DropInResponse$NimbusWeb.normalize);
  } else {
    return [];
  }
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
