'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Error$NimbusWeb = require("./Error.bs.js");
var FormData$NimbusWeb = require("./FormData.bs.js");
var AuthContext$NimbusWeb = require("../contexts/AuthContext.bs.js");
var ErrorContext$NimbusWeb = require("../contexts/ErrorContext.bs.js");

var tp = process.env.REACT_APP_CONTEXT === "development" ? "http://" : "https://";

function send(body, token, setErrorMessage, clearToken) {
  return fetch(tp + (process.env.REACT_APP_DOMAIN + "/nimbus"), Fetch.RequestInit.make(/* Post */2, {
                          "Content-Type": "application/json",
                          Authorization: token !== undefined && token !== "" ? "Bearer " + token : ""
                        }, Caml_option.some(JSON.stringify(body)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
                  return prim.json();
                }).then(function (json) {
                var error = Error$NimbusWeb.Decode.exec(json);
                var match = error.errors;
                var exit = 0;
                if (match !== undefined && match.length === 1) {
                  var err = match[0];
                  Curry._1(setErrorMessage, (function (param) {
                          return Error$NimbusWeb.$$process(err, clearToken);
                        }));
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  Curry._1(setErrorMessage, (function (param) {
                          
                        }));
                }
                return Promise.resolve(json);
              }).catch(function (param) {
              Curry._1(setErrorMessage, (function (param) {
                      return "Network Error";
                    }));
              return Promise.resolve(JSON.parse(" \"lol\" "));
            });
}

function use(param) {
  var match = React.useContext(AuthContext$NimbusWeb.Token.context);
  var setToken = match[1];
  var token = match[0];
  var setErrorMessage = React.useContext(ErrorContext$NimbusWeb.context);
  return function (body) {
    return send(body, token, setErrorMessage, setToken);
  };
}

function sendFile(file, groupId, token, setErrorMessage) {
  var formData = FormData$NimbusWeb.make(file);
  return fetch(tp + (process.env.REACT_APP_DOMAIN + ("/images/upload_file?_group=" + groupId)), Fetch.RequestInit.make(/* Post */2, {
                          Authorization: token !== undefined && token !== "" ? "Bearer " + token : ""
                        }, Caml_option.some(formData), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (lol) {
                  console.log(lol);
                  return lol.json();
                }).then(function (json) {
                return Promise.resolve(Caml_option.some(json));
              }).catch(function (param) {
              Curry._1(setErrorMessage, (function (param) {
                      return "Error uploading file";
                    }));
              return Promise.resolve(Caml_option.some(JSON.parse(" \"lol\" ")));
            });
}

function useFile(param) {
  var match = React.useContext(AuthContext$NimbusWeb.Token.context);
  var token = match[0];
  var setErrorMessage = React.useContext(ErrorContext$NimbusWeb.context);
  return function (file, groupId) {
    return sendFile(file, groupId, token, setErrorMessage);
  };
}

function sendSimple(url) {
  return fetch(url, Fetch.RequestInit.make(/* Post */2, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
              return prim.json();
            });
}

function createBody(query, variables) {
  var body = {};
  body["query"] = query;
  if (variables.length > 0) {
    var vars = {};
    Belt_Array.forEach(variables, (function ($$var) {
            vars[$$var.key] = $$var.val;
            
          }));
    body["variables"] = vars;
  }
  return body;
}

function Make(Config) {
  var data = function (json) {
    return {
            data: Json_decode.optional((function (param) {
                    return Json_decode.field("data", Config.decodeData, param);
                  }), json)
          };
  };
  return {
          data: data
        };
}

exports.tp = tp;
exports.send = send;
exports.use = use;
exports.sendFile = sendFile;
exports.useFile = useFile;
exports.sendSimple = sendSimple;
exports.createBody = createBody;
exports.Make = Make;
/* tp Not a pure module */
