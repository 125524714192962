'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var EventFilterResponse$NimbusWeb = require("./EventFilterResponse.bs.js");

function make(universityId, searchString) {
  var fragment = EventFilterResponse$NimbusWeb.Fragment.course;
  var query = "\n    query (\$universityId: ID!, \$searchString: String) {\n      courses(\n        filter: {\n          universities: [\$universityId]\n          searchString: \$searchString\n          availableTutorsDropInPublic: true\n        },\n      ) {\n        ...CourseFragment\n      }\n    }\n    " + fragment + "\n  ";
  var variables = [
    {
      key: "universityId",
      val: universityId
    },
    {
      key: "searchString",
      val: searchString
    }
  ];
  return Request$NimbusWeb.createBody(query, variables);
}

function decodeData(json) {
  return {
          courses: Json_decode.field("courses", (function (param) {
                  return Json_decode.array(EventFilterResponse$NimbusWeb.Decode.course, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeData: decodeData,
  exec: exec
};

function normalize(payload) {
  return Belt_Option.mapWithDefault(payload.data, [], (function (data) {
                return data.courses;
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
