'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

var Type = {};

function dColors(json) {
  return {
          light: Json_decode.field("light", Json_decode.string, json),
          medium: Json_decode.field("medium", Json_decode.string, json),
          dark: Json_decode.field("dark", Json_decode.string, json)
        };
}

function dOrganizationColors(json) {
  return {
          student: Json_decode.field("student", dColors, json)
        };
}

function dText(json) {
  return {
          en: Json_decode.field("en", Json_decode.string, json),
          fr: Json_decode.optional((function (param) {
                  return Json_decode.field("fr", Json_decode.string, param);
                }), json)
        };
}

function dT(json) {
  return {
          logo: Json_decode.field("logoHeader", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          colors: Json_decode.optional((function (param) {
                  return Json_decode.field("colors", dOrganizationColors, param);
                }), json),
          dropInTag: Json_decode.optional((function (param) {
                  return Json_decode.field("dropInTag", dText, param);
                }), json)
        };
}

var Decode = {
  dColors: dColors,
  dOrganizationColors: dOrganizationColors,
  dText: dText,
  dT: dT
};

var fragment = "\n  fragment OrganizationThemeFragment on Organization {\n    logoHeader\n    name\n    colors { student { light medium dark } }\n    dropInTag { en fr }\n  }\n";

exports.fragment = fragment;
exports.Type = Type;
exports.Decode = Decode;
/* No side effect */
