'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Date$NimbusWeb = require("../../../utils/Date.bs.js");

function decodeSendFileResponse(json) {
  return {
          uri: Json_decode.field("uri", Json_decode.string, json)
        };
}

function decodeUser(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          first: Json_decode.optional((function (param) {
                  return Json_decode.field("first", Json_decode.string, param);
                }), json),
          last: Json_decode.optional((function (param) {
                  return Json_decode.field("last", Json_decode.string, param);
                }), json),
          avatar: Json_decode.optional((function (param) {
                  return Json_decode.field("avatar", Json_decode.string, param);
                }), json)
        };
}

function decodeDate(json) {
  return {
          timestamp: Json_decode.field("timestamp", Json_decode.string, json)
        };
}

function decodeAttachment(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json),
          payload: Json_decode.optional((function (param) {
                  return Json_decode.field("payload", Json_decode.string, param);
                }), json),
          filename: Json_decode.optional((function (param) {
                  return Json_decode.field("filename", Json_decode.string, param);
                }), json)
        };
}

function decodeMessage(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          body: Json_decode.optional((function (param) {
                  return Json_decode.field("body", Json_decode.string, param);
                }), json),
          attachment: Json_decode.optional((function (param) {
                  return Json_decode.field("attachment", decodeAttachment, param);
                }), json),
          from: Json_decode.field("from", decodeUser, json),
          createdAt: Json_decode.field("createdAt", decodeDate, json),
          type_: Json_decode.optional((function (param) {
                  return Json_decode.field("type", Json_decode.string, param);
                }), json)
        };
}

function decodeMessages(json) {
  return Json_decode.array(decodeMessage, json);
}

var Decode = {
  decodeSendFileResponse: decodeSendFileResponse,
  decodeUser: decodeUser,
  decodeDate: decodeDate,
  decodeAttachment: decodeAttachment,
  decodeMessage: decodeMessage,
  decodeMessages: decodeMessages
};

function normalizeMessage(m) {
  return {
          _id: m._id,
          body: m.body,
          attachment: m.attachment,
          date: Date$NimbusWeb.dateFromStringMS(m.createdAt.timestamp),
          type_: m.type_
        };
}

function normalizeBlock(m, nm) {
  var match = m.from.first;
  var match$1 = m.from.last;
  return {
          user: {
            _id: m.from._id,
            name: match !== undefined && match$1 !== undefined ? match + (" " + match$1) : undefined,
            avatar: m.from.avatar
          },
          messages: [nm],
          type_: Belt_Option.getWithDefault(m.type_, "MESSAGE")
        };
}

function normalizeDay(m, nm) {
  return {
          date: nm.date,
          blocks: [normalizeBlock(m, nm)]
        };
}

function pushMessageToArray(arr, message) {
  var nMessage = normalizeMessage(message);
  var lastDay = arr.pop();
  var toPush;
  if (lastDay !== undefined) {
    var match = lastDay.date;
    var match$1 = nMessage.date;
    if (match !== undefined && match$1 !== undefined) {
      if (Date$NimbusWeb.isSameDay(Caml_option.valFromOption(match), Caml_option.valFromOption(match$1))) {
        var lastBlock = lastDay.blocks.pop();
        if (lastBlock !== undefined) {
          var messageType = Belt_Option.getWithDefault(message.type_, "MESSAGE");
          var match$2 = messageType === lastBlock.type_;
          var match$3 = message.from._id === lastBlock.user._id;
          var exit = 0;
          if (match$2 && match$3) {
            lastBlock.messages.push(nMessage);
            lastDay.blocks.push(lastBlock);
          } else {
            exit = 1;
          }
          if (exit === 1) {
            lastDay.blocks.push(lastBlock);
            lastDay.blocks.push(normalizeBlock(message, nMessage));
          }
          
        }
        toPush = lastDay;
      } else {
        arr.push(lastDay);
        toPush = normalizeDay(message, nMessage);
      }
    } else {
      toPush = normalizeDay(message, nMessage);
    }
  } else {
    toPush = normalizeDay(message, nMessage);
  }
  return Belt_Array.concat(arr, [toPush]);
}

function normalize(messages) {
  return Belt_Array.reduceReverse(messages, [], pushMessageToArray);
}

var fragment = "\n  fragment MessageFragment on Message {\n    _id\n    body\n    attachment { type payload filename }\n    from { _id first last avatar }\n    createdAt { timestamp }\n    type\n  }\n";

exports.fragment = fragment;
exports.Decode = Decode;
exports.normalizeMessage = normalizeMessage;
exports.normalizeBlock = normalizeBlock;
exports.normalizeDay = normalizeDay;
exports.pushMessageToArray = pushMessageToArray;
exports.normalize = normalize;
/* Date-NimbusWeb Not a pure module */
