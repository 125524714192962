'use strict';

var React = require("react");
var ReactDom = require("react-dom");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var App$NimbusWeb = require("./App.bs.js");
var AuthContext$NimbusWeb = require("./contexts/AuthContext.bs.js");
var UserContext$NimbusWeb = require("./contexts/UserContext.bs.js");
var BrandContext$NimbusWeb = require("./contexts/BrandContext.bs.js");
var ErrorContext$NimbusWeb = require("./contexts/ErrorContext.bs.js");
var ThemeContext$NimbusWeb = require("./contexts/ThemeContext.bs.js");
var EventsContext$NimbusWeb = require("./contexts/EventsContext.bs.js");
var LessonContext$NimbusWeb = require("./contexts/LessonContext.bs.js");
var LocaleContext$NimbusWeb = require("./contexts/LocaleContext.bs.js");
var DropInsContext$NimbusWeb = require("./contexts/DropInsContext.bs.js");
var BrowserSupported$NimbusWeb = require("./contexts/BrowserSupported.bs.js");
var OrganizationContext$NimbusWeb = require("./contexts/OrganizationContext.bs.js");
var ReportAccessibility$NimbusWeb = require("./ReportAccessibility.bs.js");
var DropInStudentsContext$NimbusWeb = require("./contexts/DropInStudentsContext.bs.js");
var SSOUniversitiesContext$NimbusWeb = require("./contexts/SSOUniversitiesContext.bs.js");
var SelectedCoursesContext$NimbusWeb = require("./contexts/SelectedCoursesContext.bs.js");

require("./Index.css");

require('es6-promise').polyfill();
;

require('smoothscroll-polyfill').polyfill();
;

require('isomorphic-fetch');
;

ReactDom.render(React.createElement(ThemeContext$NimbusWeb.Wrap.make, {
          children: React.createElement(BrandContext$NimbusWeb.Wrap.make, {
                children: React.createElement(OrganizationContext$NimbusWeb.Wrap.make, {
                      children: React.createElement(LocaleContext$NimbusWeb.Wrap.make, {
                            children: React.createElement(BrowserSupported$NimbusWeb.make, {
                                  children: React.createElement(ErrorContext$NimbusWeb.Wrap.make, {
                                        children: React.createElement(SSOUniversitiesContext$NimbusWeb.Wrap.make, {
                                              children: React.createElement(AuthContext$NimbusWeb.Wrap.make, {
                                                    children: React.createElement(UserContext$NimbusWeb.Wrap.make, {
                                                          children: React.createElement(EventsContext$NimbusWeb.Wrap.make, {
                                                                children: React.createElement(DropInsContext$NimbusWeb.Wrap.make, {
                                                                      children: React.createElement(DropInStudentsContext$NimbusWeb.Wrap.make, {
                                                                            children: React.createElement(SelectedCoursesContext$NimbusWeb.Wrap.make, {
                                                                                  children: React.createElement(LessonContext$NimbusWeb.Wrap.make, {
                                                                                        children: React.createElement(App$NimbusWeb.make, {})
                                                                                      })
                                                                                })
                                                                          })
                                                                    })
                                                              })
                                                        })
                                                  })
                                            })
                                      })
                                })
                          })
                    })
              })
        }), Belt_Option.getExn(Caml_option.nullable_to_opt(document.querySelector("#root"))));

var react = (require('react'));

ReportAccessibility$NimbusWeb.run(react);

exports.react = react;
/*  Not a pure module */
