'use strict';


function make(file) {
  var formData = new FormData();
  formData.append("media", file);
  return formData;
}

exports.make = make;
/* No side effect */
