'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var MessageResponse$NimbusWeb = require("./MessageResponse.bs.js");

function make(group, message, file, uri, type_) {
  var body = {};
  var attachment = Belt_Option.isNone(file) ? "false" : "true";
  var query = "\n    mutation sendMessage(\$group: ID!, \$body: String, \$file: MessageAttachmentInput, \$type: MessageType) {\n      addMessage(input: { _group: \$group, body: \$body, attachment: " + attachment + " }, a: \$file, type: \$type) {\n        ...MessageFragment\n      }\n    }\n    " + MessageResponse$NimbusWeb.fragment + "\n  ";
  body["query"] = query;
  var variables = {};
  variables["group"] = group;
  variables["body"] = message;
  if (type_ !== undefined) {
    variables["type"] = type_;
  }
  if (file !== undefined && uri !== undefined) {
    var fileData = {};
    var type_$1 = file.type.includes("image") ? "IMAGE" : (
        file.type.includes("video") ? "VIDEO" : "OTHER"
      );
    fileData["type"] = type_$1;
    fileData["payload"] = uri;
    fileData["filename"] = file.name;
    variables["file"] = fileData;
  }
  body["variables"] = variables;
  return body;
}

function decodeData(json) {
  return {
          addMessage: Json_decode.optional((function (param) {
                  return Json_decode.field("addMessage", MessageResponse$NimbusWeb.Decode.decodeMessage, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeData: decodeData,
  exec: exec
};

function normalize(payload) {
  var data = payload.data;
  if (data !== undefined) {
    return data.addMessage;
  }
  
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* MessageResponse-NimbusWeb Not a pure module */
