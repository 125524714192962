'use strict';

var React = require("react");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");

require("./Resources.css");

var motionQuery = window.matchMedia("(prefers-reduced-motion)");

function generateLinkMarkup(headings) {
  var parsedHeaders = [];
  headings.forEach(function (heading) {
        parsedHeaders.push({
              title: heading.innerText,
              depth: Belt_Option.getWithDefault(Belt_Int.fromString(heading.nodeName.replace(/\D/g, "")), 1),
              id: heading.getAttribute("id")
            });
        
      });
  return parsedHeaders;
}

function updateLinks(visibleId, links) {
  links.forEach(function (link) {
        var href = link.getAttribute("href");
        var classList = link.classList;
        classList.remove("resources-toc-item-is-active");
        if (href === visibleId) {
          classList.add("resources-toc-item-is-active");
          return ;
        }
        
      });
  
}

function handleObserver(entries, links) {
  entries.forEach(function (entry) {
        var target = entry.target;
        var isIntersecting = entry.isIntersecting;
        var intersectionRatio = entry.intersectionRatio;
        if (!(isIntersecting && intersectionRatio >= 1.0)) {
          return ;
        }
        var id = target.getAttribute("id");
        var visibleId = "resources/#" + id;
        return updateLinks(visibleId, links);
      });
  
}

function createObserver(links) {
  return new IntersectionObserver((function (entries) {
                return handleObserver(entries, links);
              }), {
              rootMargin: "0px 0px -200px 0px",
              threshold: 1.0
            });
}

function ResourcesToc(Props) {
  var headers = Props.headers;
  var style = Props.style;
  return React.createElement("div", {
              id: "resources-toc-container",
              style: style
            }, headers.map(function (header) {
                  var id = header.id;
                  return React.createElement("li", {
                              key: id,
                              className: "resources-toc-li" + (
                                header.depth > 1 ? " resources-toc-item-d2" : ""
                              )
                            }, React.createElement("a", {
                                  className: "resources-toc-a",
                                  href: "resources/#" + id
                                }, header.title));
                }));
}

var make = ResourcesToc;

exports.motionQuery = motionQuery;
exports.generateLinkMarkup = generateLinkMarkup;
exports.updateLinks = updateLinks;
exports.handleObserver = handleObserver;
exports.createObserver = createObserver;
exports.make = make;
/*  Not a pure module */
