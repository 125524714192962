'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var EventResponse$NimbusWeb = require("./EventResponse.bs.js");

function make(startDateTime, endDateTime) {
  var query = "\n    query (\$startDateTime: String!, \$endDateTime: String!) {\n      fetchEvents(filter: {\n        dateRange: {\n          start: { timestamp: \$startDateTime }\n          end: { timestamp: \$endDateTime }\n        },\n        include: [PRIVATE, DROP_IN]\n      }) {\n        ...EventFragment\n      }\n    }\n    " + EventResponse$NimbusWeb.fragment + "\n  ";
  var variables = [
    {
      key: "startDateTime",
      val: startDateTime
    },
    {
      key: "endDateTime",
      val: endDateTime
    }
  ];
  return Request$NimbusWeb.createBody(query, variables);
}

function dData(json) {
  return {
          events: Json_decode.optional((function (param) {
                  return Json_decode.field("fetchEvents", (function (param) {
                                return Json_decode.array(EventResponse$NimbusWeb.Decode.dT, param);
                              }), param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", dData, param);
                }), json)
        };
}

var Decode = {
  dData: dData,
  exec: exec
};

function normalize(payload) {
  var data = payload.data;
  if (data === undefined) {
    return [];
  }
  var events = data.events;
  if (events !== undefined) {
    return Belt_Array.map(events, EventResponse$NimbusWeb.normalize);
  } else {
    return [];
  }
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
