'use strict';

var React = require("react");
var Helmet$NimbusWeb = require("../../components/Helmet.bs.js");

require("./SplashScreen.css");

var nimbusLogo = require("@assets/nimbus_logo_colored.png").default;

function SplashScreen(Props) {
  return React.createElement("div", {
              className: "ss-container"
            }, React.createElement(Helmet$NimbusWeb.make, {
                  title: "Nimbus Learning-Home"
                }), React.createElement("img", {
                  className: "ss-logo",
                  alt: "logo",
                  src: nimbusLogo
                }));
}

var make = SplashScreen;

exports.nimbusLogo = nimbusLogo;
exports.make = make;
/*  Not a pure module */
