'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Utils$NimbusWeb = require("./Utils.bs.js");

function openSSOLink(endpoint) {
  var tp = process.env.REACT_APP_CONTEXT === "developement" ? "http://" : "https://";
  var url = tp + (process.env.REACT_APP_DOMAIN + ("/" + (endpoint + "?content=web")));
  window.open(url, "_self");
  
}

function getLessonFromEvent($$event, lessons) {
  return Belt_Option.flatMap(lessons, (function (ls) {
                return Caml_option.undefined_to_opt(ls.find(function (l) {
                                return l._id === $$event.objectId;
                              }));
              }));
}

function getDropInFromEvent($$event, dropIns, dropInStudents) {
  var dropIn = dropIns.find(function (d) {
        return d._id === $$event.objectId;
      });
  var dropInStudents$1 = Belt_Option.flatMap($$event.dropInStudentsId, (function (id) {
          return Caml_option.undefined_to_opt(dropInStudents.find(function (ds) {
                          return ds._id === id;
                        }));
        }));
  return [
          dropIn === undefined ? undefined : Caml_option.some(dropIn),
          dropInStudents$1
        ];
}

var defaultName = "Unknown User";

function getNameFromLesson(userType, lesson) {
  if (userType === undefined) {
    return defaultName;
  }
  switch (userType) {
    case "student" :
        return Belt_Option.mapWithDefault(lesson, defaultName, (function (l) {
                      return Belt_Option.mapWithDefault(l.nameTutor, defaultName, (function (name) {
                                    return name;
                                  }));
                    }));
    case "tutor" :
        return Belt_Option.mapWithDefault(lesson, defaultName, (function (l) {
                      return Belt_Option.mapWithDefault(l.nameStudent, defaultName, (function (name) {
                                    return name;
                                  }));
                    }));
    default:
      return defaultName;
  }
}

function getLessonAndDropInFromEvent($$event, lessons, dropIns, dropInStudents) {
  var lesson = getLessonFromEvent($$event, lessons);
  var match = getDropInFromEvent($$event, dropIns, dropInStudents);
  return [
          lesson,
          match[0],
          match[1]
        ];
}

function getChannelId(lesson, dropInStudent) {
  if (lesson !== undefined) {
    return "p_" + lesson._id;
  } else if (dropInStudent !== undefined) {
    return "d_" + dropInStudent._id;
  } else {
    return ;
  }
}

function getSelectedEvent(events, selectedEventId) {
  return Belt_Option.flatMap(selectedEventId, (function (selectedId) {
                return Caml_option.undefined_to_opt(events.find(function ($$event) {
                                return $$event._id === selectedId;
                              }));
              }));
}

function getHostId(lesson, dropIn) {
  if (lesson !== undefined) {
    return Belt_Option.getWithDefault(lesson.tutorId, "-1");
  } else if (dropIn !== undefined) {
    return dropIn.host._id;
  } else {
    return "-1";
  }
}

function getAttendeeCurrentList(lesson, dropInStudents) {
  if (lesson !== undefined) {
    return lesson.onlineAttendeesCurrent;
  } else if (dropInStudents !== undefined) {
    return dropInStudents.attendeesCurrent;
  } else {
    return [];
  }
}

function getHostUids(lesson, dropIn) {
  if (lesson !== undefined) {
    return [
            lesson.uidTutor,
            lesson.uidSSTutor
          ];
  } else if (dropIn !== undefined) {
    return [
            dropIn.host.uid,
            dropIn.host.uidSS
          ];
  } else {
    return [
            -1,
            -1
          ];
  }
}

function getAllUsers(lesson, dropInStudents, dropIn) {
  if (lesson !== undefined) {
    return [
            {
              _id: Belt_Option.getWithDefault(lesson.tutorId, "tutor"),
              first: Belt_Option.getWithDefault(lesson.nameTutor, "Tutor Name"),
              last: "",
              avatar: lesson.avatarTutor,
              uid: lesson.uidTutor,
              uidSS: lesson.uidSSTutor
            },
            {
              _id: Belt_Option.getWithDefault(lesson.studentId, "student"),
              first: Belt_Option.getWithDefault(lesson.nameStudent, "Student Name"),
              last: "",
              avatar: lesson.avatarStudent,
              uid: lesson.uidStudent,
              uidSS: lesson.uidSSStudent
            }
          ];
  } else if (dropIn !== undefined) {
    if (dropInStudents !== undefined) {
      return Belt_Array.concat(dropInStudents.interestedStudents, [dropIn.host]);
    } else {
      return [];
    }
  } else {
    return [];
  }
}

function getWhiteboardUrl(lesson, dropInStudents) {
  if (lesson !== undefined) {
    return lesson.boardUrl;
  } else if (dropInStudents !== undefined) {
    return dropInStudents.boardUrl;
  } else {
    return ;
  }
}

function getGroupId(lesson, dropInStudents) {
  if (lesson !== undefined) {
    return lesson.groupId;
  } else if (dropInStudents !== undefined) {
    return dropInStudents.groupId;
  } else {
    return ;
  }
}

function getExtension(attachment) {
  return Belt_Option.mapWithDefault(attachment.filename, "???", (function (filename) {
                var split = filename.split(".");
                return Belt_Option.mapWithDefault(Utils$NimbusWeb.getLast(split), "???", (function (extn) {
                              return extn.toUpperCase();
                            }));
              }));
}

function getStreamWithCallback(evt, callback) {
  if (evt.stream !== undefined) {
    return Curry._1(callback, evt.stream);
  }
  
}

exports.openSSOLink = openSSOLink;
exports.getLessonFromEvent = getLessonFromEvent;
exports.getDropInFromEvent = getDropInFromEvent;
exports.defaultName = defaultName;
exports.getNameFromLesson = getNameFromLesson;
exports.getLessonAndDropInFromEvent = getLessonAndDropInFromEvent;
exports.getChannelId = getChannelId;
exports.getSelectedEvent = getSelectedEvent;
exports.getHostId = getHostId;
exports.getAttendeeCurrentList = getAttendeeCurrentList;
exports.getHostUids = getHostUids;
exports.getAllUsers = getAllUsers;
exports.getWhiteboardUrl = getWhiteboardUrl;
exports.getGroupId = getGroupId;
exports.getExtension = getExtension;
exports.getStreamWithCallback = getStreamWithCallback;
/* No side effect */
