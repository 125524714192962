'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function token(json) {
  return {
          userToken: Json_decode.optional((function (param) {
                  return Json_decode.field("userToken", Json_decode.string, param);
                }), json),
          studentToken: Json_decode.optional((function (param) {
                  return Json_decode.field("studentToken", Json_decode.string, param);
                }), json),
          tutorToken: Json_decode.optional((function (param) {
                  return Json_decode.field("tutorToken", Json_decode.string, param);
                }), json),
          adminToken: Json_decode.optional((function (param) {
                  return Json_decode.field("adminToken", Json_decode.string, param);
                }), json),
          lastLoginType: Json_decode.optional((function (param) {
                  return Json_decode.field("lastLoginType", Json_decode.string, param);
                }), json)
        };
}

var Decode = {
  token: token
};

function normalize(token) {
  var match = Belt_Option.mapWithDefault(token.lastLoginType, [
        undefined,
        undefined
      ], (function (lastLoginType) {
          switch (lastLoginType) {
            case "ADMIN" :
                return [
                        token.adminToken,
                        "admin"
                      ];
            case "STUDENT" :
                return [
                        token.studentToken,
                        "student"
                      ];
            case "TUTOR" :
                return [
                        token.tutorToken,
                        "tutor"
                      ];
            case "UNKNOWN" :
                return [
                        token.userToken,
                        "user"
                      ];
            default:
              return [
                      undefined,
                      undefined
                    ];
          }
        }));
  return {
          token: match[0],
          userType: match[1]
        };
}

var fragment = "\n  fragment TokenFragment on Token {\n    userToken\n    studentToken\n    tutorToken\n    adminToken\n    lastLoginType\n  }\n";

exports.fragment = fragment;
exports.Decode = Decode;
exports.normalize = normalize;
/* No side effect */
