'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_Close(Props) {
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: "48",
              width: "48",
              viewBox: "0 -960 960 960",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z",
                  fill: c
                }));
}

var make = Icon_Close;

exports.make = make;
/* react Not a pure module */
