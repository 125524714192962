'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var EventResponse$NimbusWeb = require("./EventResponse.bs.js");

function make(courseIds) {
  var query = "\n    query (\$courses: [ID]!) {\n      fetchEventsDropInPublic(courses: \$courses) {\n        ...EventFragment\n      }\n    }\n    " + EventResponse$NimbusWeb.fragment + "\n  ";
  var variables = [{
      key: "courses",
      val: courseIds
    }];
  return Request$NimbusWeb.createBody(query, variables);
}

function dData(json) {
  return {
          events: Json_decode.field("fetchEventsDropInPublic", (function (param) {
                  return Json_decode.array(EventResponse$NimbusWeb.Decode.dT, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", dData, param);
                }), json)
        };
}

var Decode = {
  dData: dData,
  exec: exec
};

function normalize(payload) {
  return Belt_Option.mapWithDefault(payload.data, [], (function (data) {
                return Belt_Array.map(data.events, EventResponse$NimbusWeb.normalize);
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
