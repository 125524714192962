'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Alert$NimbusWeb = require("../../components/Alert.bs.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var LocaleContext$NimbusWeb = require("../../contexts/LocaleContext.bs.js");

require("./FileTooLargeAlert.css");

function FileTooLargeAlert(Props) {
  var visible = Props.visible;
  var onCloseOpt = Props.onClose;
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  var text = match[0];
  return React.createElement(Alert$NimbusWeb.make, {
              visible: visible,
              onClose: onClose,
              children: null
            }, React.createElement(Alert$NimbusWeb.Title.make, {
                  text: Curry._3(text, "message.file_too_large_alert.title", undefined, undefined)
                }), React.createElement(Alert$NimbusWeb.Content.make, {
                  text: Curry._3(text, "message.file_too_large_alert.content", undefined, undefined)
                }), React.createElement(Alert$NimbusWeb.Actions.make, {
                  children: React.createElement(Button$NimbusWeb.make, {
                        variant: /* Contained */1,
                        className: "file-too-large-alert-button",
                        onClick: onClose,
                        text: Curry._3(text, "general.ok", undefined, undefined),
                        accessKey: "o"
                      })
                }));
}

var make = FileTooLargeAlert;

exports.make = make;
/*  Not a pure module */
