'use strict';

var React = require("react");
var ReactHelmet = require("react-helmet");

var Helmet = {};

function Helmet$1(Props) {
  var title = Props.title;
  return React.createElement(ReactHelmet.Helmet, {
              children: React.createElement("title", undefined, title)
            });
}

var make = Helmet$1;

exports.Helmet = Helmet;
exports.make = make;
/* react Not a pure module */
