'use strict';

var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Date$NimbusWeb = require("../../utils/Date.bs.js");
var Avatar$NimbusWeb = require("../../components/Avatar.bs.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var Loader$NimbusWeb = require("../../components/Loader.bs.js");
var Helpers$NimbusWeb = require("../../utils/Helpers.bs.js");
var Icon_Doc$NimbusWeb = require("../../icons/Icon_Doc.bs.js");
var Icon_Send$NimbusWeb = require("../../icons/Icon_Send.bs.js");
var TextField$NimbusWeb = require("../../components/TextField.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var Icon_Cancel$NimbusWeb = require("../../icons/Icon_Cancel.bs.js");
var Icon_Download$NimbusWeb = require("../../icons/Icon_Download.bs.js");

require("./Chat.css");

function ChatUI$Container(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "chat-container"
            }, children);
}

var Container = {
  make: ChatUI$Container
};

function ChatUI$Message$Container(Props) {
  var onScroll = Props.onScroll;
  var children = Props.children;
  return React.createElement("div", {
              className: "chat-days-container",
              onScroll: onScroll
            }, children);
}

var Container$1 = {
  make: ChatUI$Message$Container
};

function ChatUI$Message$Day(Props) {
  var children = Props.children;
  var date = Props.date;
  var locale = Props.locale;
  return React.createElement("div", {
              className: "chat-day-container"
            }, React.createElement(Typography$NimbusWeb.make, {
                  className: "chat-date-text",
                  value: Belt_Option.mapWithDefault(date, "Invalid Date", (function (date) {
                          return Date$NimbusWeb.format2(date, "mDY", locale);
                        }))
                }), children);
}

var Day = {
  make: ChatUI$Message$Day
};

function getClassNames(block, profile) {
  if (profile !== undefined && profile._id === block.user._id) {
    return [
            " chat-message-right",
            " no-display"
          ];
  } else {
    return [
            "",
            ""
          ];
  }
}

function ChatUI$Message$Block(Props) {
  var children = Props.children;
  var block = Props.block;
  var displaySide = Props.displaySide;
  var displayAvatar = Props.displayAvatar;
  var type_ = Props.type_;
  switch (type_) {
    case "WHITEBOARD_START" :
    case "WHITEBOARD_STOP" :
        break;
    default:
      var name = Belt_Option.getWithDefault(block.user.name, "Unknown User");
      return React.createElement("div", {
                  className: "chat-block-container"
                }, React.createElement("div", {
                      className: "chat-avatar-container" + displayAvatar
                    }, React.createElement(Avatar$NimbusWeb.make, {
                          className: "chat-avatar",
                          src: block.user.avatar,
                          alt: "avatar_" + name,
                          size: /* Small */0
                        })), React.createElement("div", {
                      className: "chat-messages-container"
                    }, React.createElement(Typography$NimbusWeb.make, {
                          className: "text-size12 chat-message-container " + displaySide,
                          value: name
                        }), children));
  }
  return React.createElement("div", {
              className: "chat-action-container"
            }, children);
}

var Block = {
  make: ChatUI$Message$Block
};

function ChatUI$Message$ActionText(Props) {
  var text = Props.text;
  return React.createElement(Typography$NimbusWeb.make, {
              className: "text-size12 chat-action-text",
              value: text
            });
}

var ActionText = {
  make: ChatUI$Message$ActionText
};

function ChatUI$Message$Bubble(Props) {
  var ref_ = Props.ref_;
  var isAttachment = Props.isAttachment;
  var isFirst = Props.isFirst;
  var isLast = Props.isLast;
  var displaySide = Props.displaySide;
  var children = Props.children;
  var match = isAttachment ? [
      "#c1c1c1",
      "100%",
      ""
    ] : [
      "white",
      "",
      "1px solid #cdcdcd"
    ];
  var first = isFirst ? " chat-message-first" : "";
  var last = isLast ? " chat-message-last" : "";
  var className = "chat-message-container" + (displaySide + (first + last));
  return React.createElement("div", {
              ref: ref_,
              className: className,
              style: {
                backgroundColor: match[0],
                border: match[2],
                width: match[1]
              }
            }, children);
}

function ChatUI$Message$Bubble$Text(Props) {
  var value = Props.value;
  return React.createElement(Typography$NimbusWeb.make, {
              color: /* Black */11,
              className: "chat-message-body",
              value: value
            });
}

var $$Text = {
  make: ChatUI$Message$Bubble$Text
};

function ChatUI$Message$Bubble$Attachment$ContentContainer(Props) {
  var onClick = Props.onClick;
  var id = Props.id;
  var children = Props.children;
  return React.createElement("div", {
              style: {
                position: "relative",
                width: "100%"
              }
            }, children, React.createElement("div", {
                  className: "chat-attachment-overlay",
                  onClick: onClick
                }, React.createElement(Icon_Download$NimbusWeb.make, {
                      color: /* Black */11
                    })), React.createElement("a", {
                  id: id,
                  target: "_blank"
                }));
}

var ContentContainer = {
  make: ChatUI$Message$Bubble$Attachment$ContentContainer
};

function ChatUI$Message$Bubble$Attachment$Content(Props) {
  var attachment = Props.attachment;
  var match = attachment.type_;
  var match$1 = attachment.payload;
  if (match === "IMAGE") {
    if (match$1 !== undefined) {
      return React.createElement("img", {
                  src: match$1,
                  width: "100%"
                });
    } else {
      return null;
    }
  }
  if (match$1 === undefined) {
    return null;
  }
  var extension = Helpers$NimbusWeb.getExtension(attachment);
  var filename = attachment.filename;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "chat-message-attachment"
                }, React.createElement("div", {
                      className: "chat-attachment-icon-container"
                    }, React.createElement(Icon_Doc$NimbusWeb.make, {
                          text: extension.substring(0, 3)
                        })), React.createElement("div", {
                      className: "chat-attachment-filename-container"
                    }, React.createElement(Typography$NimbusWeb.make, {
                          color: /* Black */11,
                          className: "chat-message-body chat-attachment-filename",
                          value: filename !== undefined && filename !== "" ? filename : "???"
                        }))), React.createElement(Typography$NimbusWeb.make, {
                  color: /* Black */11,
                  className: "chat-attachment-extension",
                  value: extension
                }));
}

var Content = {
  make: ChatUI$Message$Bubble$Attachment$Content
};

var Attachment = {
  ContentContainer: ContentContainer,
  Content: Content
};

var Bubble = {
  make: ChatUI$Message$Bubble,
  $$Text: $$Text,
  Attachment: Attachment
};

var Message = {
  Container: Container$1,
  Day: Day,
  getClassNames: getClassNames,
  Block: Block,
  ActionText: ActionText,
  Bubble: Bubble
};

function ChatUI$Footer(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "chat-input-container"
            }, children);
}

function ChatUI$Footer$Input(Props) {
  var ref_ = Props.ref_;
  var placeholder = Props.placeholder;
  var onChangeText = Props.onChangeText;
  var value = Props.value;
  var onSelectFile = Props.onSelectFile;
  var onKeyPress = Props.onKeyPress;
  return React.createElement(TextField$NimbusWeb.make, {
              className: "chat-input",
              type_: "text",
              placeholder: placeholder,
              value: value,
              onChange: onChangeText,
              onKeyPress: onKeyPress,
              children: React.createElement("input", {
                    ref: ref_,
                    id: "file",
                    style: {
                      display: "none"
                    },
                    multiple: false,
                    type: "file",
                    onChange: onSelectFile
                  }),
              label: "chat"
            });
}

function ChatUI$Footer$Input$FileDisplay(Props) {
  var file = Props.file;
  var onClick = Props.onClick;
  return React.createElement(TextField$NimbusWeb.make, {
              disabled: true,
              className: "chat-input",
              type_: "text",
              placeholder: "",
              value: file.name,
              onChange: (function (param) {
                  
                }),
              children: React.createElement(Button$NimbusWeb.Icon.make, {
                    onClick: onClick,
                    children: React.createElement(Icon_Cancel$NimbusWeb.make, {
                          color: /* Gray3 */9
                        }),
                    ariaLabel: "remove file",
                    accessKey: "c"
                  })
            });
}

var FileDisplay = {
  make: ChatUI$Footer$Input$FileDisplay
};

var Input = {
  make: ChatUI$Footer$Input,
  FileDisplay: FileDisplay
};

function ChatUI$Footer$SendButton(Props) {
  var disabled = Props.disabled;
  var onClick = Props.onClick;
  var loading = Props.loading;
  return React.createElement("div", {
              className: "chat-send-button-container"
            }, React.createElement(Button$NimbusWeb.Icon.make, {
                  className: "center",
                  disabled: disabled,
                  onClick: onClick,
                  bgColor: /* Primary */0,
                  size: /* Small */0,
                  children: React.createElement(Icon_Send$NimbusWeb.make, {
                        color: /* White */10
                      }),
                  hover: false,
                  ariaLabel: "send message",
                  accessKey: "s"
                }), loading ? React.createElement(Loader$NimbusWeb.Circular.Simple.make, {
                    size: /* Small */0
                  }) : null);
}

var SendButton = {
  make: ChatUI$Footer$SendButton
};

var Footer = {
  make: ChatUI$Footer,
  Input: Input,
  SendButton: SendButton
};

exports.Container = Container;
exports.Message = Message;
exports.Footer = Footer;
/*  Not a pure module */
