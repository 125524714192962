'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_Doc(Props) {
  var colorOpt = Props.color;
  var textOpt = Props.text;
  var color = colorOpt !== undefined ? colorOpt : /* Primary */0;
  var text = textOpt !== undefined ? textOpt : "DOC";
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: "28",
              width: "21",
              fill: "none",
              viewBox: "0 0 21 28",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M18.931 0H9.46552C9.36207 0 9.31035 0 9.2069 0C9.15517 0 9.15517 -4.84288e-08 9.10345 0.0522388C9.05172 0.0522388 9 0.052239 9 0.104478C8.94828 0.104478 8.94828 0.156717 8.94828 0.156717C8.94828 0.156717 8.89655 0.156716 8.89655 0.208955C8.84483 0.208955 8.7931 0.208955 8.7931 0.208955C8.74138 0.261194 8.63793 0.313433 8.58621 0.365672L0.413793 8.6194C0.362069 8.67164 0.310345 8.72388 0.258621 8.82836C0.258621 8.82836 0.206897 8.8806 0.206897 8.93284C0.206897 8.93284 0.206897 8.98507 0.155172 8.98507L0.103448 9.03731C0.103448 9.08955 0.0517241 9.14179 0.0517241 9.14179C0.0517241 9.19403 0.0517241 9.19403 0 9.24627C0 9.35074 0 9.40298 0 9.50746V25.9104C0 27.0597 0.931035 28 2.06897 28H18.931C20.069 28 21 27.0597 21 25.9104V2.08955C21 0.940299 20.069 0 18.931 0ZM8.22414 4.4403V6.37313C8.22414 8.04478 7.75862 8.20149 6.77586 8.20149H4.44828L8.22414 4.4403ZM18.4138 25.3881H2.63793V10.8657H6.77586C8.27586 10.8657 10.8103 10.291 10.8103 6.42537V2.66418H18.4655V25.3881H18.4138Z",
                  fill: c
                }), React.createElement("text", {
                  fill: c,
                  fontFamily: "sans-serif",
                  fontSize: "6",
                  x: "4",
                  y: "19"
                }, text));
}

var make = Icon_Doc;

exports.make = make;
/* react Not a pure module */
