'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Float = require("bs-platform/lib/js/belt_Float.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_splice_call = require("bs-platform/lib/js/caml_splice_call.js");
var Locale$NimbusWeb = require("./Locale.bs.js");

var weekdays = {
  hd: "Sunday",
  tl: {
    hd: "Monday",
    tl: {
      hd: "Tuesday",
      tl: {
        hd: "Wednesday",
        tl: {
          hd: "Thursday",
          tl: {
            hd: "Friday",
            tl: {
              hd: "Saturday",
              tl: /* [] */0
            }
          }
        }
      }
    }
  }
};

var weekdaysShort = Belt_List.map(weekdays, (function (param) {
        return param.substring(0, 3);
      }));

var months = {
  hd: "January",
  tl: {
    hd: "February",
    tl: {
      hd: "March",
      tl: {
        hd: "April",
        tl: {
          hd: "May",
          tl: {
            hd: "June",
            tl: {
              hd: "July",
              tl: {
                hd: "August",
                tl: {
                  hd: "September",
                  tl: {
                    hd: "October",
                    tl: {
                      hd: "November",
                      tl: {
                        hd: "December",
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var monthsShort = Belt_List.map(months, (function (param) {
        return param.substring(0, 3);
      }));

function dateFromStringMS(timestampString) {
  var timestampOptional = Belt_Float.fromString(timestampString);
  if (timestampOptional !== undefined) {
    return Caml_option.some(new Date(timestampOptional));
  }
  
}

function $plus$plus$plus(date1, ms) {
  var ts = date1.getTime();
  return new Date(ts + ms);
}

function get(date, f, arr) {
  var idx = Curry._1(f, date);
  if (isNaN(idx)) {
    return ;
  } else {
    return Belt_List.get(arr, idx | 0);
  }
}

function getDay(date) {
  return get(date, (function (prim) {
                return prim.getDay();
              }), weekdays);
}

function getDayShort(date) {
  return get(date, (function (prim) {
                return prim.getDay();
              }), weekdaysShort);
}

function getMonth(date) {
  return get(date, (function (prim) {
                return prim.getMonth();
              }), months);
}

function getMonthShort(date) {
  return get(date, (function (prim) {
                return prim.getMonth();
              }), monthsShort);
}

function getDateInfoWith(date, f) {
  var info = Curry._1(f, date);
  if (isNaN(info)) {
    return "";
  } else {
    return String(info);
  }
}

function getMeridiem(date) {
  var hours = date.getHours();
  if (isNaN(hours)) {
    return "";
  } else if (hours < 12.0) {
    return "am";
  } else {
    return "pm";
  }
}

function format(date, options) {
  return Caml_splice_call.spliceObjApply("", "concat", [Array.from(options, (function (c) {
                      switch (c) {
                        case "D" :
                            return getDateInfoWith(date, (function (prim) {
                                          return prim.getDate();
                                        }));
                        case "H" :
                            return getDateInfoWith(date, (function (prim) {
                                          return prim.getHours();
                                        }));
                        case "I" :
                            var str = getDateInfoWith(date, (function (prim) {
                                    return prim.getMinutes();
                                  }));
                            var padStr = "0";
                            var maxLen = 2;
                            var len = str.length;
                            if (len > maxLen) {
                              return str;
                            } else {
                              return Caml_splice_call.spliceObjApply(str, "concat", [Belt_Array.make(maxLen - len | 0, padStr)]);
                            }
                        case "M" :
                            var s = getMonth(date);
                            if (s !== undefined) {
                              return s;
                            } else {
                              return "";
                            }
                        case "N" :
                            return getMeridiem(date).toUpperCase();
                        case "W" :
                            var d = getDay(date);
                            if (d !== undefined) {
                              return d;
                            } else {
                              return "";
                            }
                        case "Y" :
                            return getDateInfoWith(date, (function (prim) {
                                          return prim.getFullYear();
                                        }));
                        case "h" :
                            var hours = date.getHours();
                            if (hours > 12.0) {
                              return String(hours - 12.0);
                            } else if (isNaN(hours)) {
                              return "";
                            } else {
                              return String(hours);
                            }
                        case "m" :
                            var s$1 = getMonthShort(date);
                            if (s$1 !== undefined) {
                              return s$1;
                            } else {
                              return "";
                            }
                        case "n" :
                            return getMeridiem(date);
                        case "w" :
                            var d$1 = getDayShort(date);
                            if (d$1 !== undefined) {
                              return d$1;
                            } else {
                              return "";
                            }
                        default:
                          return c;
                      }
                    }))]);
}

function format2(date, options, locale) {
  var fmtOptions_hour12 = locale ? false : true;
  var fmtOptions_weekday = options.includes("W") ? "long" : (
      options.includes("w") ? "short" : undefined
    );
  var fmtOptions_year = options.includes("Y") ? "numeric" : undefined;
  var fmtOptions_month = options.includes("M") ? "long" : (
      options.includes("m") ? "short" : undefined
    );
  var fmtOptions_day = options.includes("D") ? "numeric" : undefined;
  var fmtOptions_hour = options.includes("H") ? "numeric" : undefined;
  var fmtOptions_minute = options.includes("I") ? "numeric" : undefined;
  var fmtOptions = {
    hour12: fmtOptions_hour12,
    weekday: fmtOptions_weekday,
    year: fmtOptions_year,
    month: fmtOptions_month,
    day: fmtOptions_day,
    hour: fmtOptions_hour,
    minute: fmtOptions_minute
  };
  return date.toLocaleString(Locale$NimbusWeb.toString(locale), fmtOptions);
}

var units_0 = 1000 * 60 * 60 * 24;

var units_1 = {
  hd: 1000 * 60 * 60,
  tl: {
    hd: 1000 * 60,
    tl: {
      hd: 1000,
      tl: /* [] */0
    }
  }
};

var units = {
  hd: units_0,
  tl: units_1
};

function timerText(timestamp, daysText) {
  var ns = [
    0,
    0,
    0,
    0
  ];
  Belt_List.reduceWithIndex(units, timestamp, (function (acc, ms, i) {
          var u = acc / ms | 0;
          Caml_array.set(ns, i, u);
          return acc - u * ms;
        }));
  var time = Belt_Array.reduce(Belt_Array.slice(ns, 1, 3), "", (function (acc, t) {
          if (t < 10) {
            return acc + (":0" + String(t));
          } else {
            return acc + (":" + String(t));
          }
        }));
  var time$1 = time.substr(1);
  if (Caml_array.get(ns, 0) > 0) {
    return String(Caml_array.get(ns, 0)) + (" " + (daysText + (" " + time$1)));
  } else {
    return time$1;
  }
}

function isSameDay(d1, d2) {
  if (d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth()) {
    return d1.getFullYear() === d2.getFullYear();
  } else {
    return false;
  }
}

function toLocaleString(prim, prim$1, prim$2) {
  return prim.toLocaleString(prim$1, prim$2);
}

exports.$plus$plus$plus = $plus$plus$plus;
exports.dateFromStringMS = dateFromStringMS;
exports.format = format;
exports.format2 = format2;
exports.timerText = timerText;
exports.isSameDay = isSameDay;
exports.toLocaleString = toLocaleString;
/* weekdaysShort Not a pure module */
