'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

var tp = process.env.REACT_APP_CONTEXT === "development" ? "ws://" : "wss://";

var url = tp + (process.env.REACT_APP_DOMAIN + "/nimbus_ws");

function Make(Config) {
  var mutableCounter = [0];
  var callbacks = [];
  var decodeData = function (json) {
    return {
            content: Json_decode.optional((function (param) {
                    return Json_decode.field(Config.contentKey, Config.decodeContent, param);
                  }), json)
          };
  };
  var decodePayload = function (json) {
    return {
            data: Json_decode.optional((function (param) {
                    return Json_decode.field("data", decodeData, param);
                  }), json)
          };
  };
  var Decode = {
    decodeData: decodeData,
    decodePayload: decodePayload
  };
  var connect = function (setReadyState) {
    var socket = new WebSocket(url, "graphql-ws");
    socket.onopen = (function (param) {
        var param$1 = {};
        param$1["type"] = "connection_init";
        param$1["payload"] = {};
        console.log("Opening socket connection");
        socket.send(JSON.stringify(param$1));
        return Curry._1(setReadyState, (function (param) {
                      return socket.readyState;
                    }));
      });
    socket.onerror = (function (prim) {
        console.log(prim);
        
      });
    socket.onmessage = (function (message) {
        var json;
        try {
          json = JSON.parse(message.data);
        }
        catch (exn){
          console.log("Error parsing socket message");
          json = {};
        }
        var data_type_ = Json_decode.optional((function (param) {
                return Json_decode.field("type", Json_decode.string, param);
              }), json);
        var data_id = Json_decode.optional((function (param) {
                return Json_decode.field("id", Json_decode.string, param);
              }), json);
        var data_payload = Json_decode.optional((function (param) {
                return Json_decode.field("payload", decodePayload, param);
              }), json);
        var match = data_type_;
        var match$1 = data_id;
        var match$2 = data_payload;
        if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match === "data") {
          var callbackId = Belt_Int.fromString(match$1);
          if (callbackId !== undefined) {
            var callback = Belt_Array.get(callbacks, callbackId);
            if (callback !== undefined) {
              var mData = match$2.data;
              if (mData !== undefined) {
                var content = mData.content;
                if (content !== undefined) {
                  Curry._1(callback, Curry._1(Config.normalizeCallbackArg, Caml_option.valFromOption(content)));
                }
                
              }
              
            }
            
          }
          
        }
        
      });
    return socket;
  };
  var unsubscribe = function (socket, id) {
    var param = {};
    param["type"] = "stop";
    param["id"] = id;
    param["payload"] = {};
    if (socket.readyState !== 3) {
      return socket.send(JSON.stringify(param));
    }
    
  };
  var subscribe = function (socket, query, variables, callback) {
    callbacks.push(callback);
    var id = String(mutableCounter[0]);
    Belt_Array.set(mutableCounter, 0, mutableCounter[0] + 1 | 0);
    var param = {};
    param["type"] = "start";
    param["id"] = id;
    var payload = {};
    payload["query"] = query;
    payload["variables"] = variables;
    payload["extensions"] = {};
    param["payload"] = payload;
    socket.send(JSON.stringify(param));
    return function (param) {
      return unsubscribe(socket, id);
    };
  };
  var updateCallback = function (newCallback) {
    Belt_Array.set(callbacks, 0, newCallback);
    
  };
  var Subscription$Make$Component = function (Props) {
    var query = Props.query;
    var variables = Props.variables;
    var callback = Props.callback;
    var match = React.useState(function () {
          
        });
    var setWs = match[1];
    var ws = match[0];
    var match$1 = React.useState(function () {
          return false;
        });
    var setMounted = match$1[1];
    var mounted = match$1[0];
    React.useEffect((function () {
            Curry._1(setMounted, (function (param) {
                    return true;
                  }));
            return (function (param) {
                      return Curry._1(setMounted, (function (param) {
                                    return false;
                                  }));
                    });
          }), []);
    var match$2 = React.useState(function () {
          return 3;
        });
    var setReadyState = match$2[1];
    var readyState = match$2[0];
    React.useEffect((function () {
            var tmp;
            if (ws !== undefined) {
              ws.onclose = (function (param) {
                  console.log("Socket is closing. Attempting to reconnect...");
                  if (!mounted) {
                    return ;
                  }
                  Curry._1(setReadyState, (function (param) {
                          return ws.readyState;
                        }));
                  var newSocket = connect(setReadyState);
                  return Curry._1(setWs, (function (param) {
                                return newSocket;
                              }));
                });
              tmp = (function (param) {
                  
                });
            } else {
              var socket = connect(setReadyState);
              Curry._1(setWs, (function (param) {
                      return socket;
                    }));
              tmp = (function (param) {
                  
                });
            }
            return tmp;
          }), [ws]);
    React.useEffect((function () {
            var tmp;
            var exit = 0;
            if (ws !== undefined && ws.readyState === 1 && readyState === 1) {
              tmp = subscribe(ws, query, variables, callback);
            } else {
              exit = 1;
            }
            if (exit === 1) {
              tmp = (function (param) {
                  
                });
            }
            return tmp;
          }), [
          ws,
          readyState
        ]);
    React.useEffect((function () {
            updateCallback(callback);
            
          }), [callback]);
    return null;
  };
  var Component = {
    make: Subscription$Make$Component
  };
  return {
          mutableCounter: mutableCounter,
          callbacks: callbacks,
          Decode: Decode,
          connect: connect,
          unsubscribe: unsubscribe,
          subscribe: subscribe,
          updateCallback: updateCallback,
          Component: Component
        };
}

exports.tp = tp;
exports.url = url;
exports.Make = Make;
/* tp Not a pure module */
