'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Login$NimbusWeb = require("../login/Login.bs.js");
var Helmet$NimbusWeb = require("../../components/Helmet.bs.js");
var HomeUI$NimbusWeb = require("./HomeUI.bs.js");
var Lesson$NimbusWeb = require("../lesson/Lesson.bs.js");
var Storage$NimbusWeb = require("../../utils/Storage.bs.js");
var Messages$NimbusWeb = require("../messages/Messages.bs.js");
var EventView$NimbusWeb = require("../view/EventView.bs.js");
var Resources$NimbusWeb = require("../resources/Resources.bs.js");
var SSOSelect$NimbusWeb = require("../login/SSOSelect.bs.js");
var Navigation$NimbusWeb = require("../../utils/Navigation.bs.js");
var AuthContext$NimbusWeb = require("../../contexts/AuthContext.bs.js");
var EventFilter$NimbusWeb = require("../find/EventFilter.bs.js");
var UserContext$NimbusWeb = require("../../contexts/UserContext.bs.js");
var BrandContext$NimbusWeb = require("../../contexts/BrandContext.bs.js");
var ThemeContext$NimbusWeb = require("../../contexts/ThemeContext.bs.js");
var EventFilterUI$NimbusWeb = require("../find/EventFilterUI.bs.js");
var StudentContext$NimbusWeb = require("../../contexts/StudentContext.bs.js");

function findSearch(search, key) {
  var pair = search.find(function (pair) {
        return pair[0] === key;
      });
  if (pair !== undefined) {
    return Belt_Array.get(pair, 1);
  }
  
}

var pages = [{
    id: "login",
    label: "Sign In",
    toolbar: false
  }];

function Home(Props) {
  var path = Props.path;
  var searchOpt = Props.search;
  var login = Props.login;
  var token = Props.token;
  var userType = Props.userType;
  var search = searchOpt !== undefined ? searchOpt : [];
  var onClickLogIn = React.useCallback((function (param) {
          return Navigation$NimbusWeb.push("login");
        }), []);
  var match = React.useContext(BrandContext$NimbusWeb.context);
  var restoreBrandDefault = match[6];
  var match$1 = React.useContext(ThemeContext$NimbusWeb.context);
  var restoreColorDefault = match$1[4];
  var pages$1 = pages;
  React.useEffect((function () {
          if (path === "home" || path === "resources" || path === "login") {
            Curry._1(restoreBrandDefault, undefined);
            Curry._1(restoreColorDefault, undefined);
          }
          
        }), [path]);
  var tmp = path === "home" ? React.createElement(HomeUI$NimbusWeb.AppBar.make, {
          showMenu: true
        }) : (
      login ? React.createElement(HomeUI$NimbusWeb.AppBar.make, {
              showMenu: true
            }) : React.createElement(HomeUI$NimbusWeb.AppBar.make, {
              onClickLogIn: onClickLogIn,
              showMenu: true
            })
    );
  var tmp$1;
  switch (path) {
    case "find" :
        tmp$1 = React.createElement(EventFilter$NimbusWeb.make, {
              universityId: findSearch(search, "uniid")
            });
        break;
    case "login" :
        tmp$1 = React.createElement(Login$NimbusWeb.make, {
              token: token,
              userType: userType
            });
        break;
    case "resources" :
        tmp$1 = React.createElement(Resources$NimbusWeb.make, {});
        break;
    case "sso" :
        tmp$1 = React.createElement(SSOSelect$NimbusWeb.make, {});
        break;
    case "view" :
        tmp$1 = React.createElement(EventView$NimbusWeb.make, {
              dropInStudentsId: findSearch(search, "disid")
            });
        break;
    default:
      tmp$1 = React.createElement(HomeUI$NimbusWeb.ProsDisplay.make, {
            onClickLogIn: onClickLogIn,
            onClickFind: (function (param) {
                return Navigation$NimbusWeb.push("find");
              })
          });
  }
  return React.createElement(HomeUI$NimbusWeb.Container.make, {
              children: null,
              path: path,
              pages: pages$1
            }, tmp, React.createElement(HomeUI$NimbusWeb.Centered.make, {
                  children: tmp$1
                }));
}

var pages$1 = [
  {
    id: "lesson",
    label: "My Sessions",
    toolbar: true
  },
  {
    id: "find",
    label: "Search Courses",
    toolbar: true
  },
  {
    id: "resources",
    label: "Resources",
    toolbar: true
  },
  {
    id: "messages",
    label: "Messages",
    toolbar: true
  },
  {
    id: "logout",
    label: "Sign Out",
    toolbar: false
  }
];

function Home$Protected(Props) {
  var path = Props.path;
  var searchOpt = Props.search;
  var search = searchOpt !== undefined ? searchOpt : [];
  var match = React.useContext(AuthContext$NimbusWeb.Token.context);
  var setToken = match[1];
  var match$1 = React.useContext(UserContext$NimbusWeb.context);
  var userOption = match$1[0];
  var match$2 = React.useContext(StudentContext$NimbusWeb.context);
  var student = match$2[0];
  var match$3 = React.useContext(BrandContext$NimbusWeb.context);
  var restoreBrandDefault = match$3[6];
  var match$4 = React.useContext(ThemeContext$NimbusWeb.context);
  var restoreColorDefault = match$4[4];
  var onClickLogOut = function (param) {
    Storage$NimbusWeb.clear(undefined);
    Curry._1(setToken, (function (param) {
            return "";
          }));
    Curry._1(restoreBrandDefault, undefined);
    Curry._1(restoreColorDefault, undefined);
    Navigation$NimbusWeb.push("home");
    if (userOption === undefined) {
      return ;
    }
    var url = userOption.ssoSignOutLink;
    if (url !== undefined) {
      window.open(url, "_blank");
      return ;
    }
    
  };
  var match$5 = student !== undefined ? [
      student.university._id,
      Curry._1(EventFilterUI$NimbusWeb.Course.Table.createTableData, student.courses)
    ] : [
      undefined,
      []
    ];
  var universityId = match$5[0];
  var tmp;
  switch (path) {
    case "find" :
        tmp = React.createElement(HomeUI$NimbusWeb.Centered.make, {
              children: React.createElement(EventFilter$NimbusWeb.make, {
                    universityId: universityId,
                    key: Belt_Option.getWithDefault(universityId, "")
                  })
            });
        break;
    case "lesson" :
        tmp = React.createElement(Lesson$NimbusWeb.make, {
              id: findSearch(search, "l")
            });
        break;
    case "messages" :
        tmp = React.createElement(Messages$NimbusWeb.make, {});
        break;
    case "resources" :
        tmp = React.createElement(HomeUI$NimbusWeb.Centered.make, {
              children: React.createElement(Resources$NimbusWeb.make, {})
            });
        break;
    case "view" :
        tmp = React.createElement(HomeUI$NimbusWeb.Centered.make, {
              children: React.createElement(EventView$NimbusWeb.make, {
                    dropInStudentsId: findSearch(search, "disid"),
                    authenticated: true
                  })
            });
        break;
    default:
      tmp = React.createElement(Lesson$NimbusWeb.make, {
            id: findSearch(search, "l")
          });
  }
  return React.createElement(HomeUI$NimbusWeb.Container.make, {
              children: null,
              path: path,
              pages: pages$1
            }, React.createElement(Helmet$NimbusWeb.make, {
                  title: "Nimbus Learning - Home"
                }), React.createElement(HomeUI$NimbusWeb.AppBar.make, {
                  onClickLogOut: onClickLogOut,
                  showMenu: true
                }), tmp);
}

var Protected = {
  pages: pages$1,
  make: Home$Protected
};

var make = Home;

exports.findSearch = findSearch;
exports.pages = pages;
exports.make = make;
exports.Protected = Protected;
/* react Not a pure module */
