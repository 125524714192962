'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");

function make(sessionObjectId) {
  var variables = [{
      key: "sessionObjectId",
      val: sessionObjectId
    }];
  return Request$NimbusWeb.createBody("\n    query (\$sessionObjectId: ID) {\n      getAMeeting(sessionObjectId: \$sessionObjectId) {\n        start_url\n        join_url\n      }\n    }\n  ", variables);
}

function dMeeting(json) {
  return {
          startUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("start_url", Json_decode.string, param);
                }), json),
          joinUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("join_url", Json_decode.string, param);
                }), json)
        };
}

function dData(json) {
  return {
          meeting: Json_decode.optional((function (param) {
                  return Json_decode.field("getAMeeting", dMeeting, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", dData, param);
                }), json)
        };
}

var Decode = {
  dMeeting: dMeeting,
  dData: dData,
  exec: exec
};

function normalize(payload) {
  var data = payload.data;
  if (data !== undefined) {
    return data.meeting;
  }
  
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
