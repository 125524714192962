'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Date$NimbusWeb = require("../../../utils/Date.bs.js");

var fragmentUser = "\n  fragment UserFragment on User {\n    _id avatar first last uid uidSS\n  }\n";

var fragment = "\n  fragment LessonFragment on Lesson {\n    _id\n    price\n    status\n    online\n    location { name address }\n    onlineAttendeesCurrent { ...UserFragment }\n    group {\n      _id\n      studentMembers { student { _id user { ...UserFragment } } }\n      tutor { _id user { ...UserFragment } }\n    }\n    course { _id subject number }\n    current { startTime { timestamp } timeslots { _id } }\n    help { type description }\n    boardUrl\n  }\n  " + fragmentUser + "\n";

function user(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          first: Json_decode.field("first", Json_decode.string, json),
          last: Json_decode.field("last", Json_decode.string, json),
          avatar: Json_decode.optional((function (param) {
                  return Json_decode.field("avatar", Json_decode.string, param);
                }), json),
          uid: Json_decode.field("uid", Json_decode.$$int, json),
          uidSS: Json_decode.field("uidSS", Json_decode.$$int, json)
        };
}

function tutor(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          user: Json_decode.field("user", user, json)
        };
}

function student(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          user: Json_decode.field("user", user, json)
        };
}

function studentMember(json) {
  return {
          student: Json_decode.field("student", student, json)
        };
}

function studentMembers(json) {
  return Json_decode.array(studentMember, json);
}

function group(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          studentMembers: Json_decode.field("studentMembers", studentMembers, json),
          tutor: Json_decode.field("tutor", tutor, json)
        };
}

function timestamp(json) {
  return {
          timestamp: Json_decode.field("timestamp", Json_decode.string, json)
        };
}

function timeslot(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json)
        };
}

function timeslots(json) {
  return Json_decode.array(timeslot, json);
}

function dateTime(json) {
  return {
          startTime: Json_decode.field("startTime", timestamp, json),
          timeslots: Json_decode.field("timeslots", timeslots, json)
        };
}

function course(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          subject: Json_decode.field("subject", Json_decode.string, json),
          number: Json_decode.field("number", Json_decode.string, json)
        };
}

function help(json) {
  return {
          t: Json_decode.optional((function (param) {
                  return Json_decode.field("type", Json_decode.string, param);
                }), json),
          description: Json_decode.optional((function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json)
        };
}

function dLocation(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          address: Json_decode.field("address", Json_decode.string, json)
        };
}

function lesson(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          status: Json_decode.field("status", Json_decode.string, json),
          price: Json_decode.field("price", Json_decode.$$int, json),
          group: Json_decode.optional((function (param) {
                  return Json_decode.field("group", group, param);
                }), json),
          course: Json_decode.optional((function (param) {
                  return Json_decode.field("course", course, param);
                }), json),
          help: Json_decode.optional((function (param) {
                  return Json_decode.field("help", help, param);
                }), json),
          current: Json_decode.field("current", dateTime, json),
          onlineAttendeesCurrent: Json_decode.field("onlineAttendeesCurrent", (function (param) {
                  return Json_decode.array(user, param);
                }), json),
          boardUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("boardUrl", Json_decode.string, param);
                }), json),
          online: Json_decode.field("online", Json_decode.bool, json),
          location: Json_decode.optional((function (param) {
                  return Json_decode.field("location", dLocation, param);
                }), json)
        };
}

var Decode = {
  user: user,
  tutor: tutor,
  student: student,
  studentMember: studentMember,
  studentMembers: studentMembers,
  group: group,
  timestamp: timestamp,
  timeslot: timeslot,
  timeslots: timeslots,
  dateTime: dateTime,
  course: course,
  help: help,
  dLocation: dLocation,
  lesson: lesson
};

function normalize(lesson) {
  var date = Date$NimbusWeb.dateFromStringMS(lesson.current.startTime.timestamp);
  var tmp;
  if (date !== undefined) {
    var numTimeslots = lesson.current.timeslots.length;
    tmp = Caml_option.some(Date$NimbusWeb.$plus$plus$plus(Caml_option.valFromOption(date), numTimeslots * 1800000.0));
  } else {
    tmp = undefined;
  }
  return {
          _id: lesson._id,
          price: (lesson.price / 100.0).toFixed(2),
          status: lesson.status,
          groupId: Belt_Option.map(lesson.group, (function (group) {
                  return group._id;
                })),
          nameStudent: Belt_Option.flatMap(lesson.group, (function (group) {
                  var match = group.studentMembers;
                  if (match.length !== 1) {
                    return ;
                  }
                  var studentMember = match[0];
                  var first = studentMember.student.user.first;
                  var last = studentMember.student.user.last;
                  if (first === "" || last === "") {
                    return ;
                  } else {
                    return first + (" " + last);
                  }
                })),
          nameTutor: Belt_Option.flatMap(lesson.group, (function (group) {
                  var first = group.tutor.user.first;
                  var last = group.tutor.user.last;
                  if (first === "" || last === "") {
                    return ;
                  } else {
                    return first + (" " + last);
                  }
                })),
          avatarStudent: Belt_Option.flatMap(lesson.group, (function (group) {
                  var match = group.studentMembers;
                  if (match.length !== 1) {
                    return ;
                  }
                  var studentMember = match[0];
                  return studentMember.student.user.avatar;
                })),
          avatarTutor: Belt_Option.flatMap(lesson.group, (function (group) {
                  return group.tutor.user.avatar;
                })),
          onlineAttendeesCurrent: lesson.onlineAttendeesCurrent,
          uidTutor: Belt_Option.mapWithDefault(lesson.group, 1, (function (group) {
                  return group.tutor.user.uid;
                })),
          uidStudent: Belt_Option.mapWithDefault(lesson.group, 1, (function (group) {
                  var match = group.studentMembers;
                  if (match.length !== 1) {
                    return 1;
                  }
                  var studentMember = match[0];
                  return studentMember.student.user.uid;
                })),
          uidSSTutor: Belt_Option.mapWithDefault(lesson.group, 1, (function (group) {
                  return group.tutor.user.uidSS;
                })),
          uidSSStudent: Belt_Option.mapWithDefault(lesson.group, 1, (function (group) {
                  var match = group.studentMembers;
                  if (match.length !== 1) {
                    return 1;
                  }
                  var studentMember = match[0];
                  return studentMember.student.user.uid;
                })),
          studentId: Belt_Option.flatMap(lesson.group, (function (group) {
                  var match = group.studentMembers;
                  if (match.length !== 1) {
                    return ;
                  }
                  var studentMember = match[0];
                  return studentMember.student._id;
                })),
          tutorId: Belt_Option.map(lesson.group, (function (group) {
                  return group.tutor._id;
                })),
          course: Belt_Option.map(lesson.course, (function (course) {
                  return course.subject + (" " + course.number);
                })),
          helpType: Belt_Option.flatMap(lesson.help, (function (help) {
                  return help.t;
                })),
          helpDescription: Belt_Option.flatMap(lesson.help, (function (help) {
                  return help.description;
                })),
          startTime: Date$NimbusWeb.dateFromStringMS(lesson.current.startTime.timestamp),
          minutes: Math.imul(lesson.current.timeslots.length, 30),
          endTime: tmp,
          boardUrl: lesson.boardUrl,
          online: lesson.online,
          locationName: Belt_Option.map(lesson.location, (function ($$location) {
                  return $$location.name;
                })),
          locationAddress: Belt_Option.map(lesson.location, (function ($$location) {
                  return $$location.address;
                }))
        };
}

exports.fragmentUser = fragmentUser;
exports.fragment = fragment;
exports.Decode = Decode;
exports.normalize = normalize;
/* Date-NimbusWeb Not a pure module */
