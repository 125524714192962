'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_Book(Props) {
  var colorOpt = Props.color;
  var widthOpt = Props.width;
  var heightOpt = Props.height;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var width = widthOpt !== undefined ? widthOpt : "24";
  var height = heightOpt !== undefined ? heightOpt : "24";
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: height + "px",
              width: width + "px",
              version: "1.1",
              viewBox: "0 0 " + (width + (" " + height)),
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("title", undefined, "book"), React.createElement("defs", undefined, React.createElement("path", {
                      id: "path-book",
                      d: "M19,16 L19,3 L6.5,3 C5.67157288,3 5,3.67157288 5,4.5 L5,16.3368156 C5.45462769,16.1208455 5.96320135,16 6.5,16 L19,16 Z M19,18 L6.5,18 C5.67157288,18 5,18.6715729 5,19.5 C5,20.3284271 5.67157288,21 6.5,21 L19,21 L19,18 Z M6.5,1 L20,1 C20.5522847,1 21,1.44771525 21,2 L21,22 C21,22.5522847 20.5522847,23 20,23 L6.5,23 C4.56700338,23 3,21.4329966 3,19.5 L3,4.5 C3,2.56700338 4.56700338,1 6.5,1 Z"
                    })), React.createElement("g", {
                  id: "book",
                  fill: "none",
                  fillRule: "evenodd",
                  stroke: "none",
                  strokeWidth: "1"
                }, React.createElement("mask", {
                      id: "book-mask",
                      fill: "white"
                    }, React.createElement("use", {
                          xlinkHref: "#path-book"
                        })), React.createElement("use", {
                      id: "book-combined-shape",
                      fill: "#000000",
                      fillRule: "nonzero",
                      xlinkHref: "#path-book"
                    }), React.createElement("g", {
                      id: "book-black",
                      fill: c,
                      mask: "url(#book-mask)"
                    }, React.createElement("rect", {
                          id: "book-rect",
                          height: height,
                          width: width,
                          x: "0",
                          y: "0"
                        }))));
}

var make = Icon_Book;

exports.make = make;
/* react Not a pure module */
