'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Context$NimbusWeb = require("./Context.bs.js");
var Request$NimbusWeb = require("../utils/Request.bs.js");
var LessonRequest_Fetch$NimbusWeb = require("../scenes/lesson/requests/LessonRequest_Fetch.bs.js");
var LessonRequest_FetchById$NimbusWeb = require("../scenes/lesson/requests/LessonRequest_FetchById.bs.js");

function defaultValue_setLessons(param) {
  
}

function defaultValue_updateOne(param) {
  
}

function defaultValue_fetchById(param) {
  return Promise.resolve(undefined);
}

function defaultValue_fetchByIds(param) {
  return Promise.resolve(undefined);
}

var defaultValue = {
  lessons: undefined,
  setLessons: defaultValue_setLessons,
  updateOne: defaultValue_updateOne,
  fetchById: defaultValue_fetchById,
  fetchByIds: defaultValue_fetchByIds
};

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function LessonContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        
      });
  var setLessons = match[1];
  var request = Request$NimbusWeb.use(undefined);
  var updateOne = function (lesson) {
    return Curry._1(setLessons, (function (state) {
                  var newState = Belt_Option.mapWithDefault(state, [], (function (lessons) {
                          return lessons.filter(function (item) {
                                      return item._id !== lesson._id;
                                    });
                        }));
                  newState.push(lesson);
                  return newState;
                }));
  };
  var fetchById = function (lessonId) {
    return Curry._1(request, LessonRequest_FetchById$NimbusWeb.make(lessonId)).then(function (json) {
                return Promise.resolve(LessonRequest_FetchById$NimbusWeb.$$Response.normalize(Curry._1(LessonRequest_FetchById$NimbusWeb.$$Response.Decode.exec, json)));
              });
  };
  var fetchByIds = function (lessonIds) {
    return Curry._1(request, LessonRequest_Fetch$NimbusWeb.make(lessonIds)).then(function (json) {
                return Promise.resolve(LessonRequest_Fetch$NimbusWeb.$$Response.normalize(Curry._1(LessonRequest_Fetch$NimbusWeb.$$Response.Decode.exec, json)));
              });
  };
  var api_lessons = match[0];
  var api = {
    lessons: api_lessons,
    setLessons: setLessons,
    updateOne: updateOne,
    fetchById: fetchById,
    fetchByIds: fetchByIds
  };
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, api, children, undefined));
}

var Wrap = {
  make: LessonContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
