'use strict';

var React = require("react");
var ThemeContext$NimbusWeb = require("../contexts/ThemeContext.bs.js");

function getColor(c) {
  var match = React.useContext(ThemeContext$NimbusWeb.context);
  switch (c) {
    case /* Primary */0 :
        return match[0];
    case /* Secondary */1 :
        return match[1];
    case /* Error */2 :
        return "red";
    case /* Success */3 :
        return "#28cf25";
    case /* Placeholder */4 :
        return "light-gray";
    case /* BackgroundGray */5 :
        return "#f3f4f8";
    case /* BackgroundDark */6 :
        return "#363739";
    case /* Gray */7 :
    case /* Gray1 */8 :
        return "#6f6f6f";
    case /* Gray3 */9 :
        return "#828282";
    case /* White */10 :
        return "white";
    case /* Black */11 :
        return "black";
    case /* Transparent */12 :
        return "transparent";
    
  }
}

exports.getColor = getColor;
/* react Not a pure module */
