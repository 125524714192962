'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

var run = (require('@axe-core/react'));

var Axe = {
  run: run
};

var reactDOM = (require('react-dom'));

function run$1(app) {
  if (process.env.NODE_ENV !== "production") {
    return Curry._3(run, app, reactDOM, 1000);
  }
  
}

exports.Axe = Axe;
exports.reactDOM = reactDOM;
exports.run = run$1;
/* run Not a pure module */
