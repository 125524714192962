'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");

function make(param) {
  return Request$NimbusWeb.createBody("\n    {\n      universities: universityGetSSOProviders {\n        _id\n        name\n        photo\n        photoHome\n        ssoEndpoint\n        ssoSignOutLink\n        ssoEmailDomain\n      }\n    }\n  ", []);
}

function decodeUniversity(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          photo: Json_decode.optional((function (param) {
                  return Json_decode.field("photo", Json_decode.string, param);
                }), json),
          photoHome: Json_decode.optional((function (param) {
                  return Json_decode.field("photoHome", Json_decode.string, param);
                }), json),
          ssoEndpoint: Json_decode.optional((function (param) {
                  return Json_decode.field("ssoEndpoint", Json_decode.string, param);
                }), json),
          ssoSignOutLink: Json_decode.optional((function (param) {
                  return Json_decode.field("ssoSignOutLink", Json_decode.string, param);
                }), json),
          ssoEmailDomain: Json_decode.optional((function (param) {
                  return Json_decode.field("ssoEmailDomain", Json_decode.string, param);
                }), json)
        };
}

function decodeData(json) {
  return {
          universities: Json_decode.field("universities", (function (param) {
                  return Json_decode.array(decodeUniversity, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeUniversity: decodeUniversity,
  decodeData: decodeData,
  exec: exec
};

function normalize(payload) {
  return Belt_Option.map(payload.data, (function (data) {
                return data.universities;
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
