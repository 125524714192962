'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

var fragmentUser = "\n  fragment UserFragment on User {\n    _id\n    first\n    last\n    avatar\n    uid\n    uidSS\n  }\n";

var fragment = "\n  fragment DropInStudentsFragment on DropInStudents {\n    _id\n    dropIn { _id }\n    interestedStudents { ...UserFragment }\n    attendees { ...UserFragment }\n    attendeesCurrent { ...UserFragment }\n    boardUrl\n    status\n    groupId\n  }\n  " + fragmentUser + "\n";

function dUser(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          first: Json_decode.field("first", Json_decode.string, json),
          last: Json_decode.field("last", Json_decode.string, json),
          avatar: Json_decode.optional((function (param) {
                  return Json_decode.field("avatar", Json_decode.string, param);
                }), json),
          uid: Json_decode.field("uid", Json_decode.$$int, json),
          uidSS: Json_decode.field("uidSS", Json_decode.$$int, json)
        };
}

function dDropIn(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json)
        };
}

function dT(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          interestedStudents: Json_decode.field("interestedStudents", (function (param) {
                  return Json_decode.array(dUser, param);
                }), json),
          dropIn: Json_decode.field("dropIn", dDropIn, json),
          attendees: Json_decode.field("attendees", (function (param) {
                  return Json_decode.array(dUser, param);
                }), json),
          attendeesCurrent: Json_decode.field("attendeesCurrent", (function (param) {
                  return Json_decode.array(dUser, param);
                }), json),
          status: Json_decode.field("status", Json_decode.string, json),
          boardUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("boardUrl", Json_decode.string, param);
                }), json),
          groupId: Json_decode.field("groupId", Json_decode.string, json)
        };
}

var Decode = {
  dUser: dUser,
  dDropIn: dDropIn,
  dT: dT
};

function normalize(dropInStudents) {
  return {
          _id: dropInStudents._id,
          dropInId: dropInStudents.dropIn._id,
          interestedStudents: dropInStudents.interestedStudents,
          attendees: dropInStudents.attendees,
          attendeesCurrent: dropInStudents.attendeesCurrent,
          status: dropInStudents.status,
          boardUrl: dropInStudents.boardUrl,
          groupId: dropInStudents.groupId
        };
}

exports.fragmentUser = fragmentUser;
exports.fragment = fragment;
exports.Decode = Decode;
exports.normalize = normalize;
/* No side effect */
