'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");

function make(email) {
  var variables = [{
      key: "email",
      val: email
    }];
  return Request$NimbusWeb.createBody("\n    mutation forgotPassword($email: String!) {\n      forgotPassword(email: $email)\n    }\n  ", variables);
}

function data(json) {
  return {
          forgotPassword: Json_decode.optional((function (param) {
                  return Json_decode.field("forgotPassword", Json_decode.string, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", data, param);
                }), json)
        };
}

var Decode = {
  data: data,
  exec: exec
};

function normalize(payload) {
  var data = payload.data;
  if (data === undefined) {
    return ;
  }
  var str = data.forgotPassword;
  if (str === "email sent") {
    return str;
  }
  
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
