'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var DropInStudentsResponse$NimbusWeb = require("./DropInStudentsResponse.bs.js");

function make(dropInStudentsId) {
  var query = "\n    query (\$dropInStudentsId: ID!) {\n      fetchDropInStudentsById(dropInStudentsId: \$dropInStudentsId) {\n        ...DropInStudentsFragment\n      }\n    }\n    " + DropInStudentsResponse$NimbusWeb.fragment + "\n  ";
  var variables = [{
      key: "dropInStudentsId",
      val: dropInStudentsId
    }];
  return Request$NimbusWeb.createBody(query, variables);
}

function dData(json) {
  return {
          dropInStudent: Json_decode.optional((function (param) {
                  return Json_decode.field("fetchDropInStudentsById", DropInStudentsResponse$NimbusWeb.Decode.dT, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", dData, param);
                }), json)
        };
}

var Decode = {
  dData: dData,
  exec: exec
};

function normalize(payload) {
  return Belt_Option.flatMap(payload.data, (function (data) {
                return Belt_Option.map(data.dropInStudent, DropInStudentsResponse$NimbusWeb.normalize);
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
