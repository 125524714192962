'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var Sidebar$NimbusWeb = require("../../components/Sidebar.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var AppBarLogin$NimbusWeb = require("../login/AppBarLogin.bs.js");
var LocaleContext$NimbusWeb = require("../../contexts/LocaleContext.bs.js");

require("./Home.css");

function HomeUI$Container(Props) {
  var children = Props.children;
  var path = Props.path;
  var pages = Props.pages;
  return React.createElement(Sidebar$NimbusWeb.Wrap.make, {
              children: React.createElement("div", {
                    className: "home-container"
                  }, children),
              toolbar: 65,
              pages: pages,
              path: path
            });
}

var Container = {
  make: HomeUI$Container
};

function HomeUI$Centered(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "home-centered home-centered-container home-section-container"
            }, children);
}

var Centered = {
  make: HomeUI$Centered
};

function HomeUI$AppBar(Props) {
  var onClickLogIn = Props.onClickLogIn;
  var onClickLogOut = Props.onClickLogOut;
  var showMenuOpt = Props.showMenu;
  var showMenu = showMenuOpt !== undefined ? showMenuOpt : false;
  var tmp = {
    showMenu: showMenu
  };
  if (onClickLogIn !== undefined) {
    tmp.onClickLogIn = Caml_option.valFromOption(onClickLogIn);
  }
  if (onClickLogOut !== undefined) {
    tmp.onClickLogOut = Caml_option.valFromOption(onClickLogOut);
  }
  return React.createElement("div", undefined, React.createElement(AppBarLogin$NimbusWeb.make, tmp));
}

var AppBar = {
  make: HomeUI$AppBar
};

var online = require("@assets/online_tutoring.png").default;

var screenShare = require("@assets/sharescreen.png").default;

var whiteboard = require("@assets/whiteboard.png").default;

var Icons = {
  online: online,
  screenShare: screenShare,
  whiteboard: whiteboard
};

function HomeUI$ProsDisplay$Row(Props) {
  var icon = Props.icon;
  var text = Props.text;
  return React.createElement("div", {
              className: "home-grid-row"
            }, React.createElement("div", {
                  className: "home-image-container"
                }, icon), React.createElement(Typography$NimbusWeb.make, {
                  color: /* Primary */0,
                  className: "home-image-text",
                  value: text
                }));
}

var Row = {
  make: HomeUI$ProsDisplay$Row
};

function HomeUI$ProsDisplay(Props) {
  var onClickLogIn = Props.onClickLogIn;
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  var text = match[0];
  var rows = [
    {
      icon: online,
      width: 82,
      height: 58,
      text: "online_tutoring"
    },
    {
      icon: screenShare,
      width: 66,
      height: 45,
      text: "share_screen"
    },
    {
      icon: whiteboard,
      width: 74,
      height: 47,
      text: "whiteboard"
    }
  ];
  return React.createElement("div", {
              className: "home-centered home-padding"
            }, React.createElement(Typography$NimbusWeb.make, {
                  variant: /* H1 */0,
                  color: /* Secondary */1,
                  className: "home-promo-text",
                  value: Curry._3(text, "login.promo", undefined, undefined)
                }), React.createElement("div", {
                  className: "home-grid"
                }, Belt_Array.map(rows, (function (row) {
                        return React.createElement(HomeUI$ProsDisplay$Row, {
                                    icon: React.createElement("img", {
                                          className: "home-image",
                                          alt: row.text,
                                          height: String(row.height) + "px",
                                          src: row.icon,
                                          width: String(row.width) + "px"
                                        }),
                                    text: Curry._3(text, "login." + row.text, undefined, undefined),
                                    key: row.text
                                  });
                      }))), React.createElement("div", {
                  className: "home-button-container"
                }, React.createElement(Button$NimbusWeb.make, {
                      variant: /* Contained */1,
                      onClick: onClickLogIn,
                      text: "Sign In",
                      accessKey: "s"
                    })));
}

var ProsDisplay = {
  Icons: Icons,
  Row: Row,
  make: HomeUI$ProsDisplay
};

exports.Container = Container;
exports.Centered = Centered;
exports.AppBar = AppBar;
exports.ProsDisplay = ProsDisplay;
/*  Not a pure module */
