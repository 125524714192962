'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Button$NimbusWeb = require("./Button.bs.js");
var CopyToClipboard = require("copy-to-clipboard");
var Typography$NimbusWeb = require("./Typography.bs.js");
var Icon_FileCopy$NimbusWeb = require("../icons/Icon_FileCopy.bs.js");
var LocaleContext$NimbusWeb = require("../contexts/LocaleContext.bs.js");

var Copy = {};

function CopyToClipboard$1(Props) {
  var classNameOpt = Props.className;
  var url = Props.url;
  var bgColorOpt = Props.bgColor;
  var onCopy = Props.onCopy;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var bgColor = bgColorOpt !== undefined ? bgColorOpt : /* Primary */0;
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  var text = match[0];
  return React.createElement("div", {
              className: className + " button-general",
              style: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }
            }, React.createElement(Button$NimbusWeb.Icon.make, {
                  onClick: (function (param) {
                      CopyToClipboard(url, {
                            debug: process.env.REACT_APP_CONTEXT === "development",
                            message: undefined,
                            format: undefined,
                            onCopy: onCopy
                          });
                      
                    }),
                  bgColor: bgColor,
                  title: Curry._3(text, "copy_event.button.title", undefined, undefined),
                  children: React.createElement(Icon_FileCopy$NimbusWeb.make, {
                        color: /* White */10
                      }),
                  ariaLabel: "copy to clipboard",
                  accessKey: "c"
                }), React.createElement(Typography$NimbusWeb.make, {
                  color: /* Primary */0,
                  className: "text-size12",
                  value: Curry._3(text, "copy_event.button.label", undefined, undefined)
                }));
}

var make = CopyToClipboard$1;

exports.Copy = Copy;
exports.make = make;
/* react Not a pure module */
