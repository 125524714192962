'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Badge$NimbusWeb = require("./Badge.bs.js");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");
var Typography$NimbusWeb = require("./Typography.bs.js");

require("./Button.css");

function Button(Props) {
  var variantOpt = Props.variant;
  var colorOpt = Props.color;
  var classNameOpt = Props.className;
  var onClickOpt = Props.onClick;
  var disabledOpt = Props.disabled;
  var title = Props.title;
  var type_ = Props.type_;
  var text = Props.text;
  var accessKey = Props.accessKey;
  var variant = variantOpt !== undefined ? variantOpt : /* Default */0;
  var color = colorOpt !== undefined ? colorOpt : /* Primary */0;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        
      });
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match;
  switch (variant) {
    case /* Default */0 :
        match = [
          "button-default",
          {},
          color,
          "button-default-text"
        ];
        break;
    case /* Contained */1 :
        match = [
          "button-contained",
          {
            backgroundColor: Theme$NimbusWeb.getColor(color)
          },
          /* White */10,
          "button-contained-text"
        ];
        break;
    case /* Outlined */2 :
        match = [
          "button-outlined",
          {
            borderColor: Theme$NimbusWeb.getColor(color)
          },
          color,
          "button-outlined-text"
        ];
        break;
    
  }
  var cn = "button-general button-animation " + (match[0] + ((
        disabled ? " button-disabled " : " "
      ) + className));
  var tmp = {
    accessKey: accessKey,
    className: cn,
    style: match[1],
    tabIndex: 0,
    disabled: disabled,
    onClick: onClick
  };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  if (type_ !== undefined) {
    tmp.type = Caml_option.valFromOption(type_);
  }
  return React.createElement("button", tmp, React.createElement(Typography$NimbusWeb.make, {
                  color: match[2],
                  className: match[3],
                  value: text
                }));
}

function Button$Icon(Props) {
  var classNameOpt = Props.className;
  var disabledOpt = Props.disabled;
  var onClickOpt = Props.onClick;
  var bgColorOpt = Props.bgColor;
  var sizeOpt = Props.size;
  var shadowOpt = Props.shadow;
  var type_ = Props.type_;
  var title = Props.title;
  var children = Props.children;
  var hoverOpt = Props.hover;
  var showBadgeOpt = Props.showBadge;
  var ariaLabel = Props.ariaLabel;
  var accessKey = Props.accessKey;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        
      });
  var bgColor = bgColorOpt !== undefined ? bgColorOpt : /* Transparent */12;
  var size = sizeOpt !== undefined ? sizeOpt : /* Medium */1;
  var shadow = shadowOpt !== undefined ? shadowOpt : false;
  var hover = hoverOpt !== undefined ? hoverOpt : true;
  var showBadge = showBadgeOpt !== undefined ? showBadgeOpt : false;
  var s;
  switch (size) {
    case /* Small */0 :
        s = "40px";
        break;
    case /* Medium */1 :
        s = "50px";
        break;
    case /* Large */2 :
        s = "60px";
        break;
    
  }
  var style = {
    backgroundColor: Theme$NimbusWeb.getColor(bgColor),
    height: s,
    width: s,
    boxShadow: shadow ? "0px 0px 10px gray" : "none"
  };
  var cn = "button-general " + (
    hover ? "button-animation " : ""
  ) + ("button-icon-container " + ((
        disabled ? "button-disabled " : ""
      ) + className));
  var tmp = {
    "aria-label": ariaLabel,
    accessKey: accessKey,
    className: cn,
    style: style,
    tabIndex: 0,
    disabled: disabled,
    onClick: onClick
  };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  if (type_ !== undefined) {
    tmp.type = Caml_option.valFromOption(type_);
  }
  return React.createElement("button", tmp, children, React.createElement("div", {
                  className: "button-badge-container"
                }, React.createElement(Badge$NimbusWeb.make, {
                      visible: showBadge
                    })));
}

var Icon = {
  make: Button$Icon
};

var make = Button;

exports.make = make;
exports.Icon = Icon;
/*  Not a pure module */
