'use strict';

var React = require("react");
var Icon_User$NimbusWeb = require("../icons/Icon_User.bs.js");

function Avatar(Props) {
  var classNameOpt = Props.className;
  var src = Props.src;
  var alt = Props.alt;
  var sizeOpt = Props.size;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var size = sizeOpt !== undefined ? sizeOpt : /* Medium */1;
  var size$1;
  switch (size) {
    case /* Small */0 :
        size$1 = 40;
        break;
    case /* Medium */1 :
        size$1 = 62;
        break;
    case /* Large */2 :
        size$1 = 74;
        break;
    
  }
  var style = {
    backgroundColor: "#ddd",
    display: "flex",
    height: String(size$1) + "px",
    width: String(size$1) + "px",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center"
  };
  if (src !== undefined && src !== "") {
    return React.createElement("img", {
                className: className,
                style: style,
                alt: alt,
                src: src
              });
  }
  return React.createElement("div", {
              className: className,
              style: style
            }, React.createElement(Icon_User$NimbusWeb.make, {
                  color: /* White */10
                }));
}

var make = Avatar;

exports.make = make;
/* react Not a pure module */
