'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Context$NimbusWeb = require("./Context.bs.js");

var defaultValue_0 = [];

function defaultValue_1(param) {
  
}

function defaultValue_3(param) {
  
}

var defaultValue = [
  defaultValue_0,
  defaultValue_1,
  undefined,
  defaultValue_3
];

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function EventsContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return [];
      });
  var match$1 = React.useState(function () {
        
      });
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, [
                  match[0],
                  match[1],
                  match$1[0],
                  match$1[1]
                ], children, undefined));
}

var Wrap = {
  make: EventsContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
