'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

function _getKey(k) {
  switch (k) {
    case /* Token */0 :
        return "token";
    case /* Type */1 :
        return "type";
    case /* Course */2 :
        return "courseIds";
    case /* DropInStudents */3 :
        return "dropInStudentsId";
    case /* CalendarEvent */4 :
        return "calendarEvent";
    
  }
}

function get(k) {
  return Caml_option.nullable_to_opt(window.localStorage.getItem(_getKey(k)));
}

function set(k, v) {
  window.localStorage.setItem(_getKey(k), v);
  
}

function clear(param) {
  window.localStorage.clear();
  
}

exports._getKey = _getKey;
exports.get = get;
exports.set = set;
exports.clear = clear;
/* No side effect */
