'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Date$NimbusWeb = require("../../utils/Date.bs.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var LocaleContext$NimbusWeb = require("../../contexts/LocaleContext.bs.js");

var isTesting = process.env.REACT_APP_CONTEXT !== "production";

function LessonStartButton(Props) {
  var startTime = Props.startTime;
  var onClickOpt = Props.onClick;
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        
      });
  var match = React.useState(function () {
        return Date.now();
      });
  var setCurrentTime = match[1];
  var match$1 = React.useContext(LocaleContext$NimbusWeb.context);
  var text = match$1[0];
  React.useEffect((function () {
          var id = setInterval((function (param) {
                  return Curry._1(setCurrentTime, (function (param) {
                                return Date.now();
                              }));
                }), 1000);
          return (function (param) {
                    clearInterval(id);
                    
                  });
        }), []);
  var match$2;
  if (startTime !== undefined) {
    var s = Caml_option.valFromOption(startTime).getTime();
    var diff = s - match[0];
    match$2 = diff > 0 && diff >= 1000 * 60 * 15 ? [
        true,
        diff
      ] : [
        false,
        diff
      ];
  } else {
    match$2 = [
      true,
      0
    ];
  }
  var timeLeft = match$2[1];
  var after = timeLeft < 0;
  var text$1 = after ? Curry._3(text, "lesson.button.start", undefined, undefined) : Curry._3(text, "lesson.button.start_in", [[
            "time",
            Date$NimbusWeb.timerText(timeLeft, Curry._3(text, "lesson.daysLabel", undefined, undefined))
          ]], undefined);
  return React.createElement(Button$NimbusWeb.make, {
              variant: /* Contained */1,
              className: "lesson-start-button",
              onClick: onClick,
              text: text$1,
              accessKey: "s"
            });
}

var make = LessonStartButton;

exports.isTesting = isTesting;
exports.make = make;
/* isTesting Not a pure module */
