'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");
var Typography$NimbusWeb = require("./Typography.bs.js");
var Icon_Cancel$NimbusWeb = require("../icons/Icon_Cancel.bs.js");

function Chip(Props) {
  var text = Props.text;
  var onRemove = Props.onRemove;
  return React.createElement("div", {
              style: {
                backgroundColor: Theme$NimbusWeb.getColor(/* Primary */0),
                display: "flex",
                padding: "8px",
                borderRadius: "500px",
                alignItems: "center"
              }
            }, React.createElement(Typography$NimbusWeb.make, {
                  color: /* White */10,
                  className: "text-size14",
                  value: text
                }), onRemove !== undefined ? React.createElement("div", {
                    style: {
                      display: "flex",
                      marginLeft: "4px",
                      alignItems: "center"
                    },
                    onClick: onRemove
                  }, React.createElement(Icon_Cancel$NimbusWeb.make, {
                        color: /* BackgroundGray */5
                      })) : null);
}

var make = Chip;

exports.make = make;
/* react Not a pure module */
