'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var OrganizationResponse$NimbusWeb = require("../../requests/OrganizationResponse.bs.js");

function make(param) {
  var query = "\n    query {\n      getUser {\n        _id\n        first\n        last\n        locale\n        ssoSignOutLink\n        uid\n        uidSS\n        organization {\n          ...OrganizationThemeFragment\n        }\n      }\n    }\n    " + OrganizationResponse$NimbusWeb.fragment + "\n  ";
  return Request$NimbusWeb.createBody(query, []);
}

function decodeUser(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          first: Json_decode.field("first", Json_decode.string, json),
          last: Json_decode.field("last", Json_decode.string, json),
          locale: Json_decode.optional((function (param) {
                  return Json_decode.field("locale", Json_decode.string, param);
                }), json),
          ssoSignOutLink: Json_decode.optional((function (param) {
                  return Json_decode.field("ssoSignOutLink", Json_decode.string, param);
                }), json),
          uid: Json_decode.field("uid", Json_decode.$$int, json),
          uidSS: Json_decode.field("uidSS", Json_decode.$$int, json),
          organization: Json_decode.optional((function (param) {
                  return Json_decode.field("organization", OrganizationResponse$NimbusWeb.Decode.dT, param);
                }), json)
        };
}

function decodeData(json) {
  return {
          user: Json_decode.optional((function (param) {
                  return Json_decode.field("getUser", decodeUser, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeUser: decodeUser,
  decodeData: decodeData,
  exec: exec
};

function normalize(payload) {
  var $$default = {
    _id: "",
    name: "Unknown User",
    locale: undefined,
    ssoSignOutLink: undefined,
    uid: -1,
    uidSS: -1,
    organization: undefined
  };
  var data = payload.data;
  if (data === undefined) {
    return $$default;
  }
  var user = data.user;
  if (user !== undefined) {
    return {
            _id: user._id,
            name: user.first + (" " + user.last),
            locale: user.locale,
            ssoSignOutLink: user.ssoSignOutLink,
            uid: user.uid,
            uidSS: user.uidSS,
            organization: user.organization
          };
  } else {
    return $$default;
  }
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
