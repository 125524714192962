'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var LoginResponse$NimbusWeb = require("./LoginResponse.bs.js");

function make(token) {
  var query = "\n    mutation validateToken(\$token: String!) {\n      validateToken(token: \$token) {\n        ...TokenFragment\n      }\n    }\n    " + LoginResponse$NimbusWeb.fragment + "\n  ";
  var variables = [{
      key: "token",
      val: token
    }];
  return Request$NimbusWeb.createBody(query, variables);
}

function data(json) {
  return {
          validateToken: Json_decode.field("validateToken", LoginResponse$NimbusWeb.Decode.token, json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", data, param);
                }), json)
        };
}

var Decode = {
  data: data,
  exec: exec
};

function normalize(payload) {
  var data = payload.data;
  if (data !== undefined) {
    return LoginResponse$NimbusWeb.normalize(data.validateToken);
  } else {
    return {
            token: undefined,
            userType: undefined
          };
  }
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
