'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var RemarkSlug = require("remark-slug").default;
var ReactMarkdown = require("react-markdown").default;
var Button$NimbusWeb = require("../../components/Button.bs.js");
var Icon_Menu$NimbusWeb = require("../../icons/Icon_Menu.bs.js");
var Icon_Close$NimbusWeb = require("../../icons/Icon_Close.bs.js");
var ResourcesToc$NimbusWeb = require("./ResourcesToc.bs.js");

require("./Resources.css");

var bestPracticesMd = require("@assets/resources/best_practices.md").default;

var faqsMd = require("@assets/resources/faqs.md").default;

var jobsMd = require("@assets/resources/jobs.md").default;

function getHeadings(param) {
  var contentElement = document.querySelector("#resources-content");
  return Array.from(contentElement.querySelectorAll("h1"));
}

function Resources(Props) {
  var match = React.useState(function () {
        return "";
      });
  var setContent = match[1];
  var content = match[0];
  var match$1 = React.useState(function () {
        return [];
      });
  var setHeaders = match$1[1];
  var headers = match$1[0];
  var match$2 = React.useState(function () {
        return [];
      });
  var setHeadings = match$2[1];
  var headings = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowMobile = match$3[1];
  var showMobile = match$3[0];
  var match$4 = React.useState(function () {
        return window.innerWidth;
      });
  var setWindowWidth = match$4[1];
  React.useEffect((function () {
          Promise.all([
                    fetch(bestPracticesMd),
                    fetch(faqsMd),
                    fetch(jobsMd)
                  ]).then(function (param) {
                  return Promise.all([
                              param[0].text(),
                              param[1].text(),
                              param[2].text()
                            ]);
                }).then(function (param) {
                var result = param[0] + "\n\n---\n\n" + param[1] + "\n\n---\n\n" + param[2];
                Curry._1(setContent, (function (param) {
                        return result;
                      }));
                return Promise.resolve(undefined);
              });
          var handleWindowResize = function (param) {
            return Curry._1(setWindowWidth, (function (param) {
                          return window.innerWidth;
                        }));
          };
          window.addEventListener("resize", handleWindowResize);
          return (function (param) {
                    window.removeEventListener("resize", handleWindowResize);
                    
                  });
        }), []);
  React.useEffect((function () {
          var headings = getHeadings(undefined);
          var headers = ResourcesToc$NimbusWeb.generateLinkMarkup(headings);
          Curry._1(setHeaders, (function (param) {
                  return headers;
                }));
          Curry._1(setHeadings, (function (param) {
                  return headings;
                }));
          
        }), [content]);
  React.useEffect((function () {
          var tocElement = document.querySelector("#resources-toc-container");
          var links = Array.from(tocElement.querySelectorAll("a"));
          var observer = ResourcesToc$NimbusWeb.createObserver(links);
          headings.forEach(function (heading) {
                observer.observe(heading);
                
              });
          
        }), [
        headers,
        headings
      ]);
  var showToc = showMobile || match$4[0] > 700;
  return React.createElement("div", {
              className: "resources-container"
            }, React.createElement(ResourcesToc$NimbusWeb.make, {
                  headers: headers,
                  style: {
                    display: showToc ? "inline-block" : "none"
                  }
                }), React.createElement("div", {
                  id: "resources-content"
                }, React.createElement(ReactMarkdown, {
                      children: content,
                      remarkPlugins: [RemarkSlug]
                    }), React.createElement("div", {
                      className: "resources-footer"
                    })), React.createElement(Button$NimbusWeb.Icon.make, {
                  className: "resources-toc-menu",
                  onClick: (function (param) {
                      return Curry._1(setShowMobile, (function (s) {
                                    return !s;
                                  }));
                    }),
                  bgColor: /* Primary */0,
                  children: showMobile ? React.createElement(Icon_Close$NimbusWeb.make, {
                          color: /* White */10
                        }) : React.createElement(Icon_Menu$NimbusWeb.make, {}),
                  ariaLabel: "Toggle table of contents",
                  accessKey: "z"
                }));
}

var make = Resources;

exports.bestPracticesMd = bestPracticesMd;
exports.faqsMd = faqsMd;
exports.jobsMd = jobsMd;
exports.getHeadings = getHeadings;
exports.make = make;
/*  Not a pure module */
