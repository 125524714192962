'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

require("./Loader.css");

function Loader$Circular(Props) {
  var classNameOpt = Props.className;
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var backgroundColorOpt = Props.backgroundColor;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var size = sizeOpt !== undefined ? sizeOpt : /* Medium */1;
  var color = colorOpt !== undefined ? colorOpt : /* Primary */0;
  var backgroundColor = backgroundColorOpt !== undefined ? backgroundColorOpt : /* White */10;
  var s;
  switch (size) {
    case /* Small */0 :
        s = "scale(0.25)";
        break;
    case /* Medium */1 :
        s = "scale(0.5)";
        break;
    case /* Large */2 :
        s = "scale(1)";
        break;
    
  }
  var c = Theme$NimbusWeb.getColor(color);
  var bc = Theme$NimbusWeb.getColor(backgroundColor);
  React.useEffect((function () {
          document.documentElement.style.setProperty("--color-loader-fg", c);
          document.documentElement.style.setProperty("--color-loader-bg", bc);
          
        }), [c]);
  return React.createElement("div", {
              className: "loader " + className,
              style: {
                transform: s
              }
            });
}

function Loader$Circular$Simple(Props) {
  var classNameOpt = Props.className;
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var backgroundColorOpt = Props.backgroundColor;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var size = sizeOpt !== undefined ? sizeOpt : /* Medium */1;
  var color = colorOpt !== undefined ? colorOpt : /* Primary */0;
  var backgroundColor = backgroundColorOpt !== undefined ? backgroundColorOpt : /* White */10;
  var s;
  switch (size) {
    case /* Small */0 :
        s = "40px";
        break;
    case /* Medium */1 :
        s = "50px";
        break;
    case /* Large */2 :
        s = "60px";
        break;
    
  }
  var c = Theme$NimbusWeb.getColor(color);
  var bc = Theme$NimbusWeb.getColor(backgroundColor);
  return React.createElement("div", {
              className: "loader-circular-simple " + className,
              style: {
                border: "4px solid " + bc,
                borderTop: "4px solid " + c,
                height: s,
                width: s
              }
            });
}

var Simple = {
  make: Loader$Circular$Simple
};

var Circular = {
  make: Loader$Circular,
  Simple: Simple
};

exports.Circular = Circular;
/*  Not a pure module */
