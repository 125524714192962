'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Context$NimbusWeb = require("./Context.bs.js");

var defaultPrimary = "#e13023";

var defaultSecondary = "#1e7cae";

function defaultValue_2(param) {
  
}

function defaultValue_3(param) {
  
}

function defaultValue_4(param) {
  
}

var defaultValue = [
  defaultPrimary,
  defaultSecondary,
  defaultValue_2,
  defaultValue_3,
  defaultValue_4
];

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function ThemeContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return defaultPrimary;
      });
  var setPrimary = match[1];
  var primary = match[0];
  var match$1 = React.useState(function () {
        return defaultSecondary;
      });
  var setSecondary = match$1[1];
  var secondary = match$1[0];
  var restoreDefault = function (param) {
    Curry._1(setPrimary, (function (param) {
            return defaultPrimary;
          }));
    return Curry._1(setSecondary, (function (param) {
                  return defaultSecondary;
                }));
  };
  React.useEffect((function () {
          document.documentElement.style.setProperty("--color-primary", primary);
          
        }), [primary]);
  React.useEffect((function () {
          document.documentElement.style.setProperty("--color-secondary", secondary);
          
        }), [secondary]);
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, [
                  primary,
                  secondary,
                  setPrimary,
                  setSecondary,
                  restoreDefault
                ], children, undefined));
}

var Wrap = {
  make: ThemeContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.defaultPrimary = defaultPrimary;
exports.defaultSecondary = defaultSecondary;
exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
