'use strict';

var React = require("react");

function Icon_Pin(Props) {
  return React.createElement("svg", {
              height: "24",
              width: "24",
              viewBox: "0 96 960 960",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M480 576q33 0 56.5-23.5T560 496q0-33-23.5-56.5T480 416q-33 0-56.5 23.5T400 496q0 33 23.5 56.5T480 576Zm0 294q122-112 181-203.5T720 504q0-109-69.5-178.5T480 256q-101 0-170.5 69.5T240 504q0 71 59 162.5T480 870Zm0 106Q319 839 239.5 721.5T160 504q0-150 96.5-239T480 176q127 0 223.5 89T800 504q0 100-79.5 217.5T480 976Zm0-472Z"
                }));
}

var make = Icon_Pin;

exports.make = make;
/* react Not a pure module */
