'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

var Media = {};

var ClassList = {};

var $$Window = {};

var $$Location = {};

function setRef(reactRef) {
  return function (elem) {
    reactRef.current = (elem == null) ? undefined : Caml_option.some(elem);
    
  };
}

function getLast(arr) {
  return Belt_Array.get(arr, arr.length - 1 | 0);
}

function removeHttps(url) {
  if (url.startsWith("https:")) {
    return url.substr(6);
  } else if (url.startsWith("http:")) {
    return url.substr(5);
  } else {
    return url;
  }
}

function formatSearchString(query) {
  if (query.length === 0) {
    return ;
  } else {
    return Belt_Array.map(query, (function (param) {
                    return "" + param.key + "=" + param.value;
                  })).join("&");
  }
}

function make(protocolOpt, domainOpt, pathOpt, queryOpt, param) {
  var protocol = protocolOpt !== undefined ? protocolOpt : "https";
  var domain = domainOpt !== undefined ? domainOpt : "example.com";
  var path = pathOpt !== undefined ? pathOpt : "";
  var query = queryOpt !== undefined ? queryOpt : [];
  var searchString = formatSearchString(query);
  return protocol + ("://" + (domain + ((
                path === "" ? "" : "/"
              ) + (path + Belt_Option.mapWithDefault(searchString, "", (function (searchString) {
                        return "?" + searchString;
                      }))))));
}

var Url = {
  formatSearchString: formatSearchString,
  make: make
};

exports.Media = Media;
exports.ClassList = ClassList;
exports.$$Window = $$Window;
exports.$$Location = $$Location;
exports.setRef = setRef;
exports.getLast = getLast;
exports.removeHttps = removeHttps;
exports.Url = Url;
/* No side effect */
