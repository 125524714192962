'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var ReactDom = require("react-dom");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Alert$NimbusWeb = require("../../components/Alert.bs.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var EventCard$NimbusWeb = require("../lesson/EventCard.bs.js");
var EventResponse$NimbusWeb = require("../lesson/requests/EventResponse.bs.js");
var LocaleContext$NimbusWeb = require("../../contexts/LocaleContext.bs.js");

require("./EventView.css");

function EventViewUI$Container(Props) {
  var children = Props.children;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "event-filter-spacing"
                }), React.createElement("div", {
                  className: "event-view-container"
                }, React.createElement("div", {
                      className: "event-view-subcontainer"
                    }, children)));
}

var Container = {
  make: EventViewUI$Container
};

var root = document.getElementById("root");

function EventViewUI$EventPopup(Props) {
  var $$event = Props.event;
  var dropIn = Props.dropIn;
  var dropInStudent = Props.dropInStudent;
  var onClose = Props.onClose;
  var onClickJoin = Props.onClickJoin;
  var buttonText = Props.buttonText;
  var userIdOpt = Props.userId;
  var userId = userIdOpt !== undefined ? Caml_option.valFromOption(userIdOpt) : undefined;
  var eventJson = EventResponse$NimbusWeb.parseCalendarEvent($$event);
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  var text = match[0];
  var default_1 = [];
  var $$default = [
    0,
    default_1
  ];
  var value = Js_json.classify(eventJson);
  var match$1;
  if (typeof value === "number" || value.TAG !== /* JSONObject */2) {
    console.log("Expected Json object");
    match$1 = $$default;
  } else {
    var value$1 = value._0;
    var match$2 = Js_dict.get(value$1, "minutes");
    var match$3 = Js_dict.get(value$1, "courses");
    if (match$2 !== undefined && match$3 !== undefined) {
      var match$4 = Js_json.decodeNumber(Caml_option.valFromOption(match$2));
      var match$5 = Js_json.decodeArray(Caml_option.valFromOption(match$3));
      if (match$4 !== undefined && match$5 !== undefined) {
        var courses = match$5.map(function (course) {
              var course$1 = Js_json.decodeObject(course);
              if (course$1 === undefined) {
                return "Invalid Course";
              }
              var course$2 = Caml_option.valFromOption(course$1);
              var subject = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(course$2, "subject"), Js_json.decodeString), "Subject");
              var number = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(course$2, "number"), Js_json.decodeString), "Number");
              return "" + subject + " " + number;
            });
        match$1 = [
          match$4 | 0,
          courses
        ];
      } else {
        match$1 = $$default;
      }
    } else {
      match$1 = $$default;
    }
  }
  var isEnrolled = Belt_Option.mapWithDefault(userId, false, (function (id) {
          return dropInStudent.interestedStudents.findIndex(function (user) {
                      return id === user._id;
                    }) !== -1;
        }));
  var match$6 = dropIn.accessLevel;
  var tmp;
  tmp = match$6 === "PUBLIC" ? Curry._3(text, "event_view.popup.drop_in_public.subtitle", undefined, undefined) : Curry._3(text, "event_view.popup.drop_in.subtitle", undefined, undefined);
  var tmp$1;
  if (dropIn.online) {
    tmp$1 = /* Online */0;
  } else {
    var $$location$1 = dropIn.location;
    tmp$1 = $$location$1 !== undefined ? /* InPerson */({
          _0: $$location$1.name,
          _1: $$location$1.address
        }) : /* InPerson */({
          _0: Curry._3(text, "event_card.location.unavailable", undefined, undefined),
          _1: ""
        });
  }
  return ReactDom.createPortal(React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: "event-view-popup-container absolute-fill",
                      onClick: onClose
                    }), React.createElement("div", {
                      className: "event-view-popup-card-container"
                    }, React.createElement(EventCard$NimbusWeb.make, {
                          children: null
                        }, React.createElement(EventCard$NimbusWeb.Header.make, {
                              children: React.createElement(EventCard$NimbusWeb.Content.Header.make, {
                                    src: dropIn.host.avatar,
                                    title: dropIn.host.first + " " + dropIn.host.last,
                                    subtitle: tmp,
                                    url: location.host + "/view?disid=" + dropInStudent._id
                                  })
                            }), React.createElement(EventCard$NimbusWeb.Body.make, {
                              children: null
                            }, React.createElement(EventCard$NimbusWeb.Section.make, {
                                  children: React.createElement(EventCard$NimbusWeb.Content.TitleAndDescription.make, {
                                        title: dropIn.title,
                                        description: dropIn.description
                                      }),
                                  divider: true
                                }), React.createElement(EventCard$NimbusWeb.Section.make, {
                                  children: React.createElement(EventCard$NimbusWeb.Content.Schedule.make, {
                                        date: $$event.start,
                                        minutes: match$1[0]
                                      }),
                                  divider: true
                                }), React.createElement(EventCard$NimbusWeb.Section.make, {
                                  children: React.createElement(EventCard$NimbusWeb.Content.$$Location.make, {
                                        location: tmp$1
                                      }),
                                  divider: true
                                }), React.createElement(EventCard$NimbusWeb.Section.make, {
                                  children: React.createElement(EventCard$NimbusWeb.Content.Courses.make, {
                                        courses: match$1[1]
                                      })
                                })), React.createElement(EventCard$NimbusWeb.Footer.make, {
                              children: React.createElement(Button$NimbusWeb.make, {
                                    variant: /* Contained */1,
                                    className: "lesson-start-button",
                                    onClick: onClickJoin,
                                    disabled: isEnrolled,
                                    text: dropIn.online ? Belt_Option.getWithDefault(buttonText, Curry._3(text, "lesson.button.start", undefined, undefined)) : (
                                        isEnrolled ? Curry._3(text, "lesson.button.already_enrolled", undefined, undefined) : Curry._3(text, "lesson.button.register", undefined, undefined)
                                      ),
                                    accessKey: "d"
                                  })
                            })))), root);
}

var EventPopup = {
  root: root,
  make: EventViewUI$EventPopup
};

function EventViewUI$AddInterestAlert(Props) {
  var onClose = Props.onClose;
  var authenticated = Props.authenticated;
  var onPrimary = Props.onPrimary;
  var online = Props.online;
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  var text = match[0];
  var titleText = online ? "event_view.alert.add_interest.title" : "event_view.alert.add_interest.title.in_person";
  return React.createElement(Alert$NimbusWeb.make, {
              visible: true,
              onClose: onClose,
              children: null
            }, React.createElement(Alert$NimbusWeb.Title.make, {
                  text: Curry._3(text, titleText, undefined, undefined)
                }), React.createElement(Alert$NimbusWeb.Content.make, {
                  text: (
                    online ? Curry._3(text, "event_view.alert.add_interest.content.not_available", undefined, undefined) : ""
                  ) + (
                    authenticated ? Curry._3(text, "event_view.alert.add_interest.content.authenticated", undefined, undefined) : Curry._3(text, "event_view.alert.add_interest.content.unauthenticated", undefined, undefined)
                  )
                }), React.createElement(Alert$NimbusWeb.Actions.make, {
                  children: null
                }, React.createElement(Button$NimbusWeb.make, {
                      variant: /* Outlined */2,
                      className: "event-view-popup-cancel-button",
                      onClick: onClose,
                      text: Curry._3(text, "general.cancel", undefined, undefined),
                      accessKey: "w"
                    }), React.createElement(Button$NimbusWeb.make, {
                      variant: /* Contained */1,
                      className: "event-view-popup-sign-in-button",
                      onClick: onPrimary,
                      text: authenticated ? Curry._3(text, "general.register", undefined, undefined) : Curry._3(text, "login.button.sign_in", undefined, undefined),
                      accessKey: "i"
                    })));
}

var AddInterestAlert = {
  make: EventViewUI$AddInterestAlert
};

exports.Container = Container;
exports.EventPopup = EventPopup;
exports.AddInterestAlert = AddInterestAlert;
/*  Not a pure module */
