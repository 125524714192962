'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var Helmet$NimbusWeb = require("../../components/Helmet.bs.js");
var Helpers$NimbusWeb = require("../../utils/Helpers.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var TextField$NimbusWeb = require("../../components/TextField.bs.js");
var Navigation$NimbusWeb = require("../../utils/Navigation.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var AuthContext$NimbusWeb = require("../../contexts/AuthContext.bs.js");
var LocaleContext$NimbusWeb = require("../../contexts/LocaleContext.bs.js");
var LoginRequest_Login$NimbusWeb = require("./requests/LoginRequest_Login.bs.js");
var SSOUniversitiesContext$NimbusWeb = require("../../contexts/SSOUniversitiesContext.bs.js");
var LoginRequest_ForgotPassword$NimbusWeb = require("./requests/LoginRequest_ForgotPassword.bs.js");
var LoginRequest_SSOUniversities$NimbusWeb = require("./requests/LoginRequest_SSOUniversities.bs.js");

require("./Login.css");

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SetEmail */0 :
        return {
                email: action._0,
                password: state.password,
                forgotPassword: state.forgotPassword,
                emailSent: state.emailSent,
                loading: state.loading
              };
    case /* SetPassword */1 :
        return {
                email: state.email,
                password: action._0,
                forgotPassword: state.forgotPassword,
                emailSent: state.emailSent,
                loading: state.loading
              };
    case /* SetForgotPassword */2 :
        return {
                email: state.email,
                password: state.password,
                forgotPassword: action._0,
                emailSent: state.emailSent,
                loading: state.loading
              };
    case /* SetEmailSent */3 :
        return {
                email: state.email,
                password: state.password,
                forgotPassword: state.forgotPassword,
                emailSent: action._0,
                loading: state.loading
              };
    case /* SetLoading */4 :
        return {
                email: state.email,
                password: state.password,
                forgotPassword: state.forgotPassword,
                emailSent: state.emailSent,
                loading: action._0
              };
    
  }
}

function Login(Props) {
  var token = Props.token;
  var userType = Props.userType;
  var match = React.useReducer(reducer, {
        email: "",
        password: "",
        forgotPassword: false,
        emailSent: false,
        loading: false
      });
  var dispatch = match[1];
  var state = match[0];
  var onChangeEmail = function (e) {
    return Curry._1(dispatch, {
                TAG: /* SetEmail */0,
                _0: e.target.value
              });
  };
  var onChangePassword = function (e) {
    return Curry._1(dispatch, {
                TAG: /* SetPassword */1,
                _0: e.target.value
              });
  };
  var match$1 = React.useContext(LocaleContext$NimbusWeb.context);
  var text = match$1[0];
  var match$2 = React.useContext(AuthContext$NimbusWeb.Token.context);
  var setToken = match$2[1];
  var match$3 = React.useContext(AuthContext$NimbusWeb.UserType.context);
  var setUserType = match$3[1];
  var match$4 = React.useContext(SSOUniversitiesContext$NimbusWeb.context);
  var setUniversities = match$4[1];
  var universities = match$4[0];
  var request = Request$NimbusWeb.use(undefined);
  React.useEffect((function () {
          Curry._1(setToken, (function (param) {
                  return token;
                }));
          Curry._1(setUserType, (function (param) {
                  return userType;
                }));
          Curry._1(request, LoginRequest_SSOUniversities$NimbusWeb.make(undefined)).then(function (json) {
                  return Promise.resolve(LoginRequest_SSOUniversities$NimbusWeb.$$Response.normalize(Curry._1(LoginRequest_SSOUniversities$NimbusWeb.$$Response.Decode.exec, json)));
                }).then(function (result) {
                Curry._1(setUniversities, (function (param) {
                        return result;
                      }));
                return Promise.resolve(undefined);
              });
          
        }), []);
  var login = function (email) {
    Curry._1(request, LoginRequest_Login$NimbusWeb.make(email, state.password)).then(function (json) {
              return Promise.resolve(LoginRequest_Login$NimbusWeb.$$Response.normalize(Curry._1(LoginRequest_Login$NimbusWeb.$$Response.Decode.exec, json)));
            }).then(function (data) {
            Curry._1(dispatch, {
                  TAG: /* SetLoading */4,
                  _0: false
                });
            Curry._1(setToken, (function (param) {
                    return data.token;
                  }));
            Curry._1(setUserType, (function (param) {
                    return data.userType;
                  }));
            return Promise.resolve(undefined);
          }).catch(function (param) {
          Curry._1(dispatch, {
                TAG: /* SetLoading */4,
                _0: false
              });
          return Promise.resolve(undefined);
        });
    
  };
  var onClickSignIn = function (e) {
    e.preventDefault();
    if (state.forgotPassword) {
      Curry._1(dispatch, {
            TAG: /* SetLoading */4,
            _0: true
          });
      Curry._1(request, LoginRequest_ForgotPassword$NimbusWeb.make(state.email)).then(function (json) {
                return Promise.resolve(LoginRequest_ForgotPassword$NimbusWeb.$$Response.normalize(Curry._1(LoginRequest_ForgotPassword$NimbusWeb.$$Response.Decode.exec, json)));
              }).then(function (result) {
              if (result !== undefined) {
                Curry._1(dispatch, {
                      TAG: /* SetEmailSent */3,
                      _0: true
                    });
              }
              Curry._1(dispatch, {
                    TAG: /* SetForgotPassword */2,
                    _0: false
                  });
              Curry._1(dispatch, {
                    TAG: /* SetLoading */4,
                    _0: false
                  });
              return Promise.resolve(undefined);
            }).catch(function (param) {
            Curry._1(dispatch, {
                  TAG: /* SetLoading */4,
                  _0: false
                });
            return Promise.resolve(undefined);
          });
      return ;
    } else {
      Curry._1(dispatch, {
            TAG: /* SetLoading */4,
            _0: true
          });
      var email = state.email.trim();
      var splitByAt = email.split("@");
      var match = Belt_Array.get(splitByAt, 1);
      if (match === undefined) {
        return login(email);
      }
      if (universities === undefined) {
        return login(email);
      }
      var uni = universities.find(function (uni) {
            var emailDomain = uni.ssoEmailDomain;
            if (emailDomain !== undefined) {
              return emailDomain === match;
            } else {
              return false;
            }
          });
      if (uni === undefined) {
        return login(email);
      }
      var endpoint = uni.ssoEndpoint;
      if (endpoint !== undefined) {
        Curry._1(dispatch, {
              TAG: /* SetLoading */4,
              _0: false
            });
        return Helpers$NimbusWeb.openSSOLink(endpoint);
      } else {
        return login(email);
      }
    }
  };
  return React.createElement("div", {
              className: "home-centered home-padding"
            }, React.createElement(Helmet$NimbusWeb.make, {
                  title: "Nimbus Learning - Login"
                }), React.createElement(Typography$NimbusWeb.make, {
                  variant: /* H1 */0,
                  color: /* Primary */0,
                  textAlign: "center",
                  className: "login-label",
                  value: state.forgotPassword ? Curry._3(text, "login.forgot_password_instruction", undefined, undefined) : Curry._3(text, "login.instruction", undefined, undefined)
                }), React.createElement("form", {
                  className: "home-centered login-form",
                  onSubmit: onClickSignIn
                }, React.createElement(TextField$NimbusWeb.make, {
                      className: "login-input",
                      type_: "email",
                      placeholder: Curry._3(text, "login.placeholder.email", undefined, undefined),
                      value: state.email,
                      onChange: onChangeEmail,
                      required: true,
                      label: "alternate_email"
                    }), state.forgotPassword ? null : React.createElement(TextField$NimbusWeb.make, {
                        className: "login-input",
                        type_: "password",
                        placeholder: Curry._3(text, "login.placeholder.password", undefined, undefined),
                        value: state.password,
                        onChange: onChangePassword,
                        required: true,
                        label: "lock"
                      }), React.createElement(Button$NimbusWeb.make, {
                      variant: /* Contained */1,
                      className: "login-signin-button",
                      disabled: state.loading,
                      text: state.forgotPassword ? Curry._3(text, "login.button.send", undefined, undefined) : Curry._3(text, "login.button.sign_in", undefined, undefined),
                      accessKey: "l"
                    })), React.createElement(Button$NimbusWeb.make, {
                  color: /* Gray1 */8,
                  className: "login-forgot-password-button",
                  onClick: (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* SetForgotPassword */2,
                            _0: !state.forgotPassword
                          });
                      return Curry._1(dispatch, {
                                  TAG: /* SetEmailSent */3,
                                  _0: false
                                });
                    }),
                  text: state.forgotPassword ? Curry._3(text, "login.button.nevermind", undefined, undefined) : Curry._3(text, "login.button.forgot_password", undefined, undefined),
                  accessKey: "r"
                }), React.createElement(Button$NimbusWeb.make, {
                  variant: /* Outlined */2,
                  className: "login-sso-button",
                  onClick: (function (param) {
                      return Navigation$NimbusWeb.push("sso/");
                    }),
                  text: Curry._3(text, "login.button.sso_universities", undefined, undefined),
                  accessKey: "s"
                }));
}

var make = Login;

exports.reducer = reducer;
exports.make = make;
/*  Not a pure module */
