'use strict';

var React = require("react");
var Navigation$NimbusWeb = require("../utils/Navigation.bs.js");

function Redirect(Props) {
  var to_ = Props.to_;
  React.useEffect((function () {
          setTimeout((function (param) {
                  return Navigation$NimbusWeb.push(to_);
                }), 0);
          
        }), []);
  return null;
}

var make = Redirect;

exports.make = make;
/* react Not a pure module */
