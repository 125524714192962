'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Chip$NimbusWeb = require("../../components/Chip.bs.js");
var Date$NimbusWeb = require("../../utils/Date.bs.js");
var Avatar$NimbusWeb = require("../../components/Avatar.bs.js");
var Icon_Pin$NimbusWeb = require("../../icons/Icon_Pin.bs.js");
var Icon_Book$NimbusWeb = require("../../icons/Icon_Book.bs.js");
var Icon_Chat$NimbusWeb = require("../../icons/Icon_Chat.bs.js");
var Icon_Clock$NimbusWeb = require("../../icons/Icon_Clock.bs.js");
var Icon_Heart$NimbusWeb = require("../../icons/Icon_Heart.bs.js");
var Icon_Title$NimbusWeb = require("../../icons/Icon_Title.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var Icon_Slider$NimbusWeb = require("../../icons/Icon_Slider.bs.js");
var Icon_Calendar$NimbusWeb = require("../../icons/Icon_Calendar.bs.js");
var Icon_VideoCam$NimbusWeb = require("../../icons/Icon_VideoCam.bs.js");
var LocaleContext$NimbusWeb = require("../../contexts/LocaleContext.bs.js");
var CopyToClipboard$NimbusWeb = require("../../components/CopyToClipboard.bs.js");
var Icon_Description$NimbusWeb = require("../../icons/Icon_Description.bs.js");
var LessonStartButton$NimbusWeb = require("./LessonStartButton.bs.js");

require("./EventCard.css");

function EventCard(Props) {
  var children = Props.children;
  var heightOpt = Props.height;
  var height = heightOpt !== undefined ? heightOpt : "100%";
  var style = {
    height: height
  };
  return React.createElement("div", {
              className: "event-card-container",
              style: style
            }, children);
}

function EventCard$Divider(Props) {
  var marginHorizontalOpt = Props.marginHorizontal;
  var marginHorizontal = marginHorizontalOpt !== undefined ? marginHorizontalOpt : "0px";
  return React.createElement("div", {
              className: "event-card-divider",
              style: {
                marginRight: marginHorizontal,
                marginLeft: marginHorizontal,
                flex: "1"
              }
            });
}

var Divider = {
  make: EventCard$Divider
};

function EventCard$Section(Props) {
  var children = Props.children;
  var dividerOpt = Props.divider;
  var shadedOpt = Props.shaded;
  var divider = dividerOpt !== undefined ? dividerOpt : false;
  var shaded = shadedOpt !== undefined ? shadedOpt : false;
  var className = shaded ? "event-card-section-shaded" : "";
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "event-card-section " + className
                }, children), divider ? React.createElement(EventCard$Divider, {
                    marginHorizontal: "26px"
                  }) : null);
}

var Section = {
  make: EventCard$Section
};

function EventCard$Header(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "event-card-header"
            }, React.createElement(EventCard$Section, {
                  children: children
                }));
}

var Header = {
  make: EventCard$Header
};

function EventCard$Body(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "event-card-body"
            }, children);
}

var Body = {
  make: EventCard$Body
};

function EventCard$Footer(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "event-card-footer"
            }, children);
}

var Footer = {
  make: EventCard$Footer
};

function getIcon(variant, color) {
  switch (variant) {
    case /* Null */0 :
        return [
                null,
                ""
              ];
    case /* Title */1 :
        return [
                React.createElement(Icon_Title$NimbusWeb.make, {
                      color: color,
                      width: "14",
                      height: "14"
                    }),
                ""
              ];
    case /* Description */2 :
        return [
                React.createElement(Icon_Description$NimbusWeb.make, {
                      color: color
                    }),
                ""
              ];
    case /* Calendar */3 :
        return [
                React.createElement(Icon_Calendar$NimbusWeb.make, {
                      color: color
                    }),
                "event-card-calendar-icon"
              ];
    case /* Clock */4 :
        return [
                React.createElement(Icon_Clock$NimbusWeb.make, {
                      color: color
                    }),
                "event-card-clock-icon"
              ];
    case /* Book */5 :
        return [
                React.createElement(Icon_Book$NimbusWeb.make, {
                      color: color
                    }),
                "event-card-book-icon"
              ];
    case /* Slider */6 :
        return [
                React.createElement(Icon_Slider$NimbusWeb.make, {
                      color: color
                    }),
                ""
              ];
    case /* Heart */7 :
        return [
                React.createElement(Icon_Heart$NimbusWeb.make, {
                      color: color
                    }),
                ""
              ];
    case /* Chat */8 :
        return [
                React.createElement(Icon_Chat$NimbusWeb.make, {
                      color: color
                    }),
                "event-card-chat-icon"
              ];
    case /* Pin */9 :
        return [
                React.createElement(Icon_Pin$NimbusWeb.make, {}),
                ""
              ];
    case /* VideoCam */10 :
        return [
                React.createElement(Icon_VideoCam$NimbusWeb.make, {}),
                ""
              ];
    
  }
}

function EventCard$ContentWithAdornment(Props) {
  var icon = Props.icon;
  var colorOpt = Props.color;
  var children = Props.children;
  var marginTopOpt = Props.marginTop;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var marginTop = marginTopOpt !== undefined ? marginTopOpt : "4px";
  var match = getIcon(icon, color);
  return React.createElement("div", {
              className: "event-card-content-with-adornment"
            }, React.createElement("div", {
                  className: "event-card-content-with-adornment-icon " + match[1],
                  style: {
                    marginTop: marginTop
                  }
                }, match[0]), children);
}

var ContentWithAdornment = {
  getIcon: getIcon,
  make: EventCard$ContentWithAdornment
};

function EventCard$TextWithAdornment(Props) {
  var classNameOpt = Props.className;
  var icon = Props.icon;
  var colorOpt = Props.color;
  var text = Props.text;
  var marginTopOpt = Props.marginTop;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var marginTop = marginTopOpt !== undefined ? marginTopOpt : "4px";
  return React.createElement(EventCard$ContentWithAdornment, {
              icon: icon,
              color: color,
              children: React.createElement(Typography$NimbusWeb.make, {
                    color: color,
                    className: "event-card-text-with-adornment-text " + className,
                    value: text
                  }),
              marginTop: marginTop
            });
}

var TextWithAdornment = {
  make: EventCard$TextWithAdornment
};

function EventCard$Spacing(Props) {
  var widthOpt = Props.width;
  var heightOpt = Props.height;
  var width = widthOpt !== undefined ? widthOpt : "16px";
  var height = heightOpt !== undefined ? heightOpt : "16px";
  return React.createElement("div", {
              style: {
                height: height,
                width: width
              }
            });
}

var Spacing = {
  make: EventCard$Spacing
};

function EventCard$Content$Header(Props) {
  var src = Props.src;
  var title = Props.title;
  var subtitle = Props.subtitle;
  var url = Props.url;
  return React.createElement("div", {
              className: "event-card-header-content"
            }, React.createElement(Avatar$NimbusWeb.make, {
                  src: src,
                  alt: title + "_avatar"
                }), React.createElement("div", {
                  className: "event-card-header-content-titles"
                }, React.createElement(Typography$NimbusWeb.make, {
                      color: /* Black */11,
                      className: "text-bold text-size15",
                      value: title
                    }), React.createElement(Typography$NimbusWeb.make, {
                      color: /* Black */11,
                      className: "text-size15",
                      value: subtitle
                    })), url !== undefined ? React.createElement(CopyToClipboard$NimbusWeb.make, {
                    className: "event-card-header-copy",
                    url: url
                  }) : null);
}

var Header$1 = {
  make: EventCard$Content$Header
};

function EventCard$Content$Title(Props) {
  var title = Props.title;
  return React.createElement(EventCard$TextWithAdornment, {
              className: "text-bold text-size16",
              icon: /* Title */1,
              text: title
            });
}

var Title = {
  make: EventCard$Content$Title
};

function EventCard$Content$TitleAndDescription(Props) {
  var title = Props.title;
  var description = Props.description;
  return React.createElement("div", undefined, React.createElement(EventCard$Content$Title, {
                  title: title
                }), description !== undefined && description !== "" ? React.createElement(React.Fragment, undefined, React.createElement(EventCard$Spacing, {}), React.createElement(EventCard$TextWithAdornment, {
                        className: "text-size14",
                        icon: /* Description */2,
                        text: description
                      })) : null);
}

var TitleAndDescription = {
  make: EventCard$Content$TitleAndDescription
};

function EventCard$Content$Schedule(Props) {
  var date = Props.date;
  var minutes = Props.minutes;
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  var dateText = Date$NimbusWeb.toLocaleString(date, "en-CA", {
        hour12: undefined,
        weekday: undefined,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: undefined,
        minute: undefined
      });
  var endTime = new Date(date.getTime() + 1000.0 * 60.0 * minutes);
  var timeOptions = {
    hour12: true,
    weekday: undefined,
    year: undefined,
    month: undefined,
    day: undefined,
    hour: "2-digit",
    minute: "2-digit"
  };
  var startTimeText = Date$NimbusWeb.toLocaleString(date, "en-CA", timeOptions);
  var endTimeText = Date$NimbusWeb.toLocaleString(endTime, "en-CA", timeOptions);
  return React.createElement("div", undefined, React.createElement(Typography$NimbusWeb.make, {
                  color: /* Black */11,
                  className: "text-bold text-size15",
                  value: Curry._3(match[0], "event_card.schedule.title", undefined, undefined)
                }), React.createElement(EventCard$Spacing, {}), React.createElement("div", {
                  className: "event-card-schedule-datetime"
                }, React.createElement(EventCard$TextWithAdornment, {
                      className: "text-size14",
                      icon: /* Calendar */3,
                      text: dateText
                    }), React.createElement(EventCard$Spacing, {
                      height: "0px"
                    }), React.createElement(EventCard$TextWithAdornment, {
                      className: "text-size14",
                      icon: /* Clock */4,
                      text: startTimeText + (" - " + endTimeText)
                    })));
}

var Schedule = {
  make: EventCard$Content$Schedule
};

function EventCard$Content$Location(Props) {
  var $$location = Props.location;
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  var text = match[0];
  return React.createElement("div", undefined, React.createElement(Typography$NimbusWeb.make, {
                  color: /* Black */11,
                  className: "text-bold text-size15",
                  value: Curry._3(text, "event_card.location.title", undefined, undefined)
                }), React.createElement(EventCard$Spacing, {
                  height: "20px"
                }), $$location ? React.createElement(React.Fragment, undefined, React.createElement(EventCard$TextWithAdornment, {
                        className: "text-size14",
                        icon: /* Pin */9,
                        text: $$location._0
                      }), React.createElement("div", {
                        className: "event-card-location-address"
                      }, React.createElement(Typography$NimbusWeb.make, {
                            className: "text-size14",
                            value: $$location._1
                          }))) : React.createElement(EventCard$TextWithAdornment, {
                    className: "text-size14",
                    icon: /* VideoCam */10,
                    text: Curry._3(text, "event_card.location.online", undefined, undefined)
                  }));
}

var $$Location = {
  make: EventCard$Content$Location
};

function EventCard$Content$Courses(Props) {
  var coursesOpt = Props.courses;
  var courses = coursesOpt !== undefined ? coursesOpt : [];
  var titleText = courses.length === 1 ? "Course" : "Courses";
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  return React.createElement("div", undefined, React.createElement(Typography$NimbusWeb.make, {
                  color: /* Black */11,
                  className: "text-bold text-size15",
                  value: titleText
                }), React.createElement(EventCard$Spacing, {
                  height: "22px"
                }), courses.length === 0 ? React.createElement(Typography$NimbusWeb.make, {
                    className: "text-size14",
                    value: Curry._3(match[0], "event_card.courses.empty", undefined, undefined)
                  }) : React.createElement(EventCard$ContentWithAdornment, {
                    icon: /* Book */5,
                    children: React.createElement("div", {
                          className: "event-card-courses"
                        }, Belt_Array.map(courses, (function (course) {
                                return React.createElement("div", {
                                            key: course,
                                            className: "event-card-course-item"
                                          }, React.createElement(Chip$NimbusWeb.make, {
                                                text: course
                                              }));
                              })))
                  }));
}

var Courses = {
  make: EventCard$Content$Courses
};

function EventCard$Content$Count$Base(Props) {
  var icon = Props.icon;
  var title = Props.title;
  var numerator = Props.numerator;
  var denominatorOpt = Props.denominator;
  var marginTopOpt = Props.marginTop;
  var denominator = denominatorOpt !== undefined ? Caml_option.valFromOption(denominatorOpt) : undefined;
  var marginTop = marginTopOpt !== undefined ? marginTopOpt : "4px";
  return React.createElement("div", {
              className: "event-card-count-container"
            }, React.createElement(EventCard$TextWithAdornment, {
                  className: "text-bold text-size14",
                  icon: icon,
                  text: title,
                  marginTop: marginTop
                }), React.createElement(EventCard$Spacing, {
                  width: "4px",
                  height: "0px"
                }), React.createElement(Typography$NimbusWeb.make, {
                  color: /* Black */11,
                  className: "text-size14",
                  value: String(numerator) + (
                    denominator !== undefined ? "/" + String(denominator) : ""
                  )
                }));
}

var Base = {
  make: EventCard$Content$Count$Base
};

function EventCard$Content$Count$Attendees(Props) {
  var attendeesCount = Props.attendeesCount;
  var attendeesMax = Props.attendeesMax;
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  return React.createElement(EventCard$Content$Count$Base, {
              icon: /* Slider */6,
              title: Curry._3(match[0], "event_card.count.attendees", undefined, undefined),
              numerator: attendeesCount,
              denominator: attendeesMax,
              marginTop: "0"
            });
}

var Attendees = {
  make: EventCard$Content$Count$Attendees
};

function EventCard$Content$Count$AttendeesMax(Props) {
  var attendeesMax = Props.attendeesMax;
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  return React.createElement(EventCard$Content$Count$Base, {
              icon: /* Slider */6,
              title: Curry._3(match[0], "event_card.count.attendees_max", undefined, undefined),
              numerator: attendeesMax,
              marginTop: "0"
            });
}

var AttendeesMax = {
  make: EventCard$Content$Count$AttendeesMax
};

function EventCard$Content$Count$AttendedStudents(Props) {
  var attendedCount = Props.attendedCount;
  var interestedStudentsCount = Props.interestedStudentsCount;
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  return React.createElement(EventCard$Content$Count$Base, {
              icon: /* Null */0,
              title: Curry._3(match[0], "event_card.count.attended_students", undefined, undefined),
              numerator: attendedCount,
              denominator: interestedStudentsCount
            });
}

var AttendedStudents = {
  make: EventCard$Content$Count$AttendedStudents
};

function EventCard$Content$Count$InterestedStudents(Props) {
  var count = Props.count;
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  return React.createElement(EventCard$Content$Count$Base, {
              icon: /* Heart */7,
              title: Curry._3(match[0], "event_card.count.interested_students", undefined, undefined),
              numerator: count
            });
}

var InterestedStudents = {
  make: EventCard$Content$Count$InterestedStudents
};

var Count = {
  Base: Base,
  Attendees: Attendees,
  AttendeesMax: AttendeesMax,
  AttendedStudents: AttendedStudents,
  InterestedStudents: InterestedStudents
};

function EventCard$Content$StudentList$StudentItem(Props) {
  var name = Props.name;
  var avatar = Props.avatar;
  var isLast = Props.isLast;
  var marginBottom = isLast ? "0" : "10px";
  return React.createElement("div", {
              className: "event-card-student-item",
              style: {
                marginBottom: marginBottom
              }
            }, React.createElement(Avatar$NimbusWeb.make, {
                  className: "event-card-student-item-avatar",
                  src: avatar,
                  alt: "avatar_" + name,
                  size: /* Small */0
                }), React.createElement(Typography$NimbusWeb.make, {
                  color: /* Black */11,
                  className: "text-bold",
                  value: name
                }));
}

var StudentItem = {
  make: EventCard$Content$StudentList$StudentItem
};

function EventCard$Content$StudentList(Props) {
  var students = Props.students;
  if (students.length === 0) {
    return null;
  } else {
    return React.createElement(EventCard$Section, {
                children: Belt_Array.mapWithIndex(students, (function (i, student) {
                        return React.createElement(EventCard$Content$StudentList$StudentItem, {
                                    name: student.first + (" " + student.last),
                                    avatar: student.avatar,
                                    isLast: i === (students.length - 1 | 0),
                                    key: student._id
                                  });
                      })),
                shaded: true
              });
  }
}

var StudentList = {
  StudentItem: StudentItem,
  make: EventCard$Content$StudentList
};

function EventCard$Content$HelpDescription(Props) {
  var helpType = Props.helpType;
  var description = Props.description;
  return React.createElement("div", undefined, helpType !== undefined && helpType !== "" ? React.createElement(EventCard$TextWithAdornment, {
                    icon: /* Chat */8,
                    text: helpType
                  }) : null, description !== undefined && description !== "" ? React.createElement(EventCard$TextWithAdornment, {
                    icon: /* Description */2,
                    text: description
                  }) : null);
}

var HelpDescription = {
  make: EventCard$Content$HelpDescription
};

function EventCard$Content$Footer(Props) {
  var startTime = Props.startTime;
  var onClick = Props.onClick;
  return React.createElement(LessonStartButton$NimbusWeb.make, {
              startTime: startTime,
              onClick: onClick
            });
}

var Footer$1 = {
  make: EventCard$Content$Footer
};

var Content = {
  Header: Header$1,
  Title: Title,
  TitleAndDescription: TitleAndDescription,
  Schedule: Schedule,
  $$Location: $$Location,
  Courses: Courses,
  Count: Count,
  StudentList: StudentList,
  HelpDescription: HelpDescription,
  Footer: Footer$1
};

var make = EventCard;

exports.make = make;
exports.Divider = Divider;
exports.Section = Section;
exports.Header = Header;
exports.Body = Body;
exports.Footer = Footer;
exports.ContentWithAdornment = ContentWithAdornment;
exports.TextWithAdornment = TextWithAdornment;
exports.Spacing = Spacing;
exports.Content = Content;
/*  Not a pure module */
