'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_User(Props) {
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: "24px",
              width: "24px",
              version: "1.1",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("title", undefined, "user"), React.createElement("defs", undefined, React.createElement("path", {
                      id: "path-user",
                      d: "M21,21 C21,21.5522847 20.5522847,22 20,22 C19.4477153,22 19,21.5522847 19,21 L19,19 C19,17.3431458 17.6568542,16 16,16 L8,16 C6.34314575,16 5,17.3431458 5,19 L5,21 C5,21.5522847 4.55228475,22 4,22 C3.44771525,22 3,21.5522847 3,21 L3,19 C3,16.2385763 5.23857625,14 8,14 L16,14 C18.7614237,14 21,16.2385763 21,19 L21,21 Z M12,12 C9.23857625,12 7,9.76142375 7,7 C7,4.23857625 9.23857625,2 12,2 C14.7614237,2 17,4.23857625 17,7 C17,9.76142375 14.7614237,12 12,12 Z M12,10 C13.6568542,10 15,8.65685425 15,7 C15,5.34314575 13.6568542,4 12,4 C10.3431458,4 9,5.34314575 9,7 C9,8.65685425 10.3431458,10 12,10 Z"
                    })), React.createElement("g", {
                  id: "user-icon",
                  fill: "none",
                  fillRule: "evenodd",
                  stroke: "none",
                  strokeWidth: "1"
                }, React.createElement("mask", {
                      id: "mask-user",
                      fill: "white"
                    }, React.createElement("use", {
                          xlinkHref: "#path-user"
                        })), React.createElement("use", {
                      id: "Combined-Shape-user",
                      fill: "#000000",
                      fillRule: "nonzero",
                      xlinkHref: "#path-user"
                    }), React.createElement("g", {
                      id: "user-black",
                      fill: c,
                      mask: "url(#mask-user)"
                    }, React.createElement("rect", {
                          id: "user-rect",
                          height: "24",
                          width: "24",
                          x: "0",
                          y: "0"
                        }))));
}

var make = Icon_User;

exports.make = make;
/* react Not a pure module */
