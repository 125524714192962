'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_Chat(Props) {
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: "24px",
              width: "24px",
              version: "1.1",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("title", undefined, "chat"), React.createElement("defs", undefined, React.createElement("path", {
                      id: "path-chat",
                      d: "M6.29289322,16.2928932 C6.4804296,16.1053568 6.73478351,16 7,16 L19,16 C19.5522847,16 20,15.5522847 20,15 L20,5 C20,4.44771525 19.5522847,4 19,4 L5,4 C4.44771525,4 4,4.44771525 4,5 L4,18.5857864 L6.29289322,16.2928932 Z M7.41421356,18 L3.70710678,21.7071068 C3.07714192,22.3370716 2,21.8909049 2,21 L2,5 C2,3.34314575 3.34314575,2 5,2 L19,2 C20.6568542,2 22,3.34314575 22,5 L22,15 C22,16.6568542 20.6568542,18 19,18 L7.41421356,18 Z"
                    })), React.createElement("g", {
                  id: "chat-icon",
                  fill: "none",
                  fillRule: "evenodd",
                  stroke: "none",
                  strokeWidth: "1"
                }, React.createElement("mask", {
                      id: "mask-chat",
                      fill: "white"
                    }, React.createElement("use", {
                          xlinkHref: "#path-chat"
                        })), React.createElement("use", {
                      id: "Shape-chat",
                      fill: "#000000",
                      fillRule: "nonzero",
                      xlinkHref: "#path-chat"
                    }), React.createElement("g", {
                      id: "chat-black",
                      fill: c,
                      mask: "url(#mask-chat)"
                    }, React.createElement("rect", {
                          id: "chat-rect",
                          height: "24",
                          width: "24",
                          x: "0",
                          y: "0"
                        }))));
}

var make = Icon_Chat;

exports.make = make;
/* react Not a pure module */
