'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Storage$NimbusWeb = require("./Storage.bs.js");
var Navigation$NimbusWeb = require("./Navigation.bs.js");

function payload(json) {
  return {
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", Json_decode.string, param);
                }), json)
        };
}

function error(json) {
  return {
          message: Json_decode.optional((function (param) {
                  return Json_decode.field("message", Json_decode.string, param);
                }), json),
          code: Json_decode.optional((function (param) {
                  return Json_decode.field("code", Json_decode.string, param);
                }), json),
          payload: Json_decode.optional((function (param) {
                  return Json_decode.field("payload", payload, param);
                }), json)
        };
}

function exec(json) {
  return {
          errors: Json_decode.optional((function (param) {
                  return Json_decode.field("errors", (function (param) {
                                return Json_decode.array(error, param);
                              }), param);
                }), json)
        };
}

var Decode = {
  payload: payload,
  error: error,
  exec: exec
};

function $$process(error, clearToken) {
  var code = error.code;
  if (code !== undefined) {
    switch (code) {
      case "account_not_found" :
          return "Your account was not found";
      case "incorrect_password" :
          return "Incorrect email/password combination";
      case "jwt_invalid" :
          Storage$NimbusWeb.clear(undefined);
          Curry._1(clearToken, (function (param) {
                  return "";
                }));
          Navigation$NimbusWeb.push("home");
          return "You have been logged out";
      case "operation_failed" :
          var payload = error.payload;
          if (payload === undefined) {
            return "Unknown error";
          }
          var m = payload.error;
          if (m !== undefined) {
            return m;
          } else {
            return "Unknown error";
          }
      case "permission_denied" :
          return "You do not have access to that action";
      default:
        return code;
    }
  } else {
    var m$1 = error.message;
    if (m$1 !== undefined) {
      return m$1;
    } else {
      return "Unknown error";
    }
  }
}

exports.Decode = Decode;
exports.$$process = $$process;
/* Navigation-NimbusWeb Not a pure module */
