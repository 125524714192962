'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Subscription$NimbusWeb = require("../../../utils/Subscription.bs.js");
var MessageResponse$NimbusWeb = require("./MessageResponse.bs.js");

function make(group, user) {
  var variables = {};
  variables["group"] = group;
  variables["user"] = user;
  return [
          "\n  subscription($group: ID!, $user: ID!) {\n    messageAdded(_group: $group, _user: $user) {\n      _id\n      _from\n      body\n      createdAt { timestamp }\n      attachment { type payload filename }\n      type\n    }\n  }\n  ",
          variables
        ];
}

function decodeContent(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          _from: Json_decode.field("_from", Json_decode.string, json),
          body: Json_decode.optional((function (param) {
                  return Json_decode.field("body", Json_decode.string, param);
                }), json),
          createdAt: Json_decode.field("createdAt", MessageResponse$NimbusWeb.Decode.decodeDate, json),
          attachment: Json_decode.optional((function (param) {
                  return Json_decode.field("attachment", MessageResponse$NimbusWeb.Decode.decodeAttachment, param);
                }), json),
          type_: Json_decode.optional((function (param) {
                  return Json_decode.field("type", Json_decode.string, param);
                }), json)
        };
}

function normalizeCallbackArg(arg) {
  return {
          _id: arg._id,
          body: arg.body,
          attachment: arg.attachment,
          from: {
            _id: arg._from,
            first: undefined,
            last: undefined,
            avatar: undefined
          },
          createdAt: arg.createdAt,
          type_: arg.type_
        };
}

var include = Subscription$NimbusWeb.Make({
      decodeContent: decodeContent,
      contentKey: "messageAdded",
      normalizeCallbackArg: normalizeCallbackArg
    });

var mutableCounter = include.mutableCounter;

var callbacks = include.callbacks;

var Decode = include.Decode;

var connect = include.connect;

var unsubscribe = include.unsubscribe;

var subscribe = include.subscribe;

var updateCallback = include.updateCallback;

var Component = include.Component;

exports.make = make;
exports.mutableCounter = mutableCounter;
exports.callbacks = callbacks;
exports.Decode = Decode;
exports.connect = connect;
exports.unsubscribe = unsubscribe;
exports.subscribe = subscribe;
exports.updateCallback = updateCallback;
exports.Component = Component;
/* include Not a pure module */
