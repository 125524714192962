'use strict';

var Request$NimbusWeb = require("../../../utils/Request.bs.js");

function make(dropInId, timeblockId) {
  var variables = [
    {
      key: "dropInId",
      val: dropInId
    },
    {
      key: "timeblockId",
      val: timeblockId
    }
  ];
  return Request$NimbusWeb.createBody("\n    mutation (\$dropInId: ID!, \$timeblockId: ID!) {\n      dropInShowInterest(interest: { _drop_in: \$dropInId, _timeblock: \$timeblockId }) {\n      _id\n    }\n  }\n  ", variables);
}

exports.make = make;
/* Request-NimbusWeb Not a pure module */
