'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_RightArrow(Props) {
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: "18",
              width: "11",
              fill: "none",
              viewBox: "0 0 11 18",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M1 1L9 9L1 17",
                  stroke: c,
                  strokeWidth: "2"
                }));
}

var make = Icon_RightArrow;

exports.make = make;
/* react Not a pure module */
