'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_FileCopy(Props) {
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: "24px",
              width: "24px",
              fill: c,
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M0 0h24v24H0V0z",
                  fill: "none"
                }), React.createElement("path", {
                  d: "M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zM8 21V7h6v5h5v9H8z"
                }));
}

var make = Icon_FileCopy;

exports.make = make;
/* react Not a pure module */
