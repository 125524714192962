'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_Calendar(Props) {
  var colorOpt = Props.color;
  var widthOpt = Props.width;
  var heightOpt = Props.height;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var width = widthOpt !== undefined ? widthOpt : "24";
  var height = heightOpt !== undefined ? heightOpt : "24";
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: height + "px",
              width: width + "px",
              version: "1.1",
              viewBox: "0 0 " + (width + (" " + height)),
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("title", undefined, "calendar"), React.createElement("defs", undefined, React.createElement("path", {
                      id: "path-calendar",
                      d: "M20,9 L20,6 C20,5.44771525 19.5522847,5 19,5 L17,5 L17,6 C17,6.55228475 16.5522847,7 16,7 C15.4477153,7 15,6.55228475 15,6 L15,5 L9,5 L9,6 C9,6.55228475 8.55228475,7 8,7 C7.44771525,7 7,6.55228475 7,6 L7,5 L5,5 C4.44771525,5 4,5.44771525 4,6 L4,9 L20,9 Z M20,11 L4,11 L4,20 C4,20.5522847 4.44771525,21 5,21 L19,21 C19.5522847,21 20,20.5522847 20,20 L20,11 Z M9,3 L15,3 L15,2 C15,1.44771525 15.4477153,1 16,1 C16.5522847,1 17,1.44771525 17,2 L17,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,20 C22,21.6568542 20.6568542,23 19,23 L5,23 C3.34314575,23 2,21.6568542 2,20 L2,6 C2,4.34314575 3.34314575,3 5,3 L7,3 L7,2 C7,1.44771525 7.44771525,1 8,1 C8.55228475,1 9,1.44771525 9,2 L9,3 Z"
                    })), React.createElement("g", {
                  id: "calendar",
                  fill: "none",
                  fillRule: "evenodd",
                  stroke: "none",
                  strokeWidth: "1"
                }, React.createElement("mask", {
                      id: "calendar-mask",
                      fill: "white"
                    }, React.createElement("use", {
                          xlinkHref: "#path-calendar"
                        })), React.createElement("use", {
                      id: "calendar-combined-shape",
                      fill: "#000000",
                      fillRule: "nonzero",
                      xlinkHref: "#path-calendar"
                    }), React.createElement("g", {
                      id: "calendar-black",
                      fill: c,
                      mask: "url(#calendar-mask)"
                    }, React.createElement("rect", {
                          id: "calendar-rect",
                          height: height,
                          width: width,
                          x: "0",
                          y: "0"
                        }))));
}

var make = Icon_Calendar;

exports.make = make;
/* react Not a pure module */
