'use strict';

var React = require("react");

require("./BrandIcon.css");

function BrandIcon(Props) {
  var icon = Props.icon;
  var isDefault = Props.isDefault;
  var image = React.createElement("img", {
        className: "brand-icon-image",
        alt: "Organization logo",
        src: icon
      });
  var containerStyle = {
    backgroundColor: isDefault ? "transparent" : "white",
    padding: isDefault ? "0" : "4px"
  };
  return React.createElement("div", {
              className: "brand-icon-container",
              style: containerStyle
            }, image);
}

var make = BrandIcon;

exports.make = make;
/*  Not a pure module */
