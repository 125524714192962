'use strict';

var React = require("react");
var Alert$NimbusWeb = require("../../components/Alert.bs.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");

function ZoomNotAvailableAlert(Props) {
  var visible = Props.visible;
  var onCloseOpt = Props.onClose;
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  return React.createElement(Alert$NimbusWeb.make, {
              visible: visible,
              onClose: onClose,
              children: null
            }, React.createElement(Alert$NimbusWeb.Title.make, {
                  text: "Unavailable"
                }), React.createElement(Alert$NimbusWeb.Content.make, {
                  text: "The Zoom meeting is not available yet. It will be available within 15 minutes before the session starts."
                }), React.createElement(Alert$NimbusWeb.Actions.make, {
                  children: React.createElement(Button$NimbusWeb.make, {
                        variant: /* Contained */1,
                        className: "lesson-zoom-unavailable-alert-button",
                        onClick: onClose,
                        text: "Okay",
                        accessKey: "o"
                      })
                }));
}

var make = ZoomNotAvailableAlert;

exports.make = make;
/* react Not a pure module */
