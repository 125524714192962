'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var LessonResponse$NimbusWeb = require("./LessonResponse.bs.js");

function make(lessonId) {
  var query = "\n    query (\$lessonId: ID!) {\n      lessonById(_lesson: \$lessonId) {\n        ...LessonFragment\n      }\n    }\n    " + LessonResponse$NimbusWeb.fragment + "\n  ";
  var variables = [{
      key: "lessonId",
      val: lessonId
    }];
  return Request$NimbusWeb.createBody(query, variables);
}

function dData(json) {
  return {
          lesson: Json_decode.optional((function (param) {
                  return Json_decode.field("lessonById", LessonResponse$NimbusWeb.Decode.lesson, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", dData, param);
                }), json)
        };
}

var Decode = {
  dData: dData,
  exec: exec
};

function normalize(payload) {
  return Belt_Option.flatMap(payload.data, (function (data) {
                return Belt_Option.map(data.lesson, LessonResponse$NimbusWeb.normalize);
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
