'use strict';

var React = require("react");
var Alert$NimbusWeb = require("../../components/Alert.bs.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");

function ZoomDownloadAlert(Props) {
  var visible = Props.visible;
  var isPrivateOpt = Props.isPrivate;
  var onClickStart = Props.onClickStart;
  var onCloseOpt = Props.onClose;
  var isPrivate = isPrivateOpt !== undefined ? isPrivateOpt : true;
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  return React.createElement(Alert$NimbusWeb.make, {
              visible: visible,
              onClose: onClose,
              children: null
            }, React.createElement(Alert$NimbusWeb.Title.make, {
                  text: ""
                }), React.createElement(Alert$NimbusWeb.Content.make, {
                  text: "As a tutor, you must have Zoom installed on your computer to be able to start a video session on Desktop." + (
                    isPrivate ? " Otherwise please start the session from the mobile app." : ""
                  )
                }), React.createElement(Alert$NimbusWeb.Actions.make, {
                  children: null
                }, React.createElement(Button$NimbusWeb.make, {
                      variant: /* Outlined */2,
                      onClick: (function (param) {
                          window.open("https://zoom.us/download", "_blank");
                          
                        }),
                      text: "Download",
                      accessKey: "d"
                    }), React.createElement(Button$NimbusWeb.make, {
                      variant: /* Contained */1,
                      onClick: onClickStart,
                      text: "Start Meeting",
                      accessKey: "m"
                    })));
}

var make = ZoomDownloadAlert;

exports.make = make;
/* react Not a pure module */
