'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Chat$NimbusWeb = require("../video/Chat.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var MessagesUI$NimbusWeb = require("./MessagesUI.bs.js");
var AuthContext$NimbusWeb = require("../../contexts/AuthContext.bs.js");
var ChatContext$NimbusWeb = require("../video/ChatContext.bs.js");
var GroupsRequest$NimbusWeb = require("./requests/GroupsRequest.bs.js");

function Messages(Props) {
  var match = React.useState(function () {
        
      });
  var setGroupId = match[1];
  var groupId = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setGroups = match$1[1];
  var groups = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setLoading = match$2[1];
  var match$3 = React.useState(function () {
        return window.innerWidth;
      });
  var setWindowWidth = match$3[1];
  var match$4 = React.useContext(AuthContext$NimbusWeb.UserType.context);
  var userType = match$4[0];
  var request = Request$NimbusWeb.use(undefined);
  React.useEffect((function () {
          var body = GroupsRequest$NimbusWeb.make(userType);
          if (body !== undefined) {
            Curry._1(setLoading, (function (param) {
                    return true;
                  }));
            Curry._1(request, Caml_option.valFromOption(body)).then(function (json) {
                    var groups = GroupsRequest$NimbusWeb.$$Response.normalize(Curry._1(GroupsRequest$NimbusWeb.$$Response.Decode.exec, json));
                    Curry._1(setGroups, (function (param) {
                            return groups;
                          }));
                    Curry._1(setLoading, (function (param) {
                            return false;
                          }));
                    return Promise.resolve(undefined);
                  }).catch(function (param) {
                  Curry._1(setLoading, (function (param) {
                          return false;
                        }));
                  return Promise.resolve(undefined);
                });
          }
          var handleResize = function (param) {
            return Curry._1(setWindowWidth, (function (param) {
                          return window.innerWidth;
                        }));
          };
          window.addEventListener("resize", handleResize);
          return (function (param) {
                    window.removeEventListener("resize", handleResize);
                    
                  });
        }), []);
  var users = groups !== undefined ? groups.map(function (g) {
          return {
                  _id: g.userId,
                  first: Belt_Option.getWithDefault(g.first, "Name"),
                  last: Belt_Option.getWithDefault(g.last, "not found"),
                  avatar: g.avatar,
                  uid: 0,
                  uidSS: 0
                };
        }) : [];
  var collapseSidebar = match$3[0] < 700;
  return React.createElement(MessagesUI$NimbusWeb.Container.make, {
              children: null
            }, React.createElement(MessagesUI$NimbusWeb.Sidebar.make, {
                  children: groups !== undefined ? groups.map(function (group) {
                          return React.createElement(MessagesUI$NimbusWeb.GroupItem.make, {
                                      groupId: group.groupId,
                                      name: group.name,
                                      avatar: group.avatar,
                                      onClick: (function (param) {
                                          Curry._1(setGroupId, (function (param) {
                                                  
                                                }));
                                          setTimeout((function (param) {
                                                  return Curry._1(setGroupId, (function (param) {
                                                                return group.groupId;
                                                              }));
                                                }), 0);
                                          
                                        }),
                                      selected: Belt_Option.mapWithDefault(groupId, false, (function (groupId) {
                                              return groupId === group.groupId;
                                            })),
                                      collapseSidebar: collapseSidebar,
                                      key: group.groupId
                                    });
                        }) : React.createElement(MessagesUI$NimbusWeb.Empty.GroupList.make, {
                          loading: match$2[0]
                        }),
                  collapse: collapseSidebar
                }), React.createElement(MessagesUI$NimbusWeb.Content.make, {
                  children: groupId !== undefined ? React.createElement(ChatContext$NimbusWeb.Wrap.make, {
                          children: React.createElement(Chat$NimbusWeb.make, {}),
                          groupId: groupId,
                          users: users,
                          visible: true
                        }) : React.createElement(MessagesUI$NimbusWeb.Empty.GroupUnselected.make, {})
                }));
}

var make = Messages;

exports.make = make;
/* react Not a pure module */
