'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");
var Utils$NimbusWeb = require("../utils/Utils.bs.js");

function getComponent(variant) {
  switch (variant) {
    case /* H1 */0 :
        return "h1";
    case /* H2 */1 :
        return "h2";
    case /* H3 */2 :
        return "h3";
    case /* H4 */3 :
        return "h4";
    case /* H5 */4 :
        return "h5";
    case /* H6 */5 :
    case /* Subtitle1 */6 :
    case /* Subtitle2 */7 :
        return "h6";
    case /* Body1 */8 :
    case /* Body2 */9 :
        return "span";
    
  }
}

function Typography(Props) {
  var variantOpt = Props.variant;
  var colorOpt = Props.color;
  var textAlignOpt = Props.textAlign;
  var classNameOpt = Props.className;
  var value = Props.value;
  var variant = variantOpt !== undefined ? variantOpt : /* Body1 */8;
  var color = colorOpt !== undefined ? colorOpt : /* Gray */7;
  var textAlign = textAlignOpt !== undefined ? textAlignOpt : "left";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var style = {
    color: Theme$NimbusWeb.getColor(color),
    textAlign: textAlign
  };
  return React.createElement(getComponent(variant), {
              className: className,
              style: style
            }, value);
}

function Typography$Shrink(Props) {
  var colorOpt = Props.color;
  var textAlignOpt = Props.textAlign;
  var classNameOpt = Props.className;
  var maxlen = Props.maxlen;
  var value = Props.value;
  var color = colorOpt !== undefined ? colorOpt : /* Gray */7;
  var textAlign = textAlignOpt !== undefined ? textAlignOpt : "left";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var textRef = React.useRef(undefined);
  var match = React.useState(function () {
        return value;
      });
  var setText = match[1];
  var text = match[0];
  var style = {
    color: Theme$NimbusWeb.getColor(color),
    textAlign: textAlign
  };
  React.useEffect((function () {
          var tRef = textRef.current;
          if (tRef !== undefined && Caml_obj.caml_greaterthan(Caml_option.valFromOption(tRef).clientWidth, maxlen)) {
            Curry._1(setText, (function (pt) {
                    var m = pt.length / 2 | 0;
                    var firstHalf = pt.substring(0, m);
                    var secondHalf = pt.substr(m + 1 | 0);
                    return firstHalf + secondHalf;
                  }));
          }
          
        }), [text]);
  return React.createElement("div", {
              ref: Utils$NimbusWeb.setRef(textRef),
              className: className,
              style: style
            }, text);
}

var Shrink = {
  make: Typography$Shrink
};

var make = Typography;

exports.getComponent = getComponent;
exports.make = make;
exports.Shrink = Shrink;
/* react Not a pure module */
