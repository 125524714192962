'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Alert$NimbusWeb = require("../../components/Alert.bs.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var Helmet$NimbusWeb = require("../../components/Helmet.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var Navigation$NimbusWeb = require("../../utils/Navigation.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var EventFilterUI$NimbusWeb = require("./EventFilterUI.bs.js");
var LocaleContext$NimbusWeb = require("../../contexts/LocaleContext.bs.js");
var OrganizationContext$NimbusWeb = require("../../contexts/OrganizationContext.bs.js");
var SelectedCoursesContext$NimbusWeb = require("../../contexts/SelectedCoursesContext.bs.js");
var EventFilterRequest_Fetch_Courses$NimbusWeb = require("./requests/EventFilterRequest_Fetch_Courses.bs.js");
var EventFilterRequest_Fetch_Universities$NimbusWeb = require("./requests/EventFilterRequest_Fetch_Universities.bs.js");
var FetchOrganizationsByUniversityRequest$NimbusWeb = require("../requests/FetchOrganizationsByUniversityRequest.bs.js");
var EventFilterRequest_Fetch_University_By_Id$NimbusWeb = require("./requests/EventFilterRequest_Fetch_University_By_Id.bs.js");

function EventFilter(Props) {
  var universityId = Props.universityId;
  var request = Request$NimbusWeb.use(undefined);
  var match = React.useContext(LocaleContext$NimbusWeb.context);
  var text = match[0];
  var match$1 = React.useContext(OrganizationContext$NimbusWeb.context);
  var setOrganization = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowWblAlert = match$2[1];
  var containerRef = React.useRef(null);
  var tableRef = React.useRef(null);
  var match$3 = React.useState(function () {
        return window.innerHeight;
      });
  var setWindowHeight = match$3[1];
  var windowHeight = match$3[0];
  var match$4 = React.useState(function () {
        return 0;
      });
  var setSpaceHeight = match$4[1];
  var match$5 = React.useState(function () {
        return [];
      });
  var setUniversities = match$5[1];
  var universities = match$5[0];
  var match$6 = React.useState(function () {
        return [];
      });
  var setUniversitiesFiltered = match$6[1];
  var universitiesFiltered = match$6[0];
  var match$7 = React.useState(function () {
        return [];
      });
  var setCourses = match$7[1];
  var courses = match$7[0];
  var match$8 = React.useState(function () {
        return universityId;
      });
  var setUniversityId = match$8[1];
  var universityId$1 = match$8[0];
  var match$9 = React.useState(function () {
        
      });
  var setSelectedUniversity = match$9[1];
  var selectedUniversity = match$9[0];
  var match$10 = React.useContext(SelectedCoursesContext$NimbusWeb.context);
  var setSelectedCoursesContext = match$10[1];
  var match$11 = React.useState(function () {
        return /* Group */0;
      });
  var selectedSessionType = match$11[0];
  var match$12 = React.useState(function () {
        return false;
      });
  var setLoading = match$12[1];
  var loading = match$12[0];
  var filterStep = universityId$1 !== undefined || courses.length !== 0 ? /* Course */1 : /* University */0;
  React.useEffect((function () {
          if (filterStep) {
            
          } else {
            Curry._1(setLoading, (function (param) {
                    return true;
                  }));
            Curry._1(request, EventFilterRequest_Fetch_Universities$NimbusWeb.make(undefined)).then(function (json) {
                  var data = EventFilterRequest_Fetch_Universities$NimbusWeb.$$Response.normalize(Curry._1(EventFilterRequest_Fetch_Universities$NimbusWeb.$$Response.Decode.exec, json));
                  data.sort(function (a, b) {
                        if (a.name < b.name) {
                          return -1;
                        } else {
                          return 1;
                        }
                      });
                  Curry._1(setUniversities, (function (param) {
                          return data;
                        }));
                  Curry._1(setUniversitiesFiltered, (function (param) {
                          return data;
                        }));
                  Curry._1(setLoading, (function (param) {
                          return false;
                        }));
                  return Promise.resolve(undefined);
                });
          }
          var handleWindowResize = function (param) {
            return Curry._1(setWindowHeight, (function (param) {
                          return window.innerHeight;
                        }));
          };
          window.addEventListener("resize", handleWindowResize);
          return (function (param) {
                    window.removeEventListener("resize", handleWindowResize);
                    
                  });
        }), []);
  var fetchCourses = React.useCallback((function (search) {
          if (universityId$1 !== undefined) {
            Curry._1(setLoading, (function (param) {
                    return true;
                  }));
            Curry._1(request, EventFilterRequest_Fetch_Courses$NimbusWeb.make(universityId$1, search)).then(function (json) {
                  var data = EventFilterRequest_Fetch_Courses$NimbusWeb.$$Response.normalize(Curry._1(EventFilterRequest_Fetch_Courses$NimbusWeb.$$Response.Decode.exec, json));
                  data.sort(function (a, b) {
                        if (a.subject < b.subject) {
                          return -1;
                        } else if (a.subject > b.subject) {
                          return 1;
                        } else if (a.number < b.number) {
                          return -1;
                        } else {
                          return 0;
                        }
                      });
                  Curry._1(setCourses, (function (param) {
                          return Curry._1(EventFilterUI$NimbusWeb.Course.Table.createTableData, data);
                        }));
                  Curry._1(setLoading, (function (param) {
                          return false;
                        }));
                  setTimeout((function (param) {
                          return Belt_Option.forEach(Caml_option.nullable_to_opt(containerRef.current), (function (container) {
                                        container.scrollTop = 160;
                                        
                                      }));
                        }), 0);
                  return Promise.resolve(undefined);
                });
          } else {
            console.log("Institution not selected");
          }
          
        }), [universityId$1]);
  React.useEffect((function () {
          setTimeout((function (param) {
                  var table = tableRef.current;
                  var spaceHeight = windowHeight - (500 + (
                      !(table == null) ? table.offsetHeight : 0
                    ) | 0) | 0;
                  return Curry._1(setSpaceHeight, (function (param) {
                                return spaceHeight;
                              }));
                }), 0);
          
        }), [
        windowHeight,
        courses
      ]);
  React.useEffect((function () {
          if (universityId$1 !== undefined) {
            Curry._1(fetchCourses, "");
            var university = universities.find(function (university) {
                  return university._id === universityId$1;
                });
            if (university !== undefined) {
              Curry._1(setSelectedUniversity, (function (param) {
                      return university;
                    }));
            } else {
              Curry._1(setLoading, (function (param) {
                      return true;
                    }));
              Curry._1(request, EventFilterRequest_Fetch_University_By_Id$NimbusWeb.make(universityId$1)).then(function (json) {
                    var data = EventFilterRequest_Fetch_University_By_Id$NimbusWeb.$$Response.normalize(Curry._1(EventFilterRequest_Fetch_University_By_Id$NimbusWeb.$$Response.Decode.exec, json));
                    Curry._1(setSelectedUniversity, (function (param) {
                            return data;
                          }));
                    Curry._1(setLoading, (function (param) {
                            return false;
                          }));
                    return Promise.resolve(undefined);
                  });
            }
          }
          
        }), [universityId$1]);
  var onSearchUniversity = React.useCallback((function (search) {
          return Curry._1(setUniversitiesFiltered, (function (param) {
                        return universities.filter(function (university) {
                                    return university.name.includes(search);
                                  });
                      }));
        }), [universities]);
  var onClickUniversity = function (universityId) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    Curry._1(request, FetchOrganizationsByUniversityRequest$NimbusWeb.make(universityId)).then(function (json) {
            var data = FetchOrganizationsByUniversityRequest$NimbusWeb.$$Response.normalize(Curry._1(FetchOrganizationsByUniversityRequest$NimbusWeb.$$Response.Decode.exec, json));
            if (data === undefined) {
              return Promise.resolve(undefined);
            }
            var organizationWithColor = data.find(function (o) {
                  return Belt_Option.isSome(o.colors);
                });
            var organizationWithColor$1 = organizationWithColor === undefined ? undefined : Caml_option.some(organizationWithColor);
            Curry._1(setOrganization, (function (param) {
                    return organizationWithColor$1;
                  }));
            return Promise.resolve(undefined);
          }).then(function (param) {
          Curry._1(setLoading, (function (param) {
                  return false;
                }));
          Curry._1(setUniversityId, (function (param) {
                  return universityId;
                }));
          return Promise.resolve(undefined);
        });
    
  };
  var onClickCourse = function (course) {
    if (selectedSessionType) {
      var url = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(course, "externalUrl"), Js_json.decodeString), "https://demo.seekshare.io");
      window.open(url, "_blank");
      return ;
    }
    Curry._1(setSelectedCoursesContext, (function (param) {
            return [course];
          }));
    return Navigation$NimbusWeb.push("/view");
  };
  var tmp;
  if (filterStep) {
    var match$13 = courses.length === 0;
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: "event-filter-course-space"
            }), React.createElement("div", {
              className: "event-filter-course-background"
            }, React.createElement(Typography$NimbusWeb.make, {
                  variant: /* H1 */0,
                  className: "event-filter-title",
                  value: Curry._3(text, "event_filter.course.prompt", [[
                          "university",
                          Belt_Option.mapWithDefault(selectedUniversity, "", (function (u) {
                                  return u.name;
                                }))
                        ]], undefined)
                }), React.createElement(EventFilterUI$NimbusWeb.Course.SessionType.make, {
                  value: selectedSessionType,
                  onClick: match$11[1],
                  dropInTag: Belt_Option.map(Belt_Option.flatMap(match$1[0], (function (organization) {
                              return organization.dropInTag;
                            })), (function (tag) {
                          return tag.en;
                        }))
                }), React.createElement(EventFilterUI$NimbusWeb.Search.make, {
                  onEnter: fetchCourses,
                  placeholder: "Search Courses"
                }), loading ? null : (
                match$13 ? React.createElement(EventFilterUI$NimbusWeb.Course.Empty.make, {
                        text: Curry._3(text, "event_filter.course.empty", undefined, undefined)
                      }) : React.createElement(React.Fragment, undefined, selectedSessionType ? null : React.createElement(Button$NimbusWeb.make, {
                              className: "event-filter-course-wbl",
                              onClick: (function (param) {
                                  return Curry._1(setShowWblAlert, (function (param) {
                                                return true;
                                              }));
                                }),
                              title: "Reaching the booking limit will prevent you from booking tutors. The booking limit resets every Sunday at 12:00 am.",
                              text: "Weekly booking limit: 1 / 2 hours remaining",
                              accessKey: "l"
                            }), React.createElement(EventFilterUI$NimbusWeb.Course.List.make, {
                            data: courses,
                            ref_: tableRef,
                            onClick: onClickCourse,
                            selectedSessionType: selectedSessionType
                          }))
              )), windowHeight > 450 ? React.createElement("div", {
                style: {
                  height: String(match$4[0]) + "px",
                  width: "100%"
                }
              }) : null, React.createElement(Alert$NimbusWeb.make, {
              visible: match$2[0],
              onClose: (function (param) {
                  return Curry._1(setShowWblAlert, (function (param) {
                                return false;
                              }));
                }),
              children: React.createElement(Alert$NimbusWeb.Content.make, {
                    text: "Reaching the booking limit will prevent you from booking tutors. The booking limit resets every Sunday at 12:00 am."
                  })
            }));
  } else {
    var match$14 = universitiesFiltered.length === 0;
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: "event-filter-university-title-search"
            }, React.createElement(Typography$NimbusWeb.make, {
                  variant: /* H1 */0,
                  className: "event-filter-title",
                  value: Curry._3(text, "event_filter.university.prompt", undefined, undefined)
                }), React.createElement(EventFilterUI$NimbusWeb.Search.make, {
                  onEnter: onSearchUniversity,
                  placeholder: "Search University"
                })), loading ? null : (
            match$14 ? React.createElement(EventFilterUI$NimbusWeb.University.Empty.make, {
                    text: Curry._3(text, "event_filter.university.empty", undefined, undefined)
                  }) : React.createElement(EventFilterUI$NimbusWeb.University.List.make, {
                    data: universitiesFiltered,
                    onClick: onClickUniversity
                  })
          ));
  }
  return React.createElement(React.Fragment, undefined, filterStep ? React.createElement("img", {
                    className: "event-filter-cover-photo",
                    alt: "University cover photo",
                    src: Belt_Option.mapWithDefault(selectedUniversity, "", (function (u) {
                            return Belt_Option.getWithDefault(u.photo, "");
                          }))
                  }) : null, React.createElement(EventFilterUI$NimbusWeb.Container.make, {
                  children: null,
                  ref_: containerRef
                }, React.createElement(Helmet$NimbusWeb.make, {
                      title: "Nimbus Learning - Find sessions"
                    }), tmp));
}

var make = EventFilter;

exports.make = make;
/* react Not a pure module */
