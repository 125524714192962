'use strict';

var Caml_array = require("bs-platform/lib/js/caml_array.js");

var majorVersion = parseInt(navigator.appVersion, 10);

function contains(s1, s2) {
  return s1.indexOf(s2) !== -1;
}

function lastI(s1, s2) {
  return s1.lastIndexOf(s2);
}

var browserName;

if (contains(navigator.userAgent, "Chrome")) {
  browserName = /* Chrome */0;
} else if (contains(navigator.userAgent, "MSIE")) {
  browserName = /* MSIE */1;
} else if (contains(navigator.userAgent, "Firefox")) {
  browserName = /* Firefox */2;
} else if (contains(navigator.userAgent, "Safari")) {
  browserName = /* Safari */3;
} else if ((navigator.userAgent.lastIndexOf(" ") + 1 | 0) < navigator.userAgent.lastIndexOf("/")) {
  var offsetName = navigator.userAgent.lastIndexOf(" ") + 1 | 0;
  var offsetVersion = navigator.userAgent.lastIndexOf("/");
  var name = navigator.userAgent.substring(offsetName, offsetVersion);
  browserName = name.toLowerCase() === name.toUpperCase() ? /* Other */({
        _0: navigator.appName
      }) : /* Other */({
        _0: name
      });
} else {
  browserName = /* Other */{
    _0: navigator.appName
  };
}

function getNameString(browser) {
  if (typeof browser !== "number") {
    return browser._0;
  }
  switch (browser) {
    case /* Chrome */0 :
        return "Chrome";
    case /* MSIE */1 :
        return "Internet Explorer";
    case /* Firefox */2 :
        return "Firefox";
    case /* Safari */3 :
        return "Safari";
    
  }
}

var locale = Caml_array.get(navigator.language.split("-"), 0);

exports.majorVersion = majorVersion;
exports.contains = contains;
exports.lastI = lastI;
exports.browserName = browserName;
exports.getNameString = getNameString;
exports.locale = locale;
/* majorVersion Not a pure module */
