'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Badge(Props) {
  var visible = Props.visible;
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : /* Secondary */1;
  var color$1 = Theme$NimbusWeb.getColor(color);
  if (visible) {
    return React.createElement("div", {
                style: {
                  backgroundColor: color$1,
                  height: "12px",
                  width: "12px",
                  borderRadius: "50%"
                }
              });
  } else {
    return null;
  }
}

var make = Badge;

exports.make = make;
/* react Not a pure module */
