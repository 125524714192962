'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Alert$NimbusWeb = require("../components/Alert.bs.js");
var Button$NimbusWeb = require("../components/Button.bs.js");
var Browser$NimbusWeb = require("../utils/Browser.bs.js");
var LocaleContext$NimbusWeb = require("./LocaleContext.bs.js");

function BrowserSupported(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return [
                false,
                true
              ];
      });
  var setShow = match[1];
  var show = match[0];
  var match$1 = React.useContext(LocaleContext$NimbusWeb.context);
  var text = match$1[0];
  React.useEffect((function () {
          var shouldShow;
          if (typeof Browser$NimbusWeb.browserName === "number") {
            if (Browser$NimbusWeb.browserName !== 0) {
              switch (Browser$NimbusWeb.browserName - 1 | 0) {
                case /* Chrome */0 :
                    shouldShow = true;
                    break;
                case /* MSIE */1 :
                case /* Firefox */2 :
                    shouldShow = false;
                    break;
                
              }
            } else {
              shouldShow = false;
            }
          } else {
            shouldShow = true;
          }
          Curry._1(setShow, (function (param) {
                  return [
                          shouldShow,
                          true
                        ];
                }));
          
        }), []);
  var openChrome = function (param) {
    window.open("https://www.google.ca/chrome/?brand=CHBD&gclid=EAIaIQobChMI9Pfd14DE7AIVh4bACh21QwdQEAAYASAAEgK6IvD_BwE&gclsrc=aw.ds", "_blank");
    
  };
  var openFirefox = function (param) {
    window.open("https://www.mozilla.org/en-CA/firefox/new/", "_blank");
    
  };
  var content = show[0] ? Curry._3(text, "browser.not_supported", undefined, undefined) : (
      show[1] ? "Works fine?" : Curry._3(text, "browser.system_not_met", undefined, undefined)
    );
  return React.createElement(React.Fragment, undefined, children, React.createElement(Alert$NimbusWeb.make, {
                  visible: show[0] || !show[1],
                  onClose: (function (param) {
                      return Curry._1(setShow, (function (param) {
                                    return [
                                            true,
                                            true
                                          ];
                                  }));
                    }),
                  children: null,
                  canExit: false
                }, React.createElement(Alert$NimbusWeb.Title.make, {
                      text: Curry._3(text, "browser.not_supported.title", undefined, undefined)
                    }), React.createElement(Alert$NimbusWeb.Content.make, {
                      text: content
                    }), React.createElement(Alert$NimbusWeb.Actions.make, {
                      children: null
                    }, React.createElement(Button$NimbusWeb.make, {
                          onClick: openChrome,
                          text: Curry._3(text, "browser.download.chrome", undefined, undefined),
                          accessKey: "c"
                        }), React.createElement(Button$NimbusWeb.make, {
                          onClick: openFirefox,
                          text: Curry._3(text, "browser.download.firefox", undefined, undefined),
                          accessKey: "f"
                        }))));
}

var make = BrowserSupported;

exports.make = make;
/* react Not a pure module */
