'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var EventFilterResponse$NimbusWeb = require("./EventFilterResponse.bs.js");

function make(param) {
  return Request$NimbusWeb.createBody("{\n    universitiesWithDropins {\n      _id\n      name\n      photo\n      photoHome\n    }\n  }", []);
}

function decodeData(json) {
  return {
          universities: Json_decode.field("universitiesWithDropins", (function (param) {
                  return Json_decode.array(EventFilterResponse$NimbusWeb.Decode.university, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeData: decodeData,
  exec: exec
};

function normalize(payload) {
  return Belt_Option.mapWithDefault(payload.data, [], (function (data) {
                return data.universities;
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
