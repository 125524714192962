'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var ReactDom = require("react-dom");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var QrcodeReact = require("qrcode.react");
var A11y$NimbusWeb = require("../../utils/A11y.bs.js");
var Chip$NimbusWeb = require("../../components/Chip.bs.js");
var Alert$NimbusWeb = require("../../components/Alert.bs.js");
var Table$NimbusWeb = require("../../components/Table.bs.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var Locale$NimbusWeb = require("../../utils/Locale.bs.js");
var Icon_Book$NimbusWeb = require("../../icons/Icon_Book.bs.js");
var TextField$NimbusWeb = require("../../components/TextField.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");

require("./EventFilter.css");

function EventFilterUI$Container(Props) {
  var children = Props.children;
  var ref_ = Props.ref_;
  return React.createElement("div", {
              ref: ref_,
              className: "event-filter-container"
            }, React.createElement("div", {
                  className: "event-filter-spacing"
                }), children);
}

var Container = {
  make: EventFilterUI$Container
};

function EventFilterUI$Search(Props) {
  var onEnter = Props.onEnter;
  var placeholder = Props.placeholder;
  var match = React.useState(function () {
        return "";
      });
  var setSearchText = match[1];
  var searchText = match[0];
  return React.createElement(TextField$NimbusWeb.make, {
              className: "event-filter-search",
              type_: "text",
              placeholder: placeholder,
              value: searchText,
              onChange: (function (e) {
                  e.persist();
                  return Curry._1(setSearchText, (function (param) {
                                return e.target.value;
                              }));
                }),
              onKeyPress: (function (e) {
                  if (e.key === "Enter") {
                    return Curry._1(onEnter, searchText);
                  }
                  
                }),
              label: "search"
            });
}

var Search = {
  make: EventFilterUI$Search
};

function EventFilterUI$University$Item$Image(Props) {
  var src = Props.src;
  var alt = Props.alt;
  return React.createElement("img", {
              className: "event-filter-uni-image",
              alt: alt,
              src: src
            });
}

var $$Image = {
  make: EventFilterUI$University$Item$Image
};

function EventFilterUI$University$Item(Props) {
  var name = Props.name;
  var photoHome = Props.photoHome;
  var photo = Props.photo;
  var onClick = Props.onClick;
  var accessKey = Props.accessKey;
  var tmp;
  var exit = 0;
  if (photoHome !== undefined && photoHome !== "") {
    tmp = React.createElement(EventFilterUI$University$Item$Image, {
          src: photoHome,
          alt: name
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var exit$1 = 0;
    if (photo !== undefined && photo !== "") {
      tmp = React.createElement(EventFilterUI$University$Item$Image, {
            src: photo,
            alt: name
          });
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      tmp = React.createElement("div", {
            className: "event-filter-uni-image"
          });
    }
    
  }
  return React.createElement("button", {
              accessKey: accessKey,
              className: "event-filter-uni-container",
              tabIndex: 0,
              onClick: onClick
            }, React.createElement(Typography$NimbusWeb.make, {
                  color: /* Gray1 */8,
                  className: "event-filter-uni-name",
                  value: name
                }), tmp);
}

var Item = {
  $$Image: $$Image,
  make: EventFilterUI$University$Item
};

function EventFilterUI$University$List(Props) {
  var data = Props.data;
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: "event-filter-list-container"
            }, data.map(function (university, i) {
                  return React.createElement(EventFilterUI$University$Item, {
                              name: university.name,
                              photoHome: university.photoHome,
                              photo: university.photo,
                              onClick: (function (param) {
                                  return Curry._1(onClick, university._id);
                                }),
                              accessKey: A11y$NimbusWeb.getAccessKey(i + 8 | 0),
                              key: university._id
                            });
                }));
}

var List = {
  make: EventFilterUI$University$List
};

function EventFilterUI$University$Empty(Props) {
  var text = Props.text;
  return React.createElement("div", undefined, React.createElement(Typography$NimbusWeb.make, {
                  color: /* Gray */7,
                  value: text
                }));
}

var Empty = {
  make: EventFilterUI$University$Empty
};

var University = {
  Item: Item,
  List: List,
  Empty: Empty
};

function EventFilterUI$Course$Empty(Props) {
  var text = Props.text;
  return React.createElement("div", undefined, React.createElement(Typography$NimbusWeb.make, {
                  color: /* Gray */7,
                  value: text
                }));
}

var Empty$1 = {
  make: EventFilterUI$Course$Empty
};

function getUrl(phoneType) {
  if (phoneType) {
    return "https://apps.apple.com/" + phoneType._0 + "/app/nimbus/id1435620212?l=en";
  } else {
    return "https://play.google.com/store/apps/details?id=com.nimbustutoringinc";
  }
}

function EventFilterUI$Course$SessionType$DownloadMobile(Props) {
  var phoneType = Props.phoneType;
  return React.createElement("div", {
              style: {
                display: "flex",
                alignItems: "center",
                flexDirection: "column"
              }
            }, React.createElement(QrcodeReact.QRCodeSVG, {
                  value: getUrl(phoneType),
                  size: 64
                }), React.createElement(Typography$NimbusWeb.make, {
                  value: phoneType ? "iOS" : "Android"
                }));
}

var DownloadMobile = {
  getUrl: getUrl,
  make: EventFilterUI$Course$SessionType$DownloadMobile
};

function EventFilterUI$Course$SessionType(Props) {
  var value = Props.value;
  var onClick = Props.onClick;
  var dropInTag = Props.dropInTag;
  var match = React.useState(function () {
        return false;
      });
  var setShowGetAppAlert = match[1];
  var countryCode = Locale$NimbusWeb.useUserCountryCode(undefined);
  return React.createElement("div", {
              className: "event-filter-course-session-button-container"
            }, React.createElement(Button$NimbusWeb.make, {
                  variant: /* Outlined */2,
                  className: "event-filter-course-session-button",
                  onClick: (function (param) {
                      return Curry._1(setShowGetAppAlert, (function (param) {
                                    return true;
                                  }));
                    }),
                  title: "Appointment",
                  text: "Appointment",
                  accessKey: "x"
                }), React.createElement(Button$NimbusWeb.make, {
                  variant: value ? /* Outlined */2 : /* Contained */1,
                  className: "event-filter-course-session-button",
                  onClick: (function (param) {
                      return Curry._1(onClick, (function (param) {
                                    return /* Group */0;
                                  }));
                    }),
                  title: Belt_Option.getWithDefault(dropInTag, "Group"),
                  text: Belt_Option.getWithDefault(dropInTag, "Group"),
                  accessKey: "y"
                }), React.createElement(Button$NimbusWeb.make, {
                  variant: value ? /* Contained */1 : /* Outlined */2,
                  className: "event-filter-course-session-button",
                  onClick: (function (param) {
                      return Curry._1(onClick, (function (param) {
                                    return /* Quiz */1;
                                  }));
                    }),
                  title: "Practice Tests",
                  text: "Practice Tests",
                  accessKey: "z"
                }), React.createElement(Alert$NimbusWeb.make, {
                  visible: match[0],
                  onClose: (function (param) {
                      return Curry._1(setShowGetAppAlert, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: null
                }, React.createElement(Alert$NimbusWeb.Content.make, {
                      text: "Please download the Nimbus Learning mobile application to book appointment session"
                    }), React.createElement("div", {
                      style: {
                        display: "flex",
                        marginBottom: "24px",
                        width: "100%",
                        justifyContent: "space-around"
                      }
                    }, React.createElement(EventFilterUI$Course$SessionType$DownloadMobile, {
                          phoneType: /* IOS */{
                            _0: countryCode
                          }
                        }), React.createElement(EventFilterUI$Course$SessionType$DownloadMobile, {
                          phoneType: /* Android */0
                        }))));
}

var SessionType = {
  DownloadMobile: DownloadMobile,
  make: EventFilterUI$Course$SessionType
};

function EventFilterUI$Course$List$Item(Props) {
  var code = Props.code;
  var title = Props.title;
  var availableTutors = Props.availableTutors;
  var onClick = Props.onClick;
  var selectedSessionType = Props.selectedSessionType;
  var match = selectedSessionType ? [
      "Tests",
      availableTutors + 5 | 0
    ] : [
      availableTutors === 1 ? "Tutor" : "Tutors",
      availableTutors
    ];
  var availableTutors$1 = String(match[1]);
  return React.createElement("div", {
              className: "event-filter-course-item-container",
              role: "button",
              onClick: onClick
            }, React.createElement(Icon_Book$NimbusWeb.make, {
                  color: /* Primary */0
                }), React.createElement("div", {
                  className: "event-filter-course-item-info"
                }, React.createElement(Typography$NimbusWeb.make, {
                      className: "event-filter-course-item-code",
                      value: code
                    }), React.createElement(Typography$NimbusWeb.make, {
                      value: title
                    })), React.createElement("div", {
                  className: "event-filter-course-item-tutors"
                }, React.createElement(Typography$NimbusWeb.make, {
                      className: "",
                      value: availableTutors$1
                    }), React.createElement(Typography$NimbusWeb.make, {
                      className: "event-filter-course-item-label",
                      value: "Available " + match[0]
                    })));
}

var Item$1 = {
  make: EventFilterUI$Course$List$Item
};

function EventFilterUI$Course$List(Props) {
  var data = Props.data;
  var ref_ = Props.ref_;
  var onClick = Props.onClick;
  var selectedSessionType = Props.selectedSessionType;
  return React.createElement("div", {
              ref: ref_
            }, data.map(function (d, i) {
                  return React.createElement(EventFilterUI$Course$List$Item, {
                              code: Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(d, "code"), Js_json.decodeString), "Unavailable Course"),
                              title: Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(d, "title"), Js_json.decodeString), "Unavailable Title"),
                              availableTutors: Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(d, "available_tutors"), Js_json.decodeNumber), 0.0) | 0,
                              onClick: (function (param) {
                                  return Curry._1(onClick, d);
                                }),
                              selectedSessionType: selectedSessionType,
                              key: String(i)
                            });
                }));
}

var List$1 = {
  Item: Item$1,
  make: EventFilterUI$Course$List
};

var tableHeader = [
  {
    key: "code",
    label: "Course Code"
  },
  {
    key: "title",
    label: "Title"
  },
  {
    key: "available_tutors",
    label: "Available Tutors"
  }
];

function createTableData(data) {
  return Belt_Array.map(data, (function (d) {
                var dict = {};
                var subject = d.subject;
                var number = d.number;
                dict["_id"] = d._id;
                dict["code"] = "" + subject + " " + number;
                dict["title"] = d.title;
                dict["available_tutors"] = Belt_Option.getWithDefault(d.availableTutorsCount, 0);
                var externalUrl = d.externalUrl;
                if (externalUrl !== undefined) {
                  dict["externalUrl"] = externalUrl;
                }
                return dict;
              }));
}

function EventFilterUI$Course$Table(Props) {
  var data = Props.data;
  var onClickRow = Props.onClickRow;
  var selected = Props.selected;
  var ref_ = Props.ref_;
  return React.createElement(Table$NimbusWeb.make, {
              header: tableHeader,
              data: data,
              hover: true,
              onClickRow: onClickRow,
              selected: selected,
              ref_: ref_
            });
}

var Table = {
  tableHeader: tableHeader,
  createTableData: createTableData,
  make: EventFilterUI$Course$Table
};

function EventFilterUI$Course$Selected(Props) {
  var courses = Props.courses;
  var visible = Props.visible;
  var onClose = Props.onClose;
  var onRemove = Props.onRemove;
  return React.createElement(Alert$NimbusWeb.make, {
              visible: visible,
              onClose: onClose,
              children: null
            }, React.createElement(Alert$NimbusWeb.Title.make, {
                  text: "Selected"
                }), React.createElement("div", {
                  className: "event-filter-alert-content"
                }, Belt_Array.map(courses, (function (course) {
                        var courseId = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(course, "_id"), Js_json.decodeString), "Invalid");
                        return React.createElement("div", {
                                    key: courseId,
                                    className: "event-filter-alert-content-item"
                                  }, React.createElement(Chip$NimbusWeb.make, {
                                        text: Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(course, "code"), Js_json.decodeString), "Invalid"),
                                        onRemove: (function (param) {
                                            return Curry._1(onRemove, courseId);
                                          })
                                      }));
                      }))));
}

var Selected = {
  make: EventFilterUI$Course$Selected
};

var root = document.getElementById("root");

function EventFilterUI$Course$Actions(Props) {
  var courses = Props.courses;
  var onRemove = Props.onRemove;
  var onFind = Props.onFind;
  var match = React.useState(function () {
        return false;
      });
  var setShowSelected = match[1];
  return ReactDom.createPortal(React.createElement("div", {
                  className: "event-filter-actions-container"
                }, React.createElement(Button$NimbusWeb.make, {
                      onClick: (function (param) {
                          return Curry._1(setShowSelected, (function (param) {
                                        return true;
                                      }));
                        }),
                      text: String(courses.length) + " Selected",
                      accessKey: "e"
                    }), React.createElement(Button$NimbusWeb.make, {
                      variant: /* Contained */1,
                      className: "event-filter-actions-find-button",
                      onClick: onFind,
                      text: "Find Sessions",
                      accessKey: "a"
                    }), React.createElement(EventFilterUI$Course$Selected, {
                      courses: courses,
                      visible: match[0],
                      onClose: (function (param) {
                          return Curry._1(setShowSelected, (function (param) {
                                        return false;
                                      }));
                        }),
                      onRemove: onRemove
                    })), root);
}

var Actions = {
  root: root,
  make: EventFilterUI$Course$Actions
};

var Course = {
  Empty: Empty$1,
  SessionType: SessionType,
  List: List$1,
  Table: Table,
  Selected: Selected,
  Actions: Actions
};

exports.Container = Container;
exports.Search = Search;
exports.University = University;
exports.Course = Course;
/*  Not a pure module */
