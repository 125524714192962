'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Context$NimbusWeb = require("./Context.bs.js");
var Request$NimbusWeb = require("../utils/Request.bs.js");
var DropInStudentsRequest_Fetch$NimbusWeb = require("../scenes/lesson/requests/DropInStudentsRequest_Fetch.bs.js");
var DropInStudentsRequest_FetchById$NimbusWeb = require("../scenes/lesson/requests/DropInStudentsRequest_FetchById.bs.js");

var defaultValue_dropInStudents = [];

function defaultValue_setDropInStudents(param) {
  
}

function defaultValue_updateOne(param) {
  
}

function defaultValue_fetchById(param) {
  
}

function defaultValue_fetchList(param) {
  return Promise.resolve([]);
}

var defaultValue = {
  dropInStudents: defaultValue_dropInStudents,
  setDropInStudents: defaultValue_setDropInStudents,
  updateOne: defaultValue_updateOne,
  fetchById: defaultValue_fetchById,
  fetchList: defaultValue_fetchList
};

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function DropInStudentsContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return [];
      });
  var setDropInStudents = match[1];
  var request = Request$NimbusWeb.use(undefined);
  var updateOne = function (dropInStudent) {
    return Curry._1(setDropInStudents, (function (state) {
                  var newState = state.filter(function (item) {
                        return item._id !== dropInStudent._id;
                      });
                  newState.push(dropInStudent);
                  return newState;
                }));
  };
  var fetchById = function (dropInStudentsId) {
    Curry._1(request, DropInStudentsRequest_FetchById$NimbusWeb.make(dropInStudentsId)).then(function (json) {
          var res = DropInStudentsRequest_FetchById$NimbusWeb.$$Response.normalize(Curry._1(DropInStudentsRequest_FetchById$NimbusWeb.$$Response.Decode.exec, json));
          if (res !== undefined) {
            updateOne(res);
          }
          return Promise.resolve(undefined);
        });
    
  };
  var fetchList = function (args) {
    return Curry._1(request, DropInStudentsRequest_Fetch$NimbusWeb.make(args)).then(function (json) {
                return Promise.resolve(DropInStudentsRequest_Fetch$NimbusWeb.$$Response.normalize(Curry._1(DropInStudentsRequest_Fetch$NimbusWeb.$$Response.Decode.exec, json)));
              });
  };
  var api_dropInStudents = match[0];
  var api = {
    dropInStudents: api_dropInStudents,
    setDropInStudents: setDropInStudents,
    updateOne: updateOne,
    fetchById: fetchById,
    fetchList: fetchList
  };
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, api, children, undefined));
}

var Wrap = {
  make: DropInStudentsContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
