'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_Send(Props) {
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : /* Primary */0;
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: "18",
              width: "19",
              fill: "none",
              viewBox: "0 0 19 18",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M17.9353 8.20597L2.01745 0.266117C1.84058 0.177896 1.57529 0.177896 1.30999 0.266117C0.867824 0.442558 0.690959 0.971881 0.867824 1.41298L4.22827 8.99995L0.867824 16.5869C0.690959 17.028 0.867824 17.5573 1.30999 17.7338C1.57529 17.822 1.84058 17.822 2.01745 17.7338L17.9353 9.79394C18.2006 9.6175 18.3775 9.35283 18.3775 8.99995C18.4659 8.64707 18.2891 8.38241 17.9353 8.20597ZM3.43237 15.0872L5.82006 9.88216H13.8674L3.43237 15.0872ZM5.82006 8.11775L3.43237 2.91273L13.8674 8.11775H5.82006Z",
                  fill: c
                }));
}

var make = Icon_Send;

exports.make = make;
/* react Not a pure module */
