'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var DropInResponse$NimbusWeb = require("./DropInResponse.bs.js");

function make(id) {
  var variables = [{
      key: "dropInId",
      val: id
    }];
  return Request$NimbusWeb.createBody("\n    query (\$dropInId: ID!) {\n      fetchDropInById(_drop_in: \$dropInId) {\n        _id\n        title\n        description\n        host { _id first last avatar uid uidSS }\n        access_level\n        online\n        location { name address }\n      }\n    }\n  ", variables);
}

function dData(json) {
  return {
          dropIn: Json_decode.optional((function (param) {
                  return Json_decode.field("fetchDropInById", DropInResponse$NimbusWeb.Decode.dT, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", dData, param);
                }), json)
        };
}

var Decode = {
  dData: dData,
  exec: exec
};

function normalize(payload) {
  return Belt_Option.flatMap(payload.data, (function (data) {
                return data.dropIn;
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
