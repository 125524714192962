'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_Title(Props) {
  var colorOpt = Props.color;
  var widthOpt = Props.width;
  var heightOpt = Props.height;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var width = widthOpt !== undefined ? widthOpt : "14";
  var height = heightOpt !== undefined ? heightOpt : "14";
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: height + "px",
              width: width + "px",
              fill: "none",
              viewBox: "0 0 " + (width + (" " + height)),
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M13.5536 0H7.0014H0.446361C0.199318 0 0 0.199759 0 0.447347V3.49156C0 3.73915 0.199318 3.93891 0.446361 3.93891H0.496892C0.72709 3.93891 0.917987 3.76166 0.940445 3.53095C1.00501 2.86978 1.15099 2.25643 1.37838 1.93288C1.65069 1.54743 1.96792 1.30828 2.32444 1.22387C2.68378 1.13666 3.13575 1.09164 3.68037 1.09164H5.17947C5.42651 1.09164 5.62583 1.2914 5.62583 1.53899V11.5719C5.62583 12.1375 5.51073 12.5342 5.28053 12.7677C5.09244 12.9534 4.71907 13.0687 4.16042 13.1053C3.9218 13.1222 3.73371 13.3135 3.73371 13.5527C3.73371 13.8002 3.93303 14 4.18007 14H6.9986H9.81712C10.0642 14 10.2635 13.8002 10.2635 13.5527C10.2635 13.3135 10.0754 13.1222 9.83678 13.1053C9.27532 13.0687 8.90194 12.9562 8.71666 12.7677C8.48646 12.5342 8.37137 12.1375 8.37137 11.5719V1.5418C8.37137 1.29421 8.57068 1.09445 8.81773 1.09445H10.3168C10.8614 1.09445 11.3134 1.13947 11.6727 1.22669C12.0321 1.31109 12.3465 1.55024 12.6188 1.9385C12.8462 2.26206 12.9922 2.8754 13.0567 3.53658C13.082 3.76166 13.2729 3.93891 13.5031 3.93891H13.5536C13.8007 3.93891 14 3.73915 14 3.49156V0.447347C14 0.199759 13.8007 0 13.5536 0Z",
                  fill: c
                }));
}

var make = Icon_Title;

exports.make = make;
/* react Not a pure module */
