'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Context$NimbusWeb = require("./Context.bs.js");

var nimbusLogo = require("@assets/nimbus_logo.png").default;

var defaultName = "Nimbus";

function defaultValue_3(param) {
  
}

function defaultValue_4(param) {
  
}

function defaultValue_5(param) {
  
}

function defaultValue_6(param) {
  
}

var defaultValue = [
  true,
  nimbusLogo,
  defaultName,
  defaultValue_3,
  defaultValue_4,
  defaultValue_5,
  defaultValue_6
];

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function BrandContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return nimbusLogo;
      });
  var setIcon = match[1];
  var match$1 = React.useState(function () {
        return defaultName;
      });
  var setName = match$1[1];
  var match$2 = React.useState(function () {
        return true;
      });
  var setIsDefault = match$2[1];
  var restoreDefault = function (param) {
    Curry._1(setIcon, (function (param) {
            return nimbusLogo;
          }));
    Curry._1(setName, (function (param) {
            return defaultName;
          }));
    return Curry._1(setIsDefault, (function (param) {
                  return true;
                }));
  };
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, [
                  match$2[0],
                  match[0],
                  match$1[0],
                  setIsDefault,
                  setIcon,
                  setName,
                  restoreDefault
                ], children, undefined));
}

var Wrap = {
  make: BrandContext$Wrap
};

var defaultIcon = nimbusLogo;

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.nimbusLogo = nimbusLogo;
exports.defaultIcon = defaultIcon;
exports.defaultName = defaultName;
exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* nimbusLogo Not a pure module */
