'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_Clock(Props) {
  var colorOpt = Props.color;
  var widthOpt = Props.width;
  var heightOpt = Props.height;
  var color = colorOpt !== undefined ? colorOpt : /* Black */11;
  var width = widthOpt !== undefined ? widthOpt : "25";
  var height = heightOpt !== undefined ? heightOpt : "25";
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: height,
              width: width,
              fill: "none",
              viewBox: "0 0 " + (width + (" " + height)),
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M12.5 22.8897C6.76218 22.8897 2.11208 18.2378 2.11208 12.5018C2.11208 6.76394 6.76218 2.11208 12.5 2.11208C18.2378 2.11208 22.8879 6.76394 22.8879 12.5018C22.8879 18.2378 18.2378 22.8897 12.5 22.8897ZM12.5 0C19.403 0 25 5.59702 25 12.5018C25 19.4047 19.403 25 12.5 25C5.59701 25 0 19.4047 0 12.5018C0 5.59702 5.59701 0 12.5 0ZM12.5 4.22504C11.9157 4.22504 11.444 4.6985 11.444 5.28284V12.485C11.444 12.4868 11.444 12.4886 11.444 12.4903C11.4422 12.7649 11.5425 13.0395 11.752 13.2489L16.8562 18.3513C17.0621 18.559 17.3314 18.6611 17.6024 18.6611C17.8717 18.6611 18.1428 18.559 18.3487 18.3513C18.7606 17.9395 18.7606 17.2707 18.3487 16.8588L13.556 12.0661V5.28284C13.556 4.6985 13.0843 4.22504 12.5 4.22504Z",
                  fill: c,
                  fillRule: "evenodd"
                }));
}

var make = Icon_Clock;

exports.make = make;
/* react Not a pure module */
