'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var MessageResponse$NimbusWeb = require("./MessageResponse.bs.js");

function make(group, cursor) {
  var query = "\n    query fetchMessages(\$group: ID!, \$cursor: ID) {\n      messages(_group: \$group, page: { cursor: \$cursor, limit: 25 }) {\n        ...MessageFragment\n      }\n    }\n    " + MessageResponse$NimbusWeb.fragment + "\n  ";
  var variables = [
    {
      key: "group",
      val: group
    },
    {
      key: "cursor",
      val: cursor
    }
  ];
  return Request$NimbusWeb.createBody(query, variables);
}

function decodeData(json) {
  return {
          messages: Json_decode.optional((function (param) {
                  return Json_decode.field("messages", MessageResponse$NimbusWeb.Decode.decodeMessages, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeData: decodeData,
  exec: exec
};

function normalize(payload) {
  var data = payload.data;
  if (data === undefined) {
    return [];
  }
  var msgs = data.messages;
  if (msgs !== undefined) {
    return MessageResponse$NimbusWeb.normalize(msgs);
  } else {
    return [];
  }
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
