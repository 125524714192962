'use strict';

var React = require("react");

function Icon_VideoCam(Props) {
  return React.createElement("svg", {
              height: "24",
              width: "24",
              viewBox: "0 96 960 960",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M160 896q-33 0-56.5-23.5T80 816V336q0-33 23.5-56.5T160 256h480q33 0 56.5 23.5T720 336v180l160-160v440L720 636v180q0 33-23.5 56.5T640 896H160Zm0-80h480V336H160v480Zm0 0V336v480Z"
                }));
}

var make = Icon_VideoCam;

exports.make = make;
/* react Not a pure module */
