'use strict';

var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function pop(prim) {
  history.back();
  
}

var push = RescriptReactRouter.push;

exports.pop = pop;
exports.push = push;
/* RescriptReactRouter Not a pure module */
