'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Context$NimbusWeb = require("./Context.bs.js");
var Storage$NimbusWeb = require("../utils/Storage.bs.js");

function defaultValue_1(param) {
  
}

var defaultValue = [
  undefined,
  defaultValue_1
];

var Token = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

function defaultValue_1$1(param) {
  
}

var defaultValue$1 = [
  undefined,
  defaultValue_1$1
];

var UserType = Context$NimbusWeb.Make({
      defaultValue: defaultValue$1
    });

function AuthContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return Storage$NimbusWeb.get(/* Token */0);
      });
  var match$1 = React.useState(function () {
        return Storage$NimbusWeb.get(/* Type */1);
      });
  return React.createElement(Token.Provider.make, Curry._3(Token.Provider.makeProps, [
                  match[0],
                  match[1]
                ], React.createElement(UserType.Provider.make, Curry._3(UserType.Provider.makeProps, [
                          match$1[0],
                          match$1[1]
                        ], children, undefined)), undefined));
}

var Wrap = {
  make: AuthContext$Wrap
};

exports.Token = Token;
exports.UserType = UserType;
exports.Wrap = Wrap;
/* Token Not a pure module */
