'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Home$NimbusWeb = require("./scenes/home/Home.bs.js");
var Request$NimbusWeb = require("./utils/Request.bs.js");
var Storage$NimbusWeb = require("./utils/Storage.bs.js");
var Redirect$NimbusWeb = require("./components/Redirect.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var AuthContext$NimbusWeb = require("./contexts/AuthContext.bs.js");
var ErrorContext$NimbusWeb = require("./contexts/ErrorContext.bs.js");
var SplashScreen$NimbusWeb = require("./scenes/login/SplashScreen.bs.js");
var StudentContext$NimbusWeb = require("./contexts/StudentContext.bs.js");
var LoginRequest_VerifyToken$NimbusWeb = require("./scenes/login/requests/LoginRequest_VerifyToken.bs.js");

function App(Props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = React.useContext(AuthContext$NimbusWeb.Token.context);
  var setToken = match[1];
  var token = match[0];
  var match$1 = React.useState(function () {
        return /* Validating */0;
      });
  var setAuthenticated = match$1[1];
  var setErrorMessage = React.useContext(ErrorContext$NimbusWeb.context);
  React.useEffect((function () {
          var exit = 0;
          if (token !== undefined && token !== "" && token !== "undefined") {
            Request$NimbusWeb.send(LoginRequest_VerifyToken$NimbusWeb.make(token), undefined, setErrorMessage, setToken).then(function (json) {
                      return Promise.resolve(LoginRequest_VerifyToken$NimbusWeb.$$Response.normalize(Curry._1(LoginRequest_VerifyToken$NimbusWeb.$$Response.Decode.exec, json)));
                    }).then(function (data) {
                    var match = data.token;
                    var match$1 = data.userType;
                    var exit = 0;
                    if (match !== undefined && match$1 !== undefined) {
                      Storage$NimbusWeb.set(/* Token */0, data.token);
                      Storage$NimbusWeb.set(/* Type */1, data.userType);
                      Curry._1(setAuthenticated, (function (param) {
                              return /* Authorized */2;
                            }));
                    } else {
                      exit = 1;
                    }
                    if (exit === 1) {
                      Curry._1(setAuthenticated, (function (param) {
                              return /* Unauthorized */1;
                            }));
                    }
                    return Promise.resolve(undefined);
                  }).catch(function (error) {
                  console.log(error);
                  Curry._1(setAuthenticated, (function (param) {
                          return /* Unauthorized */1;
                        }));
                  return Promise.resolve(undefined);
                });
          } else {
            exit = 1;
          }
          if (exit === 1) {
            Curry._1(setAuthenticated, (function (param) {
                    return /* Unauthorized */1;
                  }));
          }
          
        }), [token]);
  var parseSearch = function (search) {
    if (search !== "") {
      return Belt_Array.map(search.split("&"), (function (entry) {
                    return entry.split("=");
                  }));
    } else {
      return [];
    }
  };
  var tmp;
  switch (match$1[0]) {
    case /* Validating */0 :
        tmp = React.createElement(SplashScreen$NimbusWeb.make, {});
        break;
    case /* Unauthorized */1 :
        var match$2 = url.path;
        var exit = 0;
        if (match$2) {
          var other = match$2.hd;
          if (other === "login") {
            if (match$2.tl) {
              exit = 1;
            } else {
              var match$3;
              if (url.search !== "") {
                var ext = parseSearch(url.search);
                var pair = ext.find(function (pair) {
                      return pair[0] === "t";
                    });
                var t = pair !== undefined ? Belt_Array.get(pair, 1) : undefined;
                var pair$1 = ext.find(function (pair) {
                      return pair[0] === "type";
                    });
                var t2 = pair$1 !== undefined && (pair$1[1] === "student" || pair$1[1] === "tutor") ? Belt_Array.get(pair$1, 1) : undefined;
                match$3 = [
                  t,
                  t2
                ];
              } else {
                match$3 = [
                  undefined,
                  undefined
                ];
              }
              tmp = React.createElement(Home$NimbusWeb.make, {
                    path: "login",
                    login: true,
                    token: match$3[0],
                    userType: match$3[1]
                  });
            }
          } else if (match$2.tl) {
            exit = 1;
          } else {
            tmp = React.createElement(Home$NimbusWeb.make, {
                  path: other,
                  search: parseSearch(url.search),
                  login: false,
                  token: undefined,
                  userType: undefined
                });
          }
        } else {
          exit = 1;
        }
        if (exit === 1) {
          tmp = React.createElement(Redirect$NimbusWeb.make, {
                to_: "home"
              });
        }
        break;
    case /* Authorized */2 :
        var ext$1 = parseSearch(url.search);
        var match$4 = url.path;
        tmp = match$4 ? (
            match$4.tl ? React.createElement(Redirect$NimbusWeb.make, {
                    to_: "lesson"
                  }) : React.createElement(StudentContext$NimbusWeb.Wrap.make, {
                    children: React.createElement(Home$NimbusWeb.Protected.make, {
                          path: match$4.hd,
                          search: ext$1
                        })
                  })
          ) : React.createElement(Redirect$NimbusWeb.make, {
                to_: "lesson"
              });
        break;
    
  }
  return React.createElement("main", undefined, tmp);
}

var make = App;

exports.make = make;
/* react Not a pure module */
