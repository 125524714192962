'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Context$NimbusWeb = require("./Context.bs.js");
var Request$NimbusWeb = require("../utils/Request.bs.js");
var AuthContext$NimbusWeb = require("./AuthContext.bs.js");
var OrganizationContext$NimbusWeb = require("./OrganizationContext.bs.js");
var LoginRequest_Profile$NimbusWeb = require("../scenes/login/requests/LoginRequest_Profile.bs.js");

function defaultValue_1(param) {
  
}

var defaultValue = [
  undefined,
  defaultValue_1
];

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function UserContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        
      });
  var setProfile = match[1];
  var match$1 = React.useContext(AuthContext$NimbusWeb.Token.context);
  var token = match$1[0];
  var match$2 = React.useContext(OrganizationContext$NimbusWeb.context);
  var setOrganization = match$2[1];
  var request = Request$NimbusWeb.use(undefined);
  React.useEffect((function () {
          if (token !== undefined && token !== "" && token !== "undefined") {
            Curry._1(request, LoginRequest_Profile$NimbusWeb.make(undefined)).then(function (json) {
                    return Promise.resolve(LoginRequest_Profile$NimbusWeb.$$Response.normalize(Curry._1(LoginRequest_Profile$NimbusWeb.$$Response.Decode.exec, json)));
                  }).then(function (user) {
                  Curry._1(setProfile, (function (param) {
                          return user;
                        }));
                  Curry._1(setOrganization, (function (param) {
                          return user.organization;
                        }));
                  return Promise.resolve(undefined);
                });
          }
          
        }), [token]);
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, [
                  match[0],
                  setProfile
                ], children, undefined));
}

var Wrap = {
  make: UserContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
