'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var EventFilterResponse$NimbusWeb = require("./EventFilterResponse.bs.js");

function make(universityId) {
  var fragment = EventFilterResponse$NimbusWeb.Fragment.university;
  var query = "\n    query (\$id: ID!) {\n      universityById(id: \$id) {\n        ...UniversityFragment\n      }\n    }\n    " + fragment + "\n  ";
  var variables = [{
      key: "id",
      val: universityId
    }];
  return Request$NimbusWeb.createBody(query, variables);
}

function decodeData(json) {
  return {
          university: Json_decode.optional((function (param) {
                  return Json_decode.field("universityById", EventFilterResponse$NimbusWeb.Decode.university, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeData: decodeData,
  exec: exec
};

function normalize(payload) {
  return Belt_Option.flatMap(payload.data, (function (data) {
                return data.university;
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
