'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var DropInStudentsResponse$NimbusWeb = require("./DropInStudentsResponse.bs.js");

function make(fetchDropInStudents) {
  var query = "\n    query (\$fetchDropInStudents: [FetchDropInStudents]!) {\n      fetchDropInStudentsList(input: \$fetchDropInStudents) {\n        ...DropInStudentsFragment\n      }\n    }\n    " + DropInStudentsResponse$NimbusWeb.fragment + "\n  ";
  var dropInStudents = fetchDropInStudents.map(function (obj) {
        var sdt = {};
        sdt["timestamp"] = String(obj[1].getTime());
        var element = {};
        element["_drop_in"] = obj[0];
        element["startDateTime"] = sdt;
        element["minutes"] = obj[2];
        return element;
      });
  var variables = [{
      key: "fetchDropInStudents",
      val: dropInStudents
    }];
  return Request$NimbusWeb.createBody(query, variables);
}

function dData(json) {
  return {
          dropInStudents: Json_decode.optional((function (param) {
                  return Json_decode.field("fetchDropInStudentsList", (function (param) {
                                return Json_decode.array(DropInStudentsResponse$NimbusWeb.Decode.dT, param);
                              }), param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", dData, param);
                }), json)
        };
}

var Decode = {
  dData: dData,
  exec: exec
};

function normalize(payload) {
  var data = payload.data;
  if (data === undefined) {
    return [];
  }
  var dropInStudents = data.dropInStudents;
  if (dropInStudents !== undefined) {
    return Belt_Array.map(dropInStudents, DropInStudentsResponse$NimbusWeb.normalize);
  } else {
    return [];
  }
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* Request-NimbusWeb Not a pure module */
